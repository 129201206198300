import React from 'react';

import getCubeData from '../../../data/cubes/AlpenPower';
import { CubePageWrapper } from '../../../wrappers';
import { Image } from '../../../components';

export const AlpenPower = () => {
  const cube = getCubeData(process.env.REACT_APP_IS_PROD);
  return (
    <CubePageWrapper isItemNumberIsInRightColumn {...cube}>
      <p>alps pure OPC + Heidelbeere (60 Kps.);</p>
      <p> alps pure Melatonin + Baldrianwurzel (60 Kps.)</p>
    </CubePageWrapper>
  );
};
