import React from 'react';
import { useMediaQuery } from 'react-responsive'
import { Player, BigPlayButton } from 'video-react';
import 'video-react/dist/video-react.css'; // import css

import { ComponentWrapper, Description, Header, Text } from './styled-components';

export const VideoBlock = ({ poster, video, name, position, text, ...props }) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 980px)'
  });

  return (
    <ComponentWrapper {...props}>

      <Player {...{
        poster,
        src: video,
        fluid: false,
        height: isMobile ? 210 : 500
      }}>
        <BigPlayButton position="center" />
      </Player>

      <Description {...props}>

        <Header>{name}</Header>
        <Header>{position}</Header>

        <Text>{text}</Text>

      </Description>

    </ComponentWrapper>
  )
};
