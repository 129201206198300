import styled from '@emotion/styled';

export const RegistrationLinkWrapper = styled.div(({ theme }) => ({
  borderTop: `0.7px solid ${theme.colors.lightGrey}`,
  textAlign: 'center',
  paddingTop: '30px',
  marginTop: '40px',
  marginBottom: '120px',
  width: '100%',
  height: '100px',
}));

export const RegistrationLinkButton = styled.button(({ theme }) => ({
  border: 'none',
  backgroundColor: '#E8EDF2',
  color: theme.colors.black,
  width: 280,
  height: 45,
  lineHeight: '45px',
  borderRadius: 25,
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  '@media (max-width: 320px)': {
    width: '215px',
    height: '35px',
    lineHeight: '35px',
  },
}));
