import React from 'react';
import styled from '@emotion/styled';

import { Image } from '..';

const LogoWrapper = styled.div((props) => ({
  ...props.settings,
}));

export const Logo = (props) => (
  <LogoWrapper {...props}>
    <Image style={{ width: '60%', height: 'auto' }} path={`${window.location.origin}/assets/images/logos/logo-colored.svg`} alt='B.CHANGE Logo' />
  </LogoWrapper>
);
