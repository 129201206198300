import React from 'react';
import styled from '@emotion/styled';

const Text = styled.h3(({ theme, ...props }) => ({
  color: props.light ? theme.colors.white : theme.colors.black,
  fontSize: '48px',
  fontWeight: 'bold',
  letterSpacing: '1.2px',
  lineHeight: '58px',
  textAlign: props.align,
  marginBottom: '1%',
  '@media (max-width: 991px)': {
    textAlign: 'left',
    fontSize: '28px',
    lineHeight: '40px',
    letterSpacing: '0.98px',
  },
  ...props.overrides,
}));

export const SectionTitle = ({ children, ...props }) => <Text {...props}>{children}</Text>;
