import styled from '@emotion/styled';

export const SectionWrapper = styled.section({});

export const SliderIndicator = styled.li(({ isSelected, theme }) => ({
  background: isSelected ? theme.colors.attention : theme.colors.white,
  width: 50,
  height: 5,
  display: 'inline-block',
  margin: '0 3px',
  cursor: 'pointer',
  borderRadius: 5,
}));
