import React from 'react';
import { PaymentFormWrapper, ConfirmEmailWrapper, Text, BoldText, HeaderText } from './styled-components';

export const MobileOrderConfirmation = (props) => {
  const email = props?.location?.state?.mail || '';

  return (
    <PaymentFormWrapper>
      <ConfirmEmailWrapper>
        <HeaderText>Vielen Dank für deinen Cube Kauf!</HeaderText>
      </ConfirmEmailWrapper>
      <ConfirmEmailWrapper>
        <Text>Deine Bestellbestätigung mit dem Cube Code wird in Kürze an folgende Adresse verschickt:</Text>
        <BoldText>{email}</BoldText>
      </ConfirmEmailWrapper>
    </PaymentFormWrapper>
  );
};
