import React from 'react';
import { toast } from 'react-toastify';

import { toastSettings } from '../../wrappers/ShopwareWrapper';
import History from '../../utils/history';
import { ShopwareWrapper } from '../../wrappers';
import { ResetPasswordForm } from '../../components';
import { SuccessMessage } from './styled-components';
import { passwordRegEx } from './SingUp';

import api from '../../services/endpoints';

export const ResetPasswordNew = () => {
  const [passwords, setPasswords] = React.useState({
    newPassword: '',
    newPasswordConfirm: '',
  });
  const [validation, setValidation] = React.useState({
    isPasswordValid: true,
    isSecondPasswordValid: true,
  });
  const [passwordChanged, setRequestStatus] = React.useState(false);
  const [requestData, setRequestData] = React.useState(null);

  React.useEffect(() => {
    const searchParameters = History.location.search;

    if (searchParameters) {
      const parameters = searchParameters.split('&');
      const tenant = parameters[0].split('=')[1];
      const code = parameters[1].split('=')[1];
      setRequestData({ code, tenant });
    } else {
      toast.warning('Der Passwort-Zurücksetzen-Link ist nicht gültig', toastSettings);
    }
  }, []);

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setPasswords((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const onSubmit = async () => {
    if (!passwordRegEx.test(passwords.newPassword)) {
      setValidation((prevState) => ({
        ...prevState,
        isPasswordValid: false,
      }));
      return;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        isPasswordValid: true,
      }));
    }

    if (passwords.newPassword !== passwords.newPasswordConfirm) {
      setValidation((prevState) => ({
        ...prevState,
        isSecondPasswordValid: false,
      }));
      return;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        isSecondPasswordValid: true,
      }));
    }

    const reqBody = {
      password: passwords.newPassword,
      passwordConfirm: passwords.newPasswordConfirm,
    };

    if (requestData !== null) {
      try {
        const { status } = await api.user.resetPassword(requestData.code, requestData.tenant, reqBody);
        if (status === 200) setRequestStatus(true);
      } catch (err) {
        toast.error('Fehler. Bitte versuche es erneut.', toastSettings);
        console.log(err);
      }
    }
  };

  return (
    <ShopwareWrapper>
      {passwordChanged ? <SuccessMessage>Neues Passwort wurde erstellt!</SuccessMessage> : <ResetPasswordForm {...{ onSubmit, handleChange, passwords, validation, requestData }} />}
    </ShopwareWrapper>
  );
};
