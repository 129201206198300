import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  paddingTop: '6%',
  paddingBottom: '6%',
  background: 'transparent linear-gradient(201deg, #512C55 0%, #40314F 100%) 0% 0% no-repeat padding-box',
  color: '#fff',
  '@media (max-width: 991px)': {
    paddingTop: '7%',
    paddingBottom: '7%',
    paddingLeft: 20,
    paddingRight: 20,
  },
});

export const SubHeader = styled.p({
  fontSize: '18px',
  letterSpacing: '0.63px',
  fontWeight: 'bold',
  textAlign: 'center',
  marginTop: 30,
  '@media (max-width: 991px)': {
    fontSize: 22,
    lineHeight: '30px',
    textAlign: 'left',
    fontWeight: 500,
    marginTop: 40,
  },
});

export const StepperWrapper = styled.div({
  maxWidth: 1400,
  margin: '80px auto 0',
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 991px)': {
    marginTop: 50,
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const StepWrapper = styled.div(({ theme }) => ({
  position: 'relative',
  maxWidth: 340,
  fontSize: '18px',
  lineHeight: '36px',
  letterSpacing: '0.45px',
  padding: '0 15px',
  p: {
    strong: {
      //TODO check fontWeight: 700,
      backgroundColor: theme.colors.attention,
      color: 'black',
    },
  },
  span: {
    position: 'relative',
    display: 'block',
    fontFamily: 'Inter',
    fontSize: 90,
    lineHeight: '104px',
    textAlign: 'center',
    marginBottom: 40,
    '@media (max-width: 991px)': {
      marginBottom: 5,
      '&::before, &::after': {
        display: 'none',
      },
    },
  },
  '&:not(:last-of-type)': {
    'span::after': {
      content: "''",
      position: 'absolute',
      top: '50%',
      right: -22,
      transform: 'translate(0, -50%)',
      width: 50,
      height: 2,
      background: '#8698B0',
    },
  },
  '&:not(:first-of-type)': {
    'span::before': {
      content: "''",
      position: 'absolute',
      top: '50%',
      left: -22,
      transform: 'translate(0, -50%)',
      width: 50,
      height: 2,
      background: '#8698B0',
    },
  },
  '@media (max-width: 991px)': {
    textAlign: 'center',
    '&:not(:first-of-type)': {
      marginTop: 110,
    },
    '&:not(:last-of-type)': {
      '&::after': {
        content: "''",
        position: 'absolute',
        bottom: -85,
        width: 2,
        height: 50,
        background: '#8698B0',
      },
    },
  },
}));

export const ItalicText = styled.p({
  fontFamily: 'Fave',
  fontSize: 61,
  lineHeight: '61px',
  textAlign: 'center',
  maxWidth: 830,
  margin: '65px auto 0',
  '@media (max-width: 991px)': {
    margin: '65px auto 0',
    fontSize: 42,
    lineHeight: '48px',
  },
});

export const InsuranceCalculateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 85,
  marginBottom: 65,
  '@media (max-width: 991px)': {
    marginBottom: 75,
  },
});

export const SelectWrapper = styled.div(({ theme }) => ({
  maxWidth: 460,
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  color: theme.colors.darkPlum,
  label: {
    color: theme.colors.attention,
    fontSize: '18px',
    lineHeight: '28px',
    letterSpacing: '0.27px',
    marginBottom: 10,
    '@media (max-width: 991px)': {
      marginBottom: 30,
    },
  },
  '> div:first-of-type': {
    width: '338px',
    height: '56px',
    margin: 'auto',
  },
}));

export const SelectButton = styled.button(({ theme }) => ({
  outline: 'none',
  border: `1px solid ${theme.colors.darkGrey}`,
  backgroundColor: theme.colors.white,
  borderRadius: '12px',
  maxWidth: 345,
  width: '100%',
  height: '56px',
  lineHeight: '56px',
  margin: '25px auto 30px',
  fontSize: '16px',
  fontWeight: 'bold',
  color: theme.colors.black,
  transition: 'all 0.3s ease',
  '&[disabled]': {
    cursor: 'not-allowed',
    backgroundColor: theme.colors.cubeBox,
  },
  '&:hover': {
    opacity: 0.8,
  },
}));

export const Note = styled.span(({ theme }) => ({
  display: 'block',
  color: theme.colors.cubeBox,
  fontSize: '14px',
  lineHeight: '24px',
  letterSpacing: '0.35px',
  maxWidth: 825,
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
}));

export const CoatingWrapper = styled.div(({ theme }) => ({
  width: '100%',
  height: '56px',
  borderRadius: '12px',
  border: `1px solid ${theme.colors.darkPlum}`,
  background: 'rgba(255, 255, 255, 1)',
  fontSize: '16px',
  fontWeight: 'bold',
  letterSpacing: '0px',
  lineHeight: '56px',
  textAlign: 'center',
}));

export const SloganText = styled.p({
  fontSize: '32px',
  lineHeight: '46px',
  letterSpacing: '0.96px',
  textAlign: 'center',
  maxWidth: 830,
  margin: '65px auto 0',
  '@media (max-width: 991px)': {
    fontSize: '20px',
    letterSpacing: '0.7px',
    lineHeight: '30px',
  },
});
