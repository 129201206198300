import styled from '@emotion/styled';

export const SignUpFormWrapper = styled.section({
  maxWidth: 550,
  width: '100%',
  margin: '0 auto 65px',
  '> span': {
    fontSize: 10,
    lineHeight: '8px',
    color: '##91A1B7',
  },
  '@media (max-width: 620px)': {
    width: '90%',
  },
});

export const SubHeader = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '32px',
  marginBottom: 22,
  '@media (max-width: 991px)': {
    lineHeight: 1.24,
    marginTop: 40,
  },
});

export const SubmitWrapper = styled.div({
  textAlign: 'center',
  marginTop: 45,
});

export const SubmitButton = styled.button(({ theme }) => ({
  border: 'none',
  borderRadius: 23,
  width: 280,
  height: 45,
  lineHeight: '45px',
  textAlign: 'center',
  fontSize: 18,
  fontWeight: 500,
  backgroundColor: theme.colors.attention,
  color: theme.colors.black,
  transition: 'all 0.3s ease',
  '&:hover': {
    opacity: 0.8,
  },
  '&[disabled]': {
    cursor: 'not-allowed',
    opacity: 0.8,
  },
  '@media (max-width: 320px)': {
    width: '215px',
    height: '35px',
    lineHeight: '35px',
  },
}));

export const SubmitButtonNote = styled.p(({ theme }) => ({
  fontSize: 12,
  lineHeight: '16px',
  color: theme.colors.darkGrey,
  marginTop: 15,
  marginBottom: 30,
}));

export const LoginLinkWrapper = styled.div(({ theme }) => ({
  borderTop: `0.7px solid ${theme.colors.lightGrey}`,
  textAlign: 'center',
  paddingTop: '30px',
  marginTop: '40px',
  marginBottom: '120px',
  width: '100%',
  height: '100px',
}));

export const LoginLinkWrapperButton = styled.button(({ theme }) => ({
  border: 'none',
  backgroundColor: '#E8EDF2',
  color: theme.colors.black,
  width: 280,
  height: 45,
  lineHeight: '45px',
  borderRadius: 25,
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  '@media (max-width: 320px)': {
    width: '215px',
    height: '35px',
    lineHeight: '35px',
  },
}));

export const LoginLinkWrapperText = styled.p(() => ({
  marginBottom: '15px',
  fontSize: '23px',
  fontWeight: 600,
}));
