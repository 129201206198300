import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  position: 'relative',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  backgroundImage: "url('/assets/backgrounds/PreventionHero.png')",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  zIndex: 1,
  '&::before': {
    content: "''",
    position: 'absolute',
    background: 'transparent linear-gradient(234deg, #27334200 0%, #1A222C6E 61%, #151C24 100%) 0% 0% no-repeat padding-box',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  '@media (max-width: 991px) and (orientation: portrait)': {
    backgroundImage: "url('./assets/backgrounds/PreventionHero_mobile.png')",
  },
});

export const ContentWrapper = styled.div({
  width: '100%',
  marginTop: 'auto',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '> div:first-of-type': {
    maxWidth: 'none',
    paddingBottom: '2%',
  },
  h3: {
    '@media (max-width: 991px)': {
      lineHeight: 1.24,
    },
  },
});

export const CTAWrapper = styled.div({
  paddingBottom: '5%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '> div:last-of-type': {
    marginTop: 15,
  },
});

export const Text = styled.p({
  fontSize: 14,
  lineHeight: '20px',
  margin: 0,
  color: '#fff',
  span: {
    fontSize: 25,
    lineHeight: '35px',
  },
});

export const PulsatingArrows = styled.div({
  width: 30,
  height: 35,
  animation: 'pulse 2s infinite',
  img: {
    transform: 'rotate(90deg)',
  },
});
