import React from 'react';
import { toast } from 'react-toastify';

import { toastSettings } from '../../wrappers/RegistrationPageWrapper';
import History from '../../utils/history';
import { ShopwareWrapper } from '../../wrappers';
import { Button, Image } from '../../components';

import { ConfirmEmailWrapper, SubHeader, Text, AppDownloadWrapper, LinksWrapper } from './styled-components';

import api from '../../services/endpoints';

export const ConfirmEmail = () => {
  const confirmMail = React.useCallback(async (code, tenant) => {
    try {
      const { data } = await api.user.confirmEmail(code, tenant);
      if (data.status === 'ok') {
        toast.success('e-Mail Registrierung erfolgreich!', toastSettings);
      } else if (data.status === 'already_confirmed') {
        toast.warning('e-Mail wurde bereits registriert!', toastSettings);
      }
    } catch (err) {
      toast.error('Leider war die Registrierung nicht erfolgreich. Versuche es zu einem späteren Zeitpunkt nochmals', toastSettings);
      console.log(err);
    }
  }, []);

  React.useEffect(() => {
    const searchParameters = History.location.search;
    if (searchParameters) {
      const parameters = searchParameters.split('&');
      const tenant = parameters[0].split('=')[1];
      const code = parameters[1].split('=')[1];
      confirmMail(code, tenant);
    }
  }, [confirmMail]);

  return (
    <ShopwareWrapper>
      <ConfirmEmailWrapper>
        <SubHeader>Registrierung erfolgreich!</SubHeader>

        <div style={{ padding: '0px 15px' }}>
          <Text>
            Lade dir gleich die kostenfreie B.CHANGE App herunter. <br /> In deinem Postfach findest du die E-Mail mit dem Link zum App-Store.
          </Text>

          <Text>Mit deiner E-Mail Adresse und deinem Passwort, kannst du dich sofort in der B.CHANGE App anmelden.</Text>

          <Text>Wenn du einen Cube gekauft hast, ist dieser bereits für dich in der App hinterlegt!</Text>

          <Text>
            Viel Freude! <br /> Dein B.CHANGE Team
          </Text>

          <br />
          <Button {...{ href: 'https://b-change.com', text: 'Zur Startseite' }} />
        </div>
      </ConfirmEmailWrapper>
    </ShopwareWrapper>
  );
};
