import styled from '@emotion/styled';

import { Image } from '../';
import { Button } from 'react-bootstrap';
import { AiOutlineArrowUp } from 'react-icons/ai';

const ButtonWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-start',
  position: 'absolute',
  top: 0,
  right: 30,
  textTransform: 'uppercase',
  fontSize: 14,
  lineHeight: '32px',
  zIndex: 999,
  cursor: 'pointer',
  span: {
    whiteSpace: 'nowrap',
  },
  img: {
    maxWidth: 20,
  },
});

export const BackToTopButton = () => {
  const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

  return (
    <ButtonWrapper onClick={scrollToTop}>
      <Button variant='none' style={{ backgroundColor: '#eee', opacity: '90%', borderRadius: '50%' }}>
        <AiOutlineArrowUp color='#1d1d1d'></AiOutlineArrowUp>
        {/* <Image {...{ path: './assets/images/icons/arrow-up.svg', alt: 'Back To Top' }} /> */}
      </Button>
      {/* <span>nach oben</span>
       */}
    </ButtonWrapper>
  );
};
