import React, { useContext, useEffect } from 'react';
import { RegistrationPageWrapper } from '../../wrappers';
import { Image, GreenButton } from '../../components';
import { ConfirmEmailWrapper, Text, BoldText, SmallPrintText } from './styled-components';
import api from '../../services/endpoints';
import { AppContext } from '../../App';

export const OrderConfirmation = () => {
  const { state, dispatch } = useContext(AppContext);
  useEffect(() => {
    if (!state.isChosenCubeFree) return;
    api.payment.sendConfirmationEmail().then(() => sessionStorage.removeItem('temporaryToken'));
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: null,
        isChosenCubeFree: null,
        chosenCubePrice: null,
        chosenCubePriceNumber: null,
        chosenCubeName: null,
        chosenCubeNumber: null,
      },
    });
  });

  return (
    <RegistrationPageWrapper titleText={`Vielen Dank für deinen \nCube Kauf!`} titleStyle={{ textAlign: 'left', fontSize: '43px' }}>
      <ConfirmEmailWrapper>
        <BoldText>Deine Bestellbestätigung mit dem Cube Code wird in Kürze an folgende Adresse verschickt:</BoldText>
        <Text style={{ marginBottom: '90px' }}>{state.usersEmail || state.user?.userDetail?.email}</Text>

        <BoldText>
          B.CHANGE App Nutzer haben es leicht. <Image style={{ width: '25px', height: '25px' }} {...{ path: './assets/images/icons/mobile-phone.svg', alt: 'mobile-phone' }} />
        </BoldText>
        <Text>Melde dich mit deiner E-Mail Adresse und deinem Passwort in der B.CHANGE App an. Hier ist der Cube bereits für dich hinterlegt!</Text>

        <BoldText>Du bist neu hier? Noch keine B.CHANGE App?</BoldText>
        <Text style={{ marginBottom: '40px' }}>Kein Problem. Scanne einfach den Link in der Mail. Dieser führt dich direkt in den Store und weiter zu deinem Cube.</Text>
        <GreenButton {...{ path: '/', text: 'Zurück zur Homepage' }} style={{ margin: '0px auto 40px auto' }} />
        <SmallPrintText>Dies ist eine unverbindliche Bestellbestätigung. Nach erfolgreicher Zahlung der Bestellung wird eine Bestellbestätigung per Mail versendet.</SmallPrintText>
      </ConfirmEmailWrapper>
    </RegistrationPageWrapper>
  );
};
