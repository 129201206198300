import React from 'react';
import { Image, GreenButton } from '..';

import { LegendWrapper, QuoteWrapper, QuoteIcon, SlideHeader, ItalicText, SmallerText, CallToActionWrapper, ButtonWrapper, Caption, MockupWrapper, PulsatingArrows } from './styled-components';

export const Legend = ({ text, mockup }) => (
  <LegendWrapper>
    <Quote title={text} />

    <CallToActionWrapper>
      <ButtonWrapper>
        <PulsatingArrows>
          <Image {...{ path: './assets/images/icons/arrow-right-green.svg', alt: 'arrow right' }} />
        </PulsatingArrows>
        <GreenButton {...{ path: '/sign-up', text: 'Jetzt registrieren' }} />
        <Caption>Melde dich HIER an und hol dir die APP mit dem gratis CUBE.</Caption>
      </ButtonWrapper>
      <MockupWrapper>
        <Image {...{ path: mockup, alt: 'iPhone Mockup' }} />
      </MockupWrapper>
    </CallToActionWrapper>
  </LegendWrapper>
);

export const Quote = ({ title, subtitle, quoteWrapperStyles = {}, slogan = true, courseId }) => (
  <QuoteWrapper style={quoteWrapperStyles}>
    <QuoteIcon>
      <Image {...{ path: './assets/images/icons/quote.svg', alt: 'quote icon' }} />
    </QuoteIcon>
    <SlideHeader>{title}</SlideHeader>
    <SmallerText>{subtitle}</SmallerText>
    {courseId && <SmallerText>{courseId}</SmallerText>}
    {slogan && <ItalicText>my health in my hands</ItalicText>}
  </QuoteWrapper>
);
