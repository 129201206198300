import jwtDecode from 'jwt-decode';

const checkTokenExpiration = () => {
  try {
    const token = localStorage.getItem('token') || sessionStorage.getItem('temporaryToken');
    if (jwtDecode(token).exp < Date.now() / 1000) {
      return 'expired';
    }
    return token;
  } catch (err) {
    return null;
  }
};

export default checkTokenExpiration;
