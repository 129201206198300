import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import SwitchSelector from 'react-switch-selector';
import { Form, Row, Col } from 'react-bootstrap';
import Select from 'react-select';
import cn from 'classnames';
import { theme } from '../../styles/theme';
import { GreenButton } from '..';
import history from '../../utils/history';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pen.svg';
import api from '../../services/endpoints';
import './styles.css';
import { countryOptionAustria, countryOptionGermany, countryOptions } from './constants';

const FormWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.background,
  width: '100%',
  borderRadius: 25,
  padding: '35px 50px',
  fontSize: 18,
  fontWeight: 500,
  '@media (max-width: 991px)': {
    padding: '10px 15px',
  },
}));

const Text = styled.p({
  fontFamily: 'Inter',
  fontSize: 30,
  lineHeight: '65px',
  textAlign: 'left',
  paddingBottom: 30,
  alignItems: 'center',
  display: 'flex',
});

const SmallText = styled.p({
  fontFamily: 'Inter',
  fontSize: 14,
  textAlign: 'center',
});

const BottomText = styled.p({
  fontFamily: 'Inter',
  fontSize: 18,
  textAlign: 'center',
});

const options = [
  {
    label: 'Frau',
    value: 'female',
  },
  {
    label: 'Herr',
    value: 'male',
  },
];

export const UserDataFormCubes = ({ pathTo, userDetail = {}, address = {} }) => {
  const [chosenGender, setChosenGender] = useState('female');
  const [isAgreeWithTerms, setIsAgreeWithTerms] = useState(false);
  const [form, setForm] = useState({
    street: address?.street,
    houseNumber: address?.streetNumber,
    firstName: userDetail?.firstName,
    lastName: userDetail?.lastName,
    country: {
      value: address?.country,
      label: countryOptions.find((el) => el.value === address?.country)?.label || address?.country,
    },
    city: address?.city,
    zipCode: address?.zipCode,
    addressSupplement: address?.addressSupplement,
  });
  const [isEditMode, setIsEditMode] = useState(true);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!address || !userDetail) return;
    const { street, streetNumber, country, city, zipCode } = address;
    const { firstName, lastName } = userDetail;
    if (street && streetNumber && firstName && lastName && country && city && zipCode) setIsEditMode(false);
  }, []);
  const checkFormValidation = () => {
    for (let key in form) {
      if (key === 'addressSupplement') continue;
      if (!form[key]) return false;
    }
    for (let key in errors) {
      if (errors[key]) return false;
    }
    return (isAgreeWithTerms || !isEditMode) && true;
  };

  const findAndSetErrors = (formName) => {
    const newErrors = findFormErrors(formName);
    if (Object.keys(newErrors).length === 0) return;
    setErrors(newErrors);
  };

  const setField = (field, value) => {
    clearErrors(field);
    setForm({
      ...form,
      [field]: value,
    });
  };

  const clearErrors = (fieldName) => {
    if (!errors[fieldName]) return;
    setErrors({ ...errors, [fieldName]: null });
  };

  const findFormErrors = (formName) => {
    const { street, houseNumber, zipCode, city, firstName, country, lastName } = form;
    const newErrors = { ...errors };
    switch (formName) {
      case 'street': {
        if (!street) {
          newErrors.street = 'Bitte gib eine Adresse ein!';
        }
        return newErrors;
      }
      case 'houseNumber': {
        if (!houseNumber) {
          newErrors.houseNumber = 'Bitte gib die Hausnummer ein!';
        }
        return newErrors;
      }
      case 'firstName': {
        if (!firstName) {
          newErrors.firstName = 'Bitte gib deinen Vornamen an!';
        }
        return newErrors;
      }
      case 'zipCode': {
        if (!zipCode) {
          newErrors.zipCode = 'Bitte gib eine Postleitzahl ein!';
        }
        if (country?.value === countryOptionAustria) {
          newErrors.zipCode = zipCode?.length !== 4 ? 'Für Österreich muss die Postleitzahl 4-stellig sein.' : '';
        }
        if (country?.value === countryOptionGermany) {
          newErrors.zipCode = zipCode?.length !== 5 ? 'Für Deutschland muss die Postleitzahl 5-stellig sein.' : '';
        }
        return newErrors;
      }
      case 'lastName': {
        if (!lastName) {
          newErrors.lastName = 'Bitte gib deinen Nachnamen an!';
        }
        return newErrors;
      }
      case 'city': {
        if (!city) {
          newErrors.city = 'Bitte gib eine Stadt ein!';
        }
        return newErrors;
      }
      default:
        return newErrors;
    }
  };

  const submitHandler = async () => {
    const { street, houseNumber, zipCode, country, city, addressSupplement, firstName, lastName } = form;
    const userAddressData = {
      street,
      zipCode,
      city,
      addressSupplement,
      salutation: chosenGender,
      streetNumber: houseNumber,
      country: country.value,
    };

    await api.user.changeAddress(userAddressData);
    await api.user.changeName({ firstName, lastName });
    history.push(pathTo);
  };

  const { colors } = theme;
  const { street, houseNumber, firstName, lastName, country, city, zipCode, addressSupplement } = form;
  return (
    <FormWrapper>
      <div>
        <Text>Deine Daten {!isEditMode && <EditIcon stroke={colors.darkGrey} className='edit-icon' onClick={() => setIsEditMode(true)} />}</Text>
      </div>
      {isEditMode && (
        <p className='switch-wrapper'>
          <SwitchSelector
            options={options}
            initialSelectedIndex={chosenGender === 'female' ? 0 : 1}
            onChange={setChosenGender}
            backgroundColor={colors.white}
            fontColor={colors.darkGrey}
            selectedBackgroundColor={'#F0F3F5'}
            selectedFontColor={colors.darkGrey}
            border={`1px solid ${colors.lightGrey}`}
            selectionIndicatorMargin={1}
          />
        </p>
      )}
      <div>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {!isEditMode ? (
            <div style={{ marginBottom: '50px' }}>
              <p>{`${firstName} ${lastName}`}</p>
              <p>{`${street} ${houseNumber} ${addressSupplement || ''}`}</p>
              <p>{`${zipCode} ${city}`}</p>
              <p>{country.label}</p>
            </div>
          ) : (
            <>
              <Row className='mb-6'>
                <Form.Group as={Col} md='6' controlId='firstName'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.firstName })}>Vorname*</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.firstName}
                    className={cn(!!errors.firstName ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => setField('firstName', e.target.value)}
                    onBlur={() => findAndSetErrors('firstName')}
                    value={firstName}
                    type='text'
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.firstName}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' controlId='lastName'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.lastName })}>Nachname*</Form.Label>
                  <Form.Control
                    isInvalid={!!errors.lastName}
                    className={cn(!!errors.lastName ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => setField('lastName', e.target.value)}
                    onBlur={() => findAndSetErrors('lastName')}
                    type='text'
                    value={lastName}
                  />
                  {!!errors.lastName ? (
                    <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                      {errors.lastName}
                    </Form.Control.Feedback>
                  ) : (
                    <Form.Label className='under-input-text' style={{ color: colors.warmGrey }}></Form.Label>
                  )}
                </Form.Group>
              </Row>
              <Row className='mb-6'>
                <Form.Group as={Col} md='6' controlId='street'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.street })}>Straße*</Form.Label>
                  <Form.Control
                    className={cn(!!errors.street ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => setField('street', e.target.value)}
                    onBlur={() => findAndSetErrors('street')}
                    isInvalid={!!errors.street}
                    type='text'
                    value={street}
                    aria-pressed='false'
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.street}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='2' controlId='houseNumber'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.houseNumber })}>Hausnummer*</Form.Label>
                  <Form.Control
                    className={cn(!!errors.houseNumber ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => setField('houseNumber', e.target.value)}
                    onBlur={() => findAndSetErrors('houseNumber')}
                    isInvalid={!!errors.houseNumber}
                    type='text'
                    value={houseNumber}
                    aria-pressed='false'
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.houseNumber}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='4' controlId='addressSupplement'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.addressSupplement })}>Adresszusatz | Firma | c/o (optional)</Form.Label>
                  <Form.Control
                    className={cn(!!errors.addressSupplement ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => setField('addressSupplement', e.target.value)}
                    onBlur={() => findAndSetErrors('addressSupplement')}
                    isInvalid={!!errors.addressSupplement}
                    type='text'
                    value={addressSupplement}
                    aria-pressed='false'
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.addressSupplement}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mb-6'>
                <Form.Group as={Col} md='6' controlId='zipCode'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.zipCode })}>Postleitzahl*</Form.Label>
                  <Form.Control
                    className={cn(!!errors.zipCode ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => {
                      if (+e.target.value > 99999) return;
                      setField('zipCode', e.target.value);
                    }}
                    onBlur={() => findAndSetErrors('zipCode')}
                    isInvalid={!!errors.zipCode}
                    value={zipCode}
                    type='number'
                    aria-pressed='false'
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.zipCode}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group as={Col} md='6' controlId='city'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.city })}>Stadt*</Form.Label>
                  <Form.Control
                    className={cn(!!errors.city ? 'invalid-form' : 'valid-form')}
                    onChange={(e) => setField('city', e.target.value)}
                    onBlur={() => findAndSetErrors('city')}
                    isInvalid={!!errors.city}
                    type='text'
                    value={city}
                    aria-pressed='false'
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row className='mb-6'>
                <Form.Group as={Col} md='6' controlId='country'>
                  <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.country })}>Land*</Form.Label>
                  <Select
                    styles={{
                      control: (styles) => ({ ...styles, height: '60px' }),
                      indicatorSeparator: () => ({ display: 'none' }),
                    }}
                    placeholder=''
                    onChange={(e) => setField('country', e)}
                    onBlur={() => findAndSetErrors('zipCode')}
                    value={country}
                    options={countryOptions}
                  />
                  <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>
              </Row>
              <Row mb={3} style={{ marginBottom: 30 }}>
                <Form.Label style={{ color: colors.warmGrey, fontSize: 10 }}>*Pflichtfelder</Form.Label>
              </Row>
              <Row style={{ marginBottom: 90 }}>
                <Form.Group className='mb-3'>
                  <Form.Check
                    label={
                      <Form.Label style={{ paddingLeft: 25 }}>
                        Ich habe die Hinweise zu{' '}
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('/agbs', '_blank')}>
                          ABGs
                        </span>
                        ,{' '}
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('/datenschutzerklarung', '_blank')}>
                          Datenschutz, Newsletter und Weitergabe der Daten zu Abrechnungszwecken
                        </span>{' '}
                        gelesen und akzeptiere sie.
                      </Form.Label>
                    }
                    checked={isAgreeWithTerms}
                    onChange={(e) => setIsAgreeWithTerms(e.target.checked)}
                  />
                </Form.Group>
              </Row>
            </>
          )}

          <Row md='auto' className='justify-content-center' style={{ marginBottom: 34 }}>
            <GreenButton
              {...{ text: isEditMode ? 'Speichern' : 'Weiter' }}
              style={{
                opacity: checkFormValidation() ? 1 : 0.4,
              }}
              className='green-button'
              onClick={isEditMode ? () => setIsEditMode(false) : submitHandler}
              disabled={!checkFormValidation()}
            />
          </Row>
          <Row style={{ marginBottom: 170 }}>
            <SmallText>Du wirst nun zur Zahlungsabwicklung weitergeleitet, wo du die Zahlung abschließen kannst.</SmallText>
            <SmallText>Sichere Zahlung mit SSL-Verschlüsselung | Deine Daten sind 100 % sicher</SmallText>
          </Row>
        </Form>
      </div>
    </FormWrapper>
  );
};
