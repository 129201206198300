import styled from '@emotion/styled';

export const ComponentWrapper = styled.div({});

export const ComponentTitle = styled.h3(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: 56,
  lineHeight: '65px',
  color: theme.colors.darkGrey,
  marginBottom: 10,
  whiteSpace: 'nowrap',
  '@media (max-width: 991px)': {
    fontSize: 34,
  },
  '@media (max-width: 660px)': {
    fontSize: 20,
    fontWeight: 900,
  },
}));

export const ComponentSubtitle = styled.p(({ theme }) => ({
  fontSize: 18,
  lineHeight: '26px',
  marginBottom: 40,
}));

export const PreviewBlockWrapper = styled.div({
  position: 'relative',
  '> .button': {
    position: 'absolute',
    right: 0,
    bottom: 0,
    '@media (max-width: 991px)': {
      position: 'relative',
      margin: '45px auto',
    },
  },
});

export const PreviewBox = styled.div(({ theme }) => ({
  width: '100%',
  '&:not(:last-of-type)': {
    marginBottom: 15,
  },
}));

export const PreviewLabel = styled.span(({ theme }) => ({
  fontSize: 14,
  lineHeight: '20px',
}));

export const PreviewValue = styled.div(({ theme }) => ({
  maxWidth: 330,
  overflow: 'scroll',
  width: '100%',
  height: 50,
  borderRadius: 4,
  border: '1px solid #8698B0',
  fontSize: 16,
  lineHeight: '50px',
  fontWeight: 500,
  padding: '0 12px',
  '-ms-overflow-style': 'none',
  scrollbarWidth: 'none',
  '::-webkit-scrollbar': {
    display: 'none',
  },
}));

export const InlineBox = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  '> div': {
    maxWidth: '50%',
    '@media (max-width: 991px)': {
      maxWidth: '330px',
    },
  },
  '> div:nth-of-type(2n)': {
    '> span': {
      paddingLeft: 15,
    },
    '> div': {
      float: 'right',
    },
  },
  '@media (max-width: 991px)': {
    flexDirection: 'column',
  },
});

export const ModalWrapper = styled.div({
  maxWidth: 735,
  width: '100%',
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  background: '#fff',
  border: '1px solid #707070',
  padding: '45px 20px',
  overflow: 'scroll',
  '@media (max-width: 700px)': {
    height: '100%',
  },
});

export const ModalTitle = styled.div({
  fontFamily: 'Inter',
  fontSize: 56,
  lineHeight: '65px',
  color: '#44556C',
  marginBottom: 40,
  '@media (max-width: 700px)': {
    fontSize: '30px',
    lineHeight: '40px',
  },
});

export const ModalSubtitle = styled.div({
  color: '#44556C',
  fontSize: 18,
  lineHeight: '26px',
  marginBottom: 40,
});

export const AddressWrapper = styled.div({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(6, 1fr)',
  gridColumnGap: 20,
  gridRowGap: 0,
  label: {
    fontSize: 14,
    letterSpacing: '1.7px',
    color: '#44556c',
    lineHeight: 1.24,
    margin: 0,
  },
  '> div': {
    '&:nth-of-type(1)': {
      gridArea: '1 / 1 / 2 / 2',
      position: 'relative',
      select: {
        outline: 'none',
        height: 50,
        lineHeight: 1.24,
        width: '100%',
        border: '1px solid #1d1d1d',
        borderRadius: 4,
        padding: 12,
        fontSize: 16,
        appearance: 'none',
      },
    },
    '&:nth-of-type(2)': {
      gridArea: '2 / 1 / 3 / 2',
    },
    '&:nth-of-type(3)': {
      gridArea: '2 / 2 / 3 / 3',
    },
    '&:nth-of-type(4)': {
      gridArea: '3 / 1 / 4 / 2',
    },
    '&:nth-of-type(5)': {
      gridArea: '3 / 2 / 4 / 3',
    },
    '&:nth-of-type(6)': {
      gridArea: '4 / 1 / 5 / 2',
    },
    '&:nth-of-type(7)': {
      gridArea: '4 / 2 / 5 / 3',
    },
    '&:nth-of-type(8)': {
      gridArea: '5 / 1 / 6 / 2',
    },
    '&:nth-of-type(9)': {
      margin: '45px auto 0',
      gridArea: '6 / 1 / 7 / 3',
    },
  },
  '@media (max-width: 660px)': {
    display: 'flex',
    flexDirection: 'column',
  },
});

export const CubeCardWrapper = styled.div({
  display: 'flex',
  width: '100%',
  '.image-wrapper': {
    maxWidth: 210,
    width: '100%',
  },
  img: {
    verticalAlign: 'top',
  },
  ':not(:last-of-type)': {
    marginBottom: 65,
    '@media (max-width: 660px)': {
      marginBottom: 0,
    },
  },
  '> div:first-of-type': {
    marginRight: 25,
    '@media (max-width: 660px)': {
      marginRight: 0,
    },
  },
  '@media (max-width: 660px)': {
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export const CubeCardContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  fontSize: 16,
  position: 'relative',
  '> div': {
    position: 'absolute',
    bottom: -40,
    marginTop: '60px',
    '@media (max-width: 660px)': {
      position: 'relative',
      bottom: 'unset',
      marginTop: '10px',
    },
  },
});

export const ButtonArea = styled.div({
  display: 'flex',
  marginTop: '40px',
  justifyContent: 'space-evenly',
  '@media (max-width: 660px)': {
    flexDirection: 'column',
    '> div': {
      margin: '10px auto!important',
    },
  },
});
