import styled from '@emotion/styled';

export const ConfirmEmailWrapper = styled.section({
  maxWidth: 550,
  width: '100%',
  padding: 20,
});

export const Text = styled.p({
  fontSize: 20,
  lineHeight: '28px',
  marginBottom: 20,
});

export const BoldText = styled.p({
  fontSize: 20,
  fontWeight: 'bold',
  lineHeight: '28px',
});

export const SmallPrintText = styled.p({
  marginTop: '10%',
  fontSize: 14,
  lineHeight: '22px',
  '@media (max-width: 991px)': {
    marginBottom: '20px',
  },
});

export const Button = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.attention,
  color: theme.colors.black,
  width: 280,
  height: 45,
  lineHeight: '45px',
  borderRadius: 25,
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  margin: '0px auto 40px auto',
  '&:hover': {
    opacity: 0.8,
  },
  '@media (max-width: 991px)': {
    marginTop: '100px',
  },
  '@media (max-width: 320px)': {
    width: '215px',
    height: '35px',
    lineHeight: '35px',
  },
}));
