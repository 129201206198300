import React, { useContext, useEffect } from 'react';
import { RegistrationPageWrapper } from '../../wrappers';
import { Button } from './styled-components';
import { AppContext } from '../../App';
import History from '../../utils/history';

export const PaymentFailed = () => {
  const { dispatch } = useContext(AppContext);
  useEffect(() => {
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: null,
        isChosenCubeFree: null,
        chosenCubePrice: null,
        chosenCubePriceNumber: null,
        chosenCubeName: null,
        chosenCubeNumber: null,
      },
    });
  }, []);

  return (
    <RegistrationPageWrapper
      titleText={`Der Kauf wurde nicht ordnungsgemäß durchgeführt!`}
      titleStyle={{ textAlign: 'left', fontSize: '43px' }}
    >
      <Button onClick={() => History.push('/')}>Zurück zur Homepage</Button>
    </RegistrationPageWrapper>
  );
};
