import React from 'react';
import styled from '@emotion/styled';

import History from '../../utils/history';

export const ButtonWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.attention,
  color: theme.colors.black,
  width: '338px',
  height: '56px',
  borderRadius: '12px',
  fontSize: '16px',
  lineHeight: '56px',
  fontWeight: 'bold',
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  boxShadow: `0px 12px 28px ${theme.colors.btnShadow}`,
  '&:hover': {
    opacity: 0.8,
  },
  '@media (max-width: 340px)': {
    width: '180px',
    height: '35px',
    lineHeight: '35px',
  },
}));

export const GreenButton = ({ path, text, style, className, disabled, redirectHandler, onClick, href }) => {
  const relocate = () => {
    if (disabled) return;
    onClick && onClick();
    if (!path && redirectHandler) return redirectHandler();
    if (!path && !redirectHandler && !href) return;
    if (path) {
      History.push(path);
    } else if (href) {
      window.open(href, '_self');
    }
  };

  return (
    <ButtonWrapper onClick={relocate} style={style} className={className}>
      {text}
    </ButtonWrapper>
  );
};
