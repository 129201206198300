import React from 'react';
import styled from '@emotion/styled';

const Ul = styled.ul(({ theme }) => ({
  fontWeight: 500,
  listStyleType: '"- "',
  span: {
    backgroundColor: theme.colors.attention,
  },
  '@media (max-width: 991px)': {
    fontSize: 14,
  },
}));

export const BulletList = ({ children, ...props }) => <Ul {...props}>{children}</Ul>;
