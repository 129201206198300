import React, { useEffect, useRef } from 'react';
import AdyenCheckout from '@adyen/adyen-web';
import { adyenEnvironment, adyenClientKey } from '../../config/environment';
import api from '../../services/endpoints';
import history from '../../utils/history';
import { SUCCESS_PAYMENT_STATUSES } from './constants';
import '@adyen/adyen-web/dist/adyen.css';
import './styles.css';
import { PaymentFormWrapper, Text } from './styled-components';

export const MobilePaymentPage = () => {
  const ref = useRef();

  useEffect(() => {
    //get params from url
    const urlParams = new URLSearchParams(window.location.search);

    const accessToken = urlParams.has('accessToken') ? urlParams.get('accessToken') : '';
    const itemNumber = urlParams.has('itemNumber') ? urlParams.get('itemNumber') : '';
    const countryCode = urlParams.has('countryCode') ? urlParams.get('countryCode') : '';

    //if not successful or params are missing -> display mobile-payment-failed page
    if (!accessToken || !itemNumber || !countryCode) {
      return setTimeout(() => history.push('mobile-payment-failed'), 2500);
    }

    const payload = {
      itemNumber: itemNumber,
      returnUrl: window.location.origin,
      countryCode: countryCode,
    };

    return api.payment
      .createMobilePaymentSession(payload, accessToken)
      .then((res) => {
        return AdyenCheckout(createConfiguration(res.data));
      })
      .then((checkout) => {
        const cardComponent = checkout.create('dropin').mount(ref.current);
      })
      .catch((err) => console.log('ERR===>>', err));
  }, []);

  const createConfiguration = (res) => {
    const configuration = {
      environment: adyenEnvironment,
      clientKey: adyenClientKey,
      session: {
        id: res.id,
        sessionData: res.sessionData,
      },
      onPaymentCompleted: ({ resultCode }) => {
        if (!SUCCESS_PAYMENT_STATUSES.includes(resultCode)) {
          setTimeout(() => history.push('mobile-payment-failed'), 2500);
          return;
        }
        setTimeout(() => history.push({ pathname: '/mobile-order-confirmation', state: { mail: res.shopperEmail } }), 2500);
      },
      onError: (error) => {
        console.error(error);
      },
    };
    return configuration;
  };

  return (
    <>
      <PaymentFormWrapper>
        <Text>Zahlungsoptionen</Text>
        <div>
          <div id='dropin-container' className='dropin-dark' ref={ref}></div>
        </div>
      </PaymentFormWrapper>
    </>
  );
};
