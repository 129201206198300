import React from 'react';

import { SectionTitle, VideoBlock, SubSectionTitle, Teaser } from '../../components';
import { SectionWrapper, VideosWrapper } from './styled-components';
import { Container } from 'react-bootstrap';

export const PreventionVideo = ({ videos }) => (
  <SectionWrapper id='videos'>
    <Container className='g-4'>
      <SubSectionTitle align='center'>Zu Den Trainern</SubSectionTitle>
      <SectionTitle align='center'>Deine Coaches sind immer bei dir</SectionTitle>
      <Teaser>Sie begleiten dich und sorgen für optimale Motivation.</Teaser>
    </Container>
    <VideosWrapper>
      {videos.map((video) => (
        <VideoBlock key={video.name} {...video} />
      ))}
    </VideosWrapper>
  </SectionWrapper>
);
