import React, { useContext } from 'react';
import { toast } from 'react-toastify';

import { AppContext } from '../../App';
import { toastSettings } from '../../wrappers/RegistrationPageWrapper';
import { RegistrationPageWrapper } from '../../wrappers';
import { SignUpForm, SuccessRegistrationMessage } from '../../components';
import { getToken } from '../../utils/getToken';

import api from '../../services/endpoints';
import history from '../../utils/history';

export const passwordRegEx = /(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%^&*]{8,}/;
const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const SingUp = () => {
  const { state, dispatch } = useContext(AppContext);
  const [userData, setUserData] = React.useState({
    email: '',
    password: '',
    firstName: '',
  });
  const [validation, setValidation] = React.useState({
    isEmailValid: true,
    isPasswordValid: true,
  });
  const [registrationComplete, setRegistrationStatus] = React.useState(false);

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setUserData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const redirectToLogin = () => {
    history.push('/login');
  };

  const onSubmit = async () => {
    if (!emailRegEx.test(userData.email)) {
      toast.error('Bitte gib eine gültige E-Mail Adresse ein.', toastSettings);
      setValidation((prevState) => ({
        ...prevState,
        isEmailValid: false,
      }));
      return;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        isEmailValid: true,
      }));
    }

    if (!passwordRegEx.test(userData.password)) {
      toast.error('Das Passwortformat ist falsch', toastSettings);
      setValidation((prevState) => ({
        ...prevState,
        isPasswordValid: false,
      }));
      return;
    } else {
      setValidation((prevState) => ({
        ...prevState,
        isPasswordValid: true,
      }));
    }

    const reqBody = {
      ...userData,
      lastName: '',
      deviceToken: '',
      tenant: 'bchange',
    };

    try {
      const { status, data } = await api.user.registration(reqBody);
      if (status === 201) {
        setRegistrationStatus(true);
        dispatch({
          type: 'UPDATE',
          payload: {
            user: data,
          },
        });

        if (state.chosenCubeId) {
          const { access_token, error, error_description } = await api.user.login(userData.email, userData.password);
          if (error) {
            toast.error(error_description, toastSettings);
            return;
          }
          if (access_token) {
            sessionStorage.setItem('temporaryToken', access_token);
            if (state.isProgram) {
              sessionStorage.setItem('isPreventionProgram', true);
              history.push('/payment');
              return;
            }
            if (state.isChosenCubeFree) return cubeAssign(state.chosenCubeId, access_token);
            history.push('/user-data');
          }
        }
      }
    } catch (err) {
      toast.error('Registrierung nicht möglich, bitte überprüfe deine Daten!', toastSettings);
      console.log(err);
    }
  };

  const cubeAssign = async (cubeId, token) => {
    const { email } = userData;

    try {
      const { status } = await api.cube.cubeAssign(cubeId, token);
      if (status === 201) {
        dispatch({
          type: 'UPDATE',
          payload: {
            chosenCubeId: null,
            isChosenCubeFree: false,
            usersEmail: email,
          },
        });
        history.push('/order-confirmation');
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <RegistrationPageWrapper
      titleText={registrationComplete ? 'Bestätige deine E-Mail Adresse!' : 'Registriere dich jetzt!'}
      hasSubtitle
      subtitle={registrationComplete ? 'Dein Account ist noch nicht fertiggestellt. Bitte prüfe dein E-Mail Postfach.' : 'Du bist neu hier?'}
    >
      {registrationComplete ? (
        <SuccessRegistrationMessage email={userData.email} />
      ) : (
        <SignUpForm
          {...{
            redirectToLogin,
            onSubmit,
            handleChange,
            userData,
            validation,
          }}
          //userData, handleChange, onSubmit, redirectToLogin, ...props
        />
      )}
    </RegistrationPageWrapper>
  );
};
