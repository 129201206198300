import React from 'react';
import { MobileCubeFormWrapper, Text } from './styled-components';
import { Row, Col } from 'react-bootstrap';
import { Accordion, Table } from 'bootstrap';
import './styled.scss';

export const MobileCubeBasenTraining = () => {
  return (
    <MobileCubeFormWrapper className='mobile-cube-form-wrapper'>
      <div className='mobile-cube-container'>
        <Row className='row'>
          <Col className='col-12 jumbotron bg-light'>
            <div className='container'>
              <div className='infotext'>
                <h5 className='product-detail-name'>Dr. Ewald Töths Basen-Balance</h5>
                <h2>Säure Basen Regulation für ein gesundes Leben</h2>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Was kann dieser Health Cube?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='product-detail-description-text'>
                    <h3>Dauer</h3>
                    <span>21 Tage</span>
                  </div>
                  <div className='product-detail-description-text'>
                    <h3>Produktinformationen</h3>
                    <div>
                      Mehr Energie, besser schlafen, entgiften? Der Säure-Basen-Haushalt ist Teil des Stoffwechsels und beeinflusst den Körper ganzheitlich. Ist die Verteilung von Säuren und Basen in
                      einem gesunden Gleichgewicht, fühlt man sich vital und fit. Entsteht durch ungünstige Essgewohnheiten, Stress oder Bewegungsmangel eine Dysbalance, leidet das Wohlbefinden
                      vielseitig. Erfahre mit dem exklusiv von „Basenpionier“ Dr. Ewald Töth begleiteten Basen-Balance Cube, wodurch Ungleichgewicht entstehen kann und wie du deinen Körper zurück in
                      die Balance bringst.
                    </div>
                    <br />
                    <div>
                      Der Dr. Ewald Töth Basen-Balance Cube ist ein optimal getaktetes Programm für Ausgleich und ganzheitliches Wohlbefinden. Der Health Cube kombiniert verschiedene Bausteine, um
                      deinen Körper ganzheitlich zurück in das Gleichgewicht zu leiten:
                    </div>
                    <div>
                      <ul>
                        <li>Einnahme der Basen-Mineral-Mischung zweimal täglich</li>
                        <li>21-tägige digitale Begleitung mit Hintergrundwissen und präzisen Anleitungen, um die Säure-Basen-Balance zu verbessern</li>
                        <li>21 basische Rezepte zum Nachkochen</li>
                        <li>digitale Tools, die deinen Erfolg dokumentieren</li>
                        <li>Messung des pH-Wertes mit Indikatorstreifen</li>
                        <li>Test-Sachets weiterer Basenprodukte</li>
                      </ul>
                    </div>
                    <div>
                      Dr. Ewald Töth ist Basen-Experte, Arzt und Integrativmediziner. Er wird dich durch spannende 21 Tage begleiten. Mit motivierenden Videos, News, Tipps und basischen Rezepten zum
                      Nachkochen, von Diätologen geprüft. So erreichst du mit Spaß und Freude dein Ziel.
                    </div>
                  </div>
                  <div className='product-detail-ordernumber-container'>
                    <h3>
                      <span className='product-detail-ordernumber-label'>Artikelnummer:</span>
                    </h3>
                    <span className='product-detail-ordernumber'>30054</span>
                  </div>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Welche digitalen gratis Tools sind enthalten?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <ul>
                    <li className='bold'>
                      <span>pH-Wert Tool</span>
                      <div className='li-text'>
                        Das im Health Cube inkludierte pH-Wert Tool ist automatisch voreingestellt, damit du deine Messergebnisse dokumentieren kannst. Mit dem pH-Wert Tagesprofil lernst du deinen
                        Körper besser kennen und erkennst Einflussfaktoren des Säure-Basen-Haushalts.
                      </div>
                    </li>
                    <li className='bold'>
                      <span>Smart Reminder Tool</span>
                      <div className='li-text'>
                        Zusammen mit deinem Health Cube aktivierst du automatisch den Smart Reminder. Das Tool, das dich rechtzeitig an die Einnahme deiner Supplements erinnert und eine
                        Einnahmeübersicht erstellt.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Welche Supplements sind im Paket inkludiert?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <ul>
                    <li>
                      <span>Basen Mineral Mischung (500g) der Marke DR. MED. EWALD TÖTH®</span>
                    </li>
                    <li>
                      <span>Basen Mineral Bad (3 Sachets zu je 50g) der Marke DR. MED. EWALD TÖTH®</span>
                    </li>
                    <li>
                      <span>Basencreme Regulation (2 Sachets zu je 2 ml) der Marke DR. MED. EWALD TÖTH®</span>
                    </li>
                    <li>
                      <span>pH-Wert Indikatorstreifen (2 Stück mit je 27 Streifen)</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Zutaten und Nährwerte-Tabelle</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div>
                    <div className='bold'>Nährwerte pro Tagesdosis (Basen Mineral Mischung)</div>
                  </div>
                  <br />
                  <div>
                    <table className='table'>
                      <thead>
                        <tr>
                          <th scope='col' className='table-th1'>
                            Nährwerte
                          </th>
                          <th scope='col' className='table-th2'>
                            2 TL = 10 g
                          </th>
                          <th scope='col' className='table-th3'>
                            % NRV 1
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Kalzium</td>
                          <td>1.017 mg</td>
                          <td>127</td>
                        </tr>
                        <tr>
                          <td>Natrium</td>
                          <td>1.249 mg</td>
                          <td>**</td>
                        </tr>
                        <tr>
                          <td>Kalium</td>
                          <td>300 mg</td>
                          <td>15</td>
                        </tr>
                        <tr>
                          <td>Magnesium</td>
                          <td>72 mg</td>
                          <td>19</td>
                        </tr>
                        <tr>
                          <td>Zink</td>
                          <td>6 mg</td>
                          <td>60</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className='margin-top'>
                    <div>
                      1) Prozentsatz der empf. Tagesdosis gem. VO (EU) Nr. 1169/2011.
                      <br />
                      ** Keine Nährstoffbezugswerte vorhanden.
                    </div>
                  </div>
                  <br />
                  <div>
                    <div className='bold'>Zutaten (Basen Mineral Mischung)</div>
                  </div>
                  <div className='margin-top'>
                    <div>Natriumhydrogencarbonat (Säureregulator), Zitronensäure (Säuerungsmittel), Magnesiumcitrat, Kaliumcitrat, Di-Natriumhydrogenphosphar (Säureregulator), Zinkcitrat</div>
                  </div>
                  <div className='margin-top small-line-height'>
                    Hinweis: Nahrungsergänzungsmittel sind kein Ersatz für eine ausgewogene und abwechslungsreiche Ernährung sowie eine gesunde Lebensweise. Die empfohlene Tagesdosis nicht
                    überschreiten. Fragen Sie Ihren Arzt, Therapeuten oder Apotheker.
                  </div>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Nährstoffwissen</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='bold'>Physiologische Funktion der Nährstoffe (Basen-Mineral-Mischung)</div>

                  <div>
                    <ul>
                      <li className='bold'>
                        <span>Zink </span>
                        <span className='li-text'>
                          trägt zu einer normalen DNA-Synthese, einer normalen Fruchtbarkeit und einer normalen Reproduktion bei. Außerdem leistet Zink einen Beitrag zur Erhaltung normaler Haut,
                          Haare, Nägel und Knochen. Zink trägt zu normalen Stoffwechselprozessen von Fettsäuren, Kohlenhydraten, Makronährstoffen und Vitamin A bei. Wichtig ist auch der Beitrag zu
                          normalen kognitiven Fähigkeiten und einem normalen Säure-Basen-Stoffwechsel.
                        </span>
                      </li>
                      <li className='bold'>
                        <span>Calcium </span>
                        <span className='li-text'>
                          trägt zu einer normalen Blutgerinnung, einer normalen Funktion von Verdauungsenzymen, einem normalen Energiestoffwechsel und einer normalen Signalübertragung zwischen den
                          Nervenzellen bei. Außerdem wird der Mineralstoff für die Erhaltung normaler Knochen und Zähne benötigt. Calcium hat eine wichtige Funktion bei der Zellteilung und
                          –spezialisierung.
                        </span>
                      </li>
                      <li className='bold'>
                        <span>Magnesium </span>
                        <span className='li-text'>
                          trägt zur Erhaltung normaler Knochen und Zähne bei. Der Mineralstoff hat eine Funktion bei der Zellteilung, und er trägt zu einem normalen Energiestoffwechsel plus einer
                          normalen Funktion des Nervensystems bei. Magnesium trägt zur Verringerung von Müdigkeit und Ermüdung, zu einer normalen Eiweißsynthese, einer normalen Muskelfunktion und zum
                          Elektrolytgleichgewicht bei.
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div>
                    <div className='bold'>Anwendungsbereich (Basen-Mineral-Mischung)</div>
                    <div>
                      <ul>
                        <li>
                          <span>Ergänzung der täglichen Ernährung für jedes Alter </span>
                        </li>
                        <li>
                          <span>Vegetarische und vegane Ernährungsweise</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <div className='bold'>Verzehrempfehlung (Basen-Mineral-Mischung)</div>
                    <div className='margin-top'>
                      2 x täglich einen Messlöffel (1 ML = 5 g) zunächst in 1/8 Liter warmen Wasser auflösen und aussprudeln lassen. Danach mit Wasser oder Tee (kalt oder warm) bis zu insgesamt
                      mindestens ¼ Liter auffüllen.
                    </div>
                  </div>

                  <div className='margin-top'>
                    <div className='bold'>Der Smart Reminder erinnert automatisch an die Einnahme der Supplements.</div>
                  </div>

                  <div className='margin-top'>
                    <div className='bold'>Lagerhinweis (Basen-Mineral-Mischung)</div>
                    <div className='margin-top'>Geschlossen, kühl, trocken und lichtgeschützt außerhalb der Reichweite von kleinen Kindern lagern.</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MobileCubeFormWrapper>
  );
};
