import React from 'react';
import styled from '@emotion/styled';
import history from '../../utils/history';

const TermsText = styled.p({
  textAlign: 'center',
  fontSize: 12,
  whiteSpace: 'pre-wrap',
  lineHeight: '16px',
  paddingTop: '15px',
});

const Term = styled.span({
  textDecoration: 'underline',
  cursor: 'pointer',
});

export default function TermsTextComponent() {
  return (
    <TermsText>
      Mit der Registrierung akzeptierst du unsere <Term onClick={() => history.push('/agbs')}>AGB</Term>, {`\r\n`}
      <Term onClick={() => history.push('/nutzungsbedingungen')}>Nutzungsbedingungen</Term> und{' '}
      <Term onClick={() => history.push('/datenschutzerklarung')}>Datenschutzhinweise</Term>.
    </TermsText>
  );
}
