import styled from '@emotion/styled';

export const FormGroupWrapper = styled.div({
  position: 'relative',
  marginBottom: 20,
});

export const ShowPasswordButton = styled.div({
  position: 'absolute',
  width: 20,
  height: 20,
  top: '50%',
  right: 10,
  transform: 'translate(0, -50%)',
  lineHeight: '20px',
  cursor: 'pointer',
  zIndex: 50,
  svg: {
    verticalAlign: 'middle',
  },
});

export const InputWrapper = styled.div({
  position: 'relative',
});

export const Label = styled.label(({ theme }) => ({
  fontFamily: 'Red Hat Display',
  fontSize: 18,
  lineHeight: 1.24,
  letterSpacing: '1.7px',
  margin: 0,
  color: theme.colors.darkGrey,
}));

export const Input = styled.input(({ theme, isValid }) => ({
  fontFamily: 'Red Hat Display',
  width: '100%',
  height: 50,
  borderWidth: 0.5,
  borderStyle: 'solid',
  borderColor: isValid ? theme.colors.black : theme.colors.red,
  borderRadius: 12,
  fontSize: 14,
  padding: 12,
  color: '#1d1d1d',
  outline: 'none',
  '&.error': {
    borderColor: theme.colors.red,
  },
  '&[disabled]': {
    cursor: 'not-allowed',
    backgroundColor: theme.colors.cubeBox,
  },
  '@media (max-width: 991px)': {
    borderWidth: 1,
  },
}));

export const InputNote = styled.span(({ theme, error }) => ({
  fontFamily: 'Red Hat Display',
  fontSize: 12,
  lineHeight: '16px',
  marginTop: 7,
  color: error === true ? theme.colors.red : '#1d1d1d',
}));
