import React, { useState, useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Nav } from 'react-bootstrap';

import { AppContext } from '../../App';
import { DarkLogo, Menu, Hamburger, WhiteLogo } from '../';
import { HeaderWrapper, UserMenuWrapper, UserMenuIconWrapper, UserNameWrapper, MyAccountWrapper, MyAccountItem } from './styled-components';

import History from '../../utils/history';
import { useToggle } from '../../utils/useToggle';
import { USER_NAME_CHARACTERS_LIMIT } from './constants';
import useScrollDirection, { SCROLL_UP } from '../../utils/useScrollDirection';

const headerLogoSettings = {
  cursor: 'pointer',
  maxWidth: 262,
  left: 150,
  position: 'absolute',
  '@media (max-width: 1500px)': {
    left: '5%',
  },
  '@media (max-width: 1250px)': {
    maxWidth: 180,
  },
};

export const PageHeader = ({ logo = false, login = true, ...props }) => {
  useContext(AppContext);
  const scrollDirection = useScrollDirection();
  const [isToggled, toggle] = useToggle(false);
  const [token, setToken] = useState(localStorage.getItem('token'));
  const isAbsolute = props.absolute ? true : false;
  const isMobile = useMediaQuery({
    query: '(max-width: 980px)',
  });

  const backToHome = () => {
    History.push(window.location.href.includes('praevention') ? '/praevention' : '/');
  };

  const [isTop, setTopPosition] = useState(true);
  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, []);

  const listenToScroll = () => {
    const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
    if (winScroll < 120) setTopPosition(true);
    if (winScroll > 200) setTopPosition(false);
  };

  const isHeaderFixed = scrollDirection === SCROLL_UP && !isTop;
  return (
    <HeaderWrapper {...{ isAbsolute, isToggled, className: isHeaderFixed ? 'fixed' : null, isMobile }}>
      {isMobile && !isToggled && <Hamburger onClick={toggle} theme={props.burgerTheme} />}

      {!isMobile && logo && (isHeaderFixed ? <WhiteLogo onClick={backToHome} settings={headerLogoSettings} /> : <DarkLogo onClick={backToHome} settings={headerLogoSettings} />)}

      {props.menu && <Menu menuList={props.menu} light={isAbsolute} isOpen={isToggled} toggle={toggle} isMobile={isMobile} isLoggedIn={token} setToken={setToken} login={login} />}

      {!isMobile && login && <UserMenu {...{ setToken, light: isAbsolute, isLoggedIn: token }} />}
    </HeaderWrapper>
  );
};

const UserMenu = ({ setToken, light, isLoggedIn }) => {
  const { dispatch } = useContext(AppContext);
  const userFirstName = localStorage.getItem('userFirstName');
  const userLastName = localStorage.getItem('userLastName');
  const userNameLine = (userFirstName || '') + ' ' + (userLastName || '');
  const truncatedLine = userNameLine.length > USER_NAME_CHARACTERS_LIMIT ? userNameLine.slice(0, USER_NAME_CHARACTERS_LIMIT) + '...' : userNameLine;
  const onLogin = () => {
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: null,
        isChosenCubeFree: null,
        chosenCubePrice: null,
        chosenCubePriceNumber: null,
        chosenCubeName: null,
        chosenCubeNumber: null,
      },
    });
    sessionStorage.removeItem('temporaryToken');
    History.push('/login');
  };

  return (
    <UserMenuWrapper {...{ isAbsolute: light, onClick: !isLoggedIn ? onLogin : null }}>
      <UserMenuIconWrapper>
        <UserIcon {...{ light }} />
      </UserMenuIconWrapper>
      <UserNameWrapper {...{ light, isLoggedIn }}>
        <span>{isLoggedIn ? 'Profil' : 'Login'}</span>
      </UserNameWrapper>
      {isLoggedIn && <MyAccount {...{ setToken }} />}
    </UserMenuWrapper>
  );
};

const MyAccount = ({ setToken }) => {
  const { dispatch } = useContext(AppContext);
  const goToProfile = () => History.push('/user-profile');
  const onLogout = () => {
    localStorage.clear();
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: null,
        isChosenCubeFree: null,
        chosenCubePrice: null,
        chosenCubePriceNumber: null,
        chosenCubeName: null,
        chosenCubeNumber: null,
      },
    });
    History.push('/');
    setToken(null);
  };
  return (
    <MyAccountWrapper>
      <MyAccountItem>
        <Nav.Link onClick={goToProfile}>Mein Konto</Nav.Link>
      </MyAccountItem>
      <MyAccountItem>
        <Nav.Link onClick={onLogout}>Logout</Nav.Link>
      </MyAccountItem>
    </MyAccountWrapper>
  );
};

const UserIcon = ({ light }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='12.097' height='14.799' viewBox='0 0 12.097 14.799' stroke={light ? '#fff' : '#91A1B7'}>
    <path
      fill={light ? '#fff' : '#91A1B7'}
      id='Icon_open-person'
      data-name='Icon open-person'
      d='M6.048,0A3.338,3.338,0,0,0,3.024,3.572,3.338,3.338,0,0,0,6.048,7.144,3.338,3.338,0,0,0,9.072,3.572,3.338,3.338,0,0,0,6.048,0ZM2.888,7.144A2.939,2.939,0,0,0,0,10v2.539A8.554,8.554,0,0,0,6.048,14.8,8.814,8.814,0,0,0,12.1,12.541V10A2.928,2.928,0,0,0,9.209,7.144a4.347,4.347,0,0,1-3.16,1.429A4.347,4.347,0,0,1,2.888,7.144Z'
    />
  </svg>
);
