import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import {
  BChangeMain,
  FitAndImunn,
  BasenTraining,
  SingUp,
  ConfirmEmail,
  ResetPasswordNew,
  LegalPage,
  Prevention,
  BitterPower,
  StartProgram,
  GesundUndFit,
  CustomerArea,
  LoginPage,
  PaymentPage,
  OrderConfirmation,
  UserDataSection,
  ProgramConfirmation,
  IntermittentFasting,
  AlpenPower,
  PaymentFailed,
  MobilePaymentPage,
  MobilePaymentFailed,
  MobileOrderConfirmation,
  MobileCubeBasenTraining,
  MobileCubeAlpenkraeuterRegeneration,
  MobileCubeBitterKur,
  MobileCubeDefault,
} from './pages';

const RedirectToPrevention = () => {
  return <Redirect to='/praevention'></Redirect>;
};
const Redirectstartprogram = () => {
  return <Redirect to='/praevention-start-program'></Redirect>;
};
const Redirectimpressum = () => {
  return <Redirect to='/praevention/impressum'></Redirect>;
};
const Redirectagbs = () => {
  return <Redirect to='/praevention/agbs'></Redirect>;
};
const Redirectgesundheitliche = () => {
  return <Redirect to='/praevention/gesundheitliche'></Redirect>;
};
const Redirectdatenschutzerklarung = () => {
  return <Redirect to='/praevention/datenschutzerklarung'></Redirect>;
};
const Redirectwiderrufsrecht = () => {
  return <Redirect to='/praevention/widerrufsrecht'></Redirect>;
};
const Redirectnutzungsbedingungen = () => {
  return <Redirect to='/praevention/nutzungsbedingungen'></Redirect>;
};
const Redirectprogramconfirmation = () => {
  return <Redirect to='/praevention-program-confirmation'></Redirect>;
};

export const useRoutes = (isAuthenticated) => (
  <Switch>
    {/* Landings */}
    <Route path={'/'} exact={true} component={RedirectToPrevention} />
    <Route path={'/prevention'} exact={true} component={RedirectToPrevention} />
    <Route path={'/praevention'} exact={true} component={Prevention} />
    <Route path={'/praevention-start-program'} component={StartProgram} />
    <Route path={'/prevention-start-program'} component={Redirectstartprogram} />
    <Route path={'/praevention-program-confirmation'} component={ProgramConfirmation} />
    <Route path={'/prevention-program-confirmation'} component={Redirectprogramconfirmation} />

    {/* Payment */}
    <Route path={'/payment'} exact={true} component={PaymentPage} />
    <Route path={'/payment-failed'} exact={true} component={PaymentFailed} />
    <Route path={'/mobile-payment'} exact={true} component={MobilePaymentPage} />
    <Route path={'/mobile-payment-failed'} exact={true} component={MobilePaymentFailed} />

    <Route path={'/mobile-order-confirmation'} component={MobileOrderConfirmation} />
    <Route path={'/order-confirmation'} component={OrderConfirmation} />
    {/* Mobile Cubes View */}
    <Route path={'/cube/CubeBasenTraining'} exact={true} component={MobileCubeBasenTraining} />
    <Route path={'/cube/CubeAlpenkraeuterRegeneration'} exact={true} component={MobileCubeAlpenkraeuterRegeneration} />
    <Route path={'/cube/CubeBitterKur'} exact={true} component={MobileCubeBitterKur} />
    <Route path={'/cube/CubeDefault'} exact={true} component={MobileCubeDefault} />

    {/* TODO remove Cubes */}
    {/* <Route path={'/FitAndImunn'} component={FitAndImunn} />
    <Route path={'/BasenTraining'} component={BasenTraining} />
    <Route path={'/BitterPower'} component={BitterPower} />
    <Route path={'/GesundUndFit'} component={GesundUndFit} />
    <Route path={'/IntermittentFasting'} component={IntermittentFasting} />
    <Route path={'/AlpenPower'} component={AlpenPower} /> */}

    {/* Registration processes */}
    <Route path={'/sign-up'} component={SingUp} />
    <Route path={'/confirm-email'} component={ConfirmEmail} />
    <Route path={'/reset-password'} component={ResetPasswordNew} />

    {/* Legal pages */}
    <Route path={'/praevention/impressum'} component={LegalPage} />
    <Route path={'/prevention/impressum'} component={Redirectimpressum} />
    <Route path={'/praevention/agbs'} component={LegalPage} />
    <Route path={'/prevention/agbs'} component={Redirectagbs} />
    <Route path={'/praevention/gesundheitliche'} component={LegalPage} />
    <Route path={'/prevention/gesundheitliche'} component={Redirectgesundheitliche} />
    <Route path={'/praevention/datenschutzerklarung'} component={LegalPage} />
    <Route path={'/prevention/datenschutzerklarung'} component={Redirectdatenschutzerklarung} />
    <Route path={'/praevention/widerrufsrecht'} component={LegalPage} />
    <Route path={'/prevention/widerrufsrecht'} component={Redirectwiderrufsrecht} />
    <Route path={'/praevention/nutzungsbedingungen'} component={LegalPage} />
    <Route path={'/prevention/nutzungsbedingungen'} component={Redirectnutzungsbedingungen} />
    <Route path={'/agbs'} component={LegalPage} />
    <Route path={'/datenschutzerklarung'} component={LegalPage} />
    <Route path={'/nutzungsbedingungen'} component={LegalPage} />
    <Route path={'/impressum'} component={LegalPage} />

    {/* TODO remove User */}
    {/* <Route path={'/login'} component={LoginPage} /> */}
    {/* {isAuthenticated && <Route path={'/user-profile'} component={CustomerArea} />} */}
    {/* <Route path={'/user-profile'} component={CustomerArea} /> 
    <Route path={'/user-data'} component={UserDataSection} />*/}
    {/* TODO fix isAuthenticated */}

    <Redirect {...{ to: '/' }} />
  </Switch>
);
