import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@emotion/react';
import ClearCache from 'react-clear-cache';

import App from './App';

import './styles/reset.css';
import './styles/index.css';
import './styles/theme.css';
import './styles/bootstrap-overwrite.css';
import './assets/fonts/style.css';
import { theme } from './styles/theme';

ReactDOM.render(
  <ClearCache auto>
    {() => (
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    )}
  </ClearCache>,

  document.getElementById('root')
);
