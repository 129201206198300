import React from 'react';
import styled from '@emotion/styled';

export const Teaser = styled.p(({ theme }) => ({
  marginTop: 20,
  fontSize: '18px',
  color: theme.colors.black,
  fontWeight: 'bold',
  lineHeight: '26px',
  textAlign: 'center',
  marginBottom: '4%',
  span: {
    backgroundColor: theme.colors.attention,
  },
  '@media (max-width: 991px)': {
    letterSpacing: '0.7px',
    fontSize: '20px',
    lineHeight: '30px',
    textAlign: 'left',
  },
}));
