import styled from '@emotion/styled';
import { theme } from '../../styles/theme';

export const SectionWrapper = styled.section({
  padding: '4% 0 4%',
});

export const SubHeader = styled.p(({ theme }) => ({
  fontSize: '18px',
  color: theme.colors.black,
  margin: '45px 0',
  fontWeight: 'bold',
  maxWidth: '1400px',
  span: {
    backgroundColor: theme.colors.attention,
  },
  '@media (max-width: 991px)': {
    // TODO add mobile letterSpacing
    fontSize: '20px',
    lineHeight: '30px',
    fontWeight: 500,
    marginTop: 30,
    marginBottom: 40,
    paddingRight: 0,
  },
}));

export const Text = styled.p({
  maxWidth: '1400px',
  marginBottom: '30px',
  span: {
    backgroundColor: theme.colors.attention,
  },
});

export const UnderlinedText = styled.p({
  maxWidth: '1400px',
  marginBottom: '30px',
  textDecoration: 'underline',
});

export const Ul = styled.ul(({ theme }) => ({
  listStyleType: '"- "',
  marginBottom: '30px',
}));

export const CalculateCTA = styled.div({
  maxWidth: 1020,
  margin: '0 auto',
  backgroundColor: '#F7F7F8',
  borderRadius: 235,
  display: 'flex',
  justifyContent: 'space-between',
  br: {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  '@media (max-width: 991px)': {
    borderRadius: 0,
    padding: '35px 20px 70px',
    textAlign: 'center',
  },
});

export const CalculateCTAContent = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  width: '100%',
  paddingLeft: 125,
  p: {
    marginTop: 0,
    marginBottom: 0,
  },
  '@media (max-width: 991px)': {
    paddingLeft: 0,
    alignItems: 'center',
  },
  '@media (max-width: 991px) and (orientation: landscape)': {
    alignItems: 'center',
  },
}));

export const CalculateCTAImage = styled.div({
  maxWidth: 468,
  width: '100%',
  img: {
    verticalAlign: 'top',
    height: '100%',
    width: 'auto',
    borderRadius: '50%',
  },
  '@media (max-width: 991px)': {
    display: 'none',
  },
});

export const TextWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: 100,
  marginTop: 25,
  img: {
    width: 36,
    height: 'auto',
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  '@media (max-width: 991px)': {
    paddingRight: 0,
  },
});

export const CalculateCTATitle = styled.h4({
  fontSize: 18,
  fontWeight: 'bold',
  color: theme.colors.black,
});

export const MobileBlock = styled.div({
  userSelect: 'none',
  marginTop: 30,
  display: 'flex',
  'img:first-of-type': {
    borderRadius: '50%',
    maxWidth: 170,
  },
  'img:last-of-type': {
    width: 36,
    marginLeft: 42,
  },
});
