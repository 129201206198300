import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  padding: '6% 20px 4%',
  backgroundColor: '#F7F7F8',
});

export const TextWrapper = styled.div({
  maxWidth: 1240,
  margin: 'auto',
  '@media (max-width: 991px)': {
    maxWidth: 'none',
  },
});

export const SubHeader = styled.p(({ theme }) => ({
  fontSize: '18px',
  color: theme.colors.black,
  letterSpacing: '0.63px',
  fontWeight: 'bold',
  marginTop: 50,
  marginBottom: 25,
  '@media (max-width: 991px)': {
    fontSize: 22,
    lineHeight: '30px',
    fontWeight: 500,
    marginTop: 40,
    marginBottom: 30,
    padding: '0px 20px 0px 20px',
  },
}));

export const Text = styled.p(({ theme }) => ({
  maxWidth: 930,
  fontSize: '16px',
  color: theme.colors.black,
  marginBottom: '2%',
  span: {
    backgroundColor: theme.colors.attention,
  },
}));

export const ModulesWrapper = styled.div({
  position: 'relative',
  maxWidth: 1400,
  margin: '0 auto',
  backgroundColor: '#fff',
  padding: '2% 2%',
  borderRadius: 8,
  '@media (max-width: 991px)': {
    padding: '20px 0',
    margin: '0 20px',
  },
});

export const ModulesGrid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const ModuleImageWrapper = styled.div(({ active }) => ({
  position: 'relative',
  cursor: 'pointer',
  maxWidth: '25%',
  textAlign: 'center',
  margin: '0 26px 65px',
  overflow: 'hidden',
  borderRadius: 8,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: active ? 0 : '-100%',
    background: 'transparent linear-gradient(209deg, #44556C67 0%, #151C24 100%) 0% 0% no-repeat padding-box',
    height: '100%',
    width: '100%',
    transition: 'all 0.3s ease',
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  img: {
    maxWidth: 215,
    verticalAlign: 'top',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
    },
    '@media (max-height: 500px)': {
      maxHeight: '20em',
      width: 'auto',
    },
  },
  '@media (max-width: 1500px)': {
    maxWidth: 250,
    margin: '0 26px 35px',
  },
  '@media (max-width: 991px)': {
    maxWidth: 250,
    margin: '0 auto 30px',
  },
}));

export const RingIcon = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 15,
  left: 15,
  backgroundImage: "url('./assets/backgrounds/ring_small.svg')",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: 68,
  height: 68,
  fontSize: '28px',
  lineHeight: '28px',
  letterSpacing: '3.6px',
  color: theme.colors.black,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  span: {
    marginTop: 0,
    '&:first-of-type': {
      marginTop: 6,
      fontSize: '10px',
      lineHeight: '11px',
      letterSpacing: '-0.18px',
      fontWeight: 500,
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        bottom: -1,
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 25,
        height: 1,
        backgroundColor: theme.colors.warmGrey,
      },
    },
  },
}));

export const ModuleTitle = styled.p(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  margin: '0 auto',
  bottom: 60,
  color: theme.colors.white,
  fontSize: '18px',
  letterSpacing: '0.63px',
  fontWeight: 'bold',
}));

export const ModuleDescription = styled.div({
  maxWidth: 790,
  margin: 'auto',
  padding: '0 26px',
  fontSize: '16px',
  letterSpacing: '0.4px',
  span: {
    display: 'block',
    fontSize: '32px',
    letterSpacing: '1.12px',
    fontWeight: 500,
    marginBottom: 25,
  },
  '@media (max-width: 1500px)': {
    padding: '0 32px',
  },
});

export const Arrow = styled.div(({ left }) => ({
  border: 'solid #fff',
  borderWidth: '0 3px 3px 0',
  display: 'inline-block',
  padding: 5,
  margin: 15,
  transform: left ? 'rotate(135deg)' : 'rotate(-45deg)',
  marginRight: left ? 10 : 20,
}));

export const ArrowWrapper = styled.div(({ theme, next }) => ({
  width: 40,
  height: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.colors.warmGrey,
  opacity: 0.5,
  borderRadius: '50%',
  zIndex: 10,
  right: next ? -15 : 'auto',
  left: next ? 'auto' : -15,
  '&:hover': {
    backgroundColor: theme.colors.warmGrey,
  },
  '&::before': {
    display: 'none',
  },
  '&.slick-disabled': {
    display: 'none',
  },
}));

export const LargeRing = styled.div({
  backgroundImage: "url('./assets/backgrounds/ring_large.svg')",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 120,
  height: 120,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  cursor: 'pointer',
  zIndex: 1,
});
