import React from 'react';
import { MobileCubeFormWrapper, Text } from './styled-components';
import { Row, Col } from 'react-bootstrap';
import './styled.scss';

export const MobileCubeDefault = () => {
  return (
    <MobileCubeFormWrapper className='mobile-cube-form-wrapper'>
      <div className='mobile-cube-container'>
        <Row className='row'>
          <Col className='col-12 jumbotron bg-light'>
            <div className='container'>
              <div className='infotext'>
                {/* <h5 className='product-detail-name'></h5> */}
                <h2>HEALTH CUBES sind Lifestyle-Programme mit einem definierten Fokus.</h2>
              </div>
              <div>Wo liegt dein Fokus? Welcher Health Cube ist der richtige für dich?</div>
              <br />
              <div className='image-space'>
                <img src='../../assets/images/3erHandy-Health-Cubes.png' alt='health-cubes-image'></img>
              </div>
              <br />
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Was können Health Cubes?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='product-detail-description-text'>
                    <div>
                      Health Cubes sind von Gesundheitsexperten entwickelte Lifestyle-Programme mit einem definierten Fokus. Sie motivieren und begleiten dich über einen bestimmten Zeitraum und zeigen
                      dir den Weg in ein gesünderes Leben.
                    </div>
                    <br />
                    <div>Jeder Health Cube behandelt ein bestimmtes Wellbeing-Thema und ist ein in sich stimmiges, perfekt abgestimmtes Paket.</div>
                    <br />
                    <div>
                      Health Cubes verbinden
                      <ul>
                        <li>verifiziertes Gesundheitswissen</li>
                        <li>Lifestyle Programme mit Fokus</li>
                        <li>Trainings- und Gesundheitsvideos</li>
                        <li>Digitale Assistenten unterstützen dich und tracken deine Erfolge.</li>
                      </ul>
                    </div>
                    <div className='image-container'>
                      <img src='../../assets/images/Assistants_angeschnitten.png' alt='health-assistant-image'></img>
                    </div>
                    <div>
                      Manchmal braucht es etwas mehr! Deshalb enthalten einige Health Cubes Supplements - hochwertige Mikronährstoffe in geprüfter Qualität aus Österreich und Deutschland, die dich
                      zusammen mit einem gesunden Lebensstil fit und vital halten.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MobileCubeFormWrapper>
  );
};
