import React from 'react';
import styled from '@emotion/styled';
import MediaQuery from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useToggle } from '../utils/useToggle';
import History from '../utils/history';

import menu from '../data/mainMenu.json';
import footer from '../data/mainFooter.json';

import { BLogo, Image, Menu, Hamburger, DarkLogo, PageFooter } from '../components';

export const toastSettings = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

const PageWrapper = styled.div({
  height: 'inherit',
  display: 'flex',
  width: '100%',
  position: 'relative',
  '@media (max-width: 991px)': {
    flexDirection: 'column-reverse',
  },
});

const LeftSide = styled.div({
  maxWidth: '50%',
  width: '100%',
  overflow: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 991px)': {
    maxWidth: '100%',
    height: '100%',
  },
  '@media (min-width: 980px)': {
    padding: 20,
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const PageHeader = styled.header({
  margin: '40px auto 0',
  width: 550,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  '> div:nth-of-type(1)': {
    maxWidth: 230,
    cursor: 'pointer',
  },
});

const PageMain = styled.main({
  maxWidth: 550,
  width: '100%',
  margin: '0 auto',
  flex: '1 0 auto',
});

const RightSide = styled.div({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '50%',
  width: '100%',
  backgroundImage: "url('./assets/backgrounds/RegistrationFHD.png')",
  backgroundSize: 'cover',
  position: 'relative',
  zIndex: 1,
  '&::before': {
    content: "''",
    position: 'absolute',
    background: 'transparent linear-gradient(234deg, #27334200 0%, #1A222C6E 61%, #151C24 100%) 0% 0% no-repeat padding-box',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  '> div:nth-of-type(1)': {
    position: 'absolute',
    top: 40,
    right: '15%',
    margin: 0,
    '@media (max-width: 991px)': {
      top: 16,
      right: 20,
      width: 40,
      height: 40,
    },
  },
  '@media (max-width: 991px)': {
    backgroundImage: "url('./assets/backgrounds/RegistrationMobile.png')",
    maxWidth: '100%',
    minHeight: 200,
    alignItems: 'flex-end',
  },
});

const MockUpWrapper = styled.div({
  display: 'flex',
  alignItems: 'flex-end',
  marginLeft: '12%',
  width: '100%',
  color: '#fff',
  img: {
    maxWidth: 200,
  },
  p: {
    paddingBottom: 30,
  },
  '@media (max-width: 991px)': {
    display: 'none',
  },
});

const ItalicText = styled.p({
  fontFamily: 'Fave',
  fontSize: 70,
  lineHeight: '55px',
  letterSpacing: '2.1px',
});

const WelcomeText = styled.h3(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: 46,
  lineHeight: '65px',
  whiteSpace: 'pre-line',
  fontWeight: 'lighter',
  color: theme.colors.darkGrey,
  textAlign: 'left',
  margin: '30px 0 50px ',
  '@media (max-width: 991px)': {
    fontSize: 36,
    lineHeight: '48px',
    color: '#fff',
    margin: '0 auto 16px',
  },
  '@media (max-width: 640px)': {
    fontSize: 22,
    lineHeight: '34px',
    color: '#fff',
    margin: '0 auto 16px',
    padding: '0 20px',
  },
}));

const Subtitle = styled.h6(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: '600',
  color: theme.colors.darkGrey,
  textAlign: 'left',
  marginBottom: '50px',
}));

const footerStyles = {
  color: '#91a1b7',
  backgroundColor: 'transparent',
  borderTop: '0.7px solid #cad2dd',
  maxWidth: 550,
  margin: '0 auto',
  padding: '15px 0 60px',
  lineHeight: '22px',
  alignItems: 'flex-start',
  span: {
    marginBottom: 20,
    '@media (max-width: 991px)': {
      marginBottom: '0',
    },
  },
  a: { color: '#91a1b7' },
};

export const RegistrationPageWrapper = ({ children, titleText, titleStyle, subtitle, hasSubtitle }) => {
  const [isToggled, toggle] = useToggle(false);
  const goToHomePage = () => History.push('/');
  const links = footer.mainFooter;

  return (
    <PageWrapper>
      <LeftSide>
        <MediaQuery minWidth={992}>
          <PageHeader>
            <DarkLogo onClick={goToHomePage} />
            <WelcomeText style={{ ...titleStyle }}>{titleText || 'Willkommen zurück!'}</WelcomeText>
            {hasSubtitle && <Subtitle>{subtitle || 'Melde dich hier mit deinen Kontodaten an.'}</Subtitle>}
          </PageHeader>
        </MediaQuery>

        <PageMain>{children}</PageMain>

        <PageFooter {...{ links, footerStyles }} />
      </LeftSide>

      <RightSide>
        <BLogo />
        <MediaQuery maxWidth={991}>
          <Hamburger onClick={toggle} />
          <WelcomeText>{titleText || `Willkommen bei \nB.CHANGE`}</WelcomeText>
        </MediaQuery>
        <MockUpWrapper>
          <Image {...{ path: './assets/images/mocks/register_male@3.png', alt: 'iPhone Mockup' }} />
          <ItalicText>my health in my hands</ItalicText>
        </MockUpWrapper>
      </RightSide>

      <MediaQuery maxWidth={991}>
        <Menu menuList={menu.mainMenu} light={false} isOpen={isToggled} toggle={toggle} />
      </MediaQuery>

      <ToastContainer />
    </PageWrapper>
  );
};
