import styled from '@emotion/styled';

const btn = {
  maxWidth: 280,
  width: '100%',
  height: 45,
  lineHeight: '45px',
  borderRadius: 23,
  fontWeight: 500,
  fontSize: 18,
  letterSpacing: 0.9,
  textAlign: 'center',
  cursor: 'pointer',
  margin: '0 auto 60px',
  ':hover': {
    opacity: 0.75,
  },
};

export const GreenButton = styled.div(({ theme }) => ({
  ...btn,
  background: theme.colors.attention,
  color: theme.colors.black,
}));

export const GreyButton = styled.div(({ theme }) => ({
  ...btn,
  background: theme.colors.cubeBox,
  color: theme.colors.black,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  img: {
    maxWidth: 20,
    marginRight: 12,
  },
}));

export const GoBackToLoginPage = styled.div({
  textAlign: 'center',
  marginBottom: 60,
  fontSize: 14,
  textDecoration: 'underline',
  cursor: 'pointer',
});
