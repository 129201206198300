import React from 'react';
import styled from '@emotion/styled';
import { Image, GreenButton } from '..';
import { Stack } from 'react-bootstrap';

const CallToActionWrapper = styled.div(({ theme, ...props }) => ({
  color: props.dark ? theme.colors.black : theme.colors.white,
  '> img': {
    maxHeight: 225,
    width: 'auto',
    display: props.mockup ? 'block' : 'none',
  },
}));

const Text = styled.p({
  whiteSpace: 'nowrap',
  fontSize: '16px',
  margin: 0,
  '@media (max-width: 991px)': {
    fontSize: 14,
  },
});

const BigText = styled.p({
  whiteSpace: 'nowrap',
  fontSize: '48px',
  fontWeight: 'bold',
  margin: '5px auto 10px',
  '@media (max-width: 991px)': {
    fontSize: 25,
  },
});

const PulsatingArrows = styled.div({
  width: 48,
  height: 'auto',
  animation: 'pulse 2s infinite',
  img: {
    transform: 'rotate(90deg)',
  },
  '@media (max-width: 991px)': {
    width: 30,
    height: 35,
  },
});

const BigTextLineThrough = styled.p({
  textDecoration: 'line-through',
  whiteSpace: 'nowrap',
  fontSize: '48px',
  fontWeight: 'bold',
  margin: '5px auto 10px',
  '@media (max-width: 991px)': {
    fontSize: 25,
  },
});

export const CallToActionPrevention = (props) => (
  <CallToActionWrapper {...props}>
    <Stack className='align-items-center'>
      {!props.withoutImage && <><Image style={{ maxHeight: 225, width: 'auto' }} {...{ path: './assets/images/mocks/MockUps_Trainer@2.png', alt: 'iPhone Mockup' }} />

        <PulsatingArrows>
          <Image {...{ path: './assets/images/icons/arrow-right-green.svg', alt: 'arrow right' }} />
        </PulsatingArrows></>}

      {!props.lineThrough && <BigText>{props.priceText}</BigText>}
      {props.lineThrough && <BigTextLineThrough>{props.priceText}</BigTextLineThrough>}
      {props.replaceText && <Text>{props.replaceText}</Text>}
      {props.priceText && <Text>Preis inkl. MwSt (19%)</Text>}

      <GreenButton {...{ text: props.text, redirectHandler: props.startProgramHandler }} />
    </Stack>
  </CallToActionWrapper>
);
