import React from "react";

import { useToggle } from "../../utils/useToggle";

import { InputGroup } from "../InputGroup/InputGroup";
import { SignUpFormWrapper, SubmitWrapper, SubmitButton, LoginLinkWrapper, LoginLinkWrapperButton, LoginLinkWrapperText } from "./styled-components";
import TermsText from "../TermsText/TermsText";

export const SignUpForm = ({ userData, handleChange, onSubmit, redirectToLogin, ...props }) => {
  const [isVisible, passwordToggle] = useToggle(false);
  const { email, firstName, password } = userData;
  const { isEmailValid, isPasswordValid } = props.validation;

  return (
    <SignUpFormWrapper>
      {/* <SubHeader>Registriere dich hier und hol dir deinen gratis CUBE.</SubHeader> */}

      <InputGroup
        {...{
          type: "text",
          name: "email",
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          label: "E-Mail*",
          isValid: isEmailValid,
        }}
      />

      <InputGroup
        {...{
          type: "password",
          name: "password",
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          label: "Passwort*",
          isVisible,
          passwordToggle,
          note: "Mind. 8 Zeichen inkl. Groß- und Kleinbuchstaben und 1 Ziffer.",
          isValid: isPasswordValid,
        }}
      />

      <InputGroup
        {...{
          type: "text",
          name: "firstName",
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          label: "Name*",
          isValid: true,
        }}
      />

      <SubmitWrapper>
        <SubmitButton
          {...{
            type: "submit",
            onClick: onSubmit,
            disabled: email && firstName && password ? false : true,
          }}
        >
          Jetzt registrieren
        </SubmitButton>

        <TermsText />
      </SubmitWrapper>

      <span>*Pflichtfelder</span>

      <LoginLinkWrapper>
        <LoginLinkWrapperText>Du hast bereits ein Konto - melde Dich hier an!</LoginLinkWrapperText>
        <LoginLinkWrapperButton onClick={redirectToLogin}>Jetzt anmelden</LoginLinkWrapperButton>
      </LoginLinkWrapper>
    </SignUpFormWrapper>
  );
};
