import React from 'react';
import { PaymentFormWrapper, ConfirmEmailWrapper, FailedText } from './styled-components';

export const MobilePaymentFailed = () => {
  return (
    <>
      <PaymentFormWrapper>
        <ConfirmEmailWrapper>
          <FailedText>Der Kauf wurde nicht ordnungsgemäß durchgeführt!</FailedText>
        </ConfirmEmailWrapper>
      </PaymentFormWrapper>
    </>
  );
};
