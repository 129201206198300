import styled from '@emotion/styled';

export const HeaderWrapper = styled.header((props) => ({
  maxWidth: props.isMobile ? 'unset' : props.isAbsolute ? 700 : 1920,
  margin: '0 auto',
  left: props.isAbsolute ? '50%' : null,
  transform: props.isAbsolute ? 'translate(-50%, 0)' : null,
  position: props.isAbsolute ? 'absolute' : 'relative',
  width: '100%',
  zIndex: 100,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: 70,
  transition: 'all .3s ease',
  '@media (max-width: 991px)': {
    position: 'relative',
    marginTop: 0,
    transform: props.isAbsolute && !props.isToggled ? 'translate(-50%, 0)' : 'none',
  },
  '@media (min-width: 1920px)': {},
  '&.fixed': {
    '@media (min-width: 981px)': {
      position: 'fixed',
      height: 50,
      marginTop: 0,
      background: 'rgba(29,29,29,0.9)',
      maxWidth: 'none',
      width: '100%',
    },
    '> div:last-of-type': {
      '@media (min-width: 981px)': {
        color: '#fff',
        position: props.isAbsolute ? 'relative' : 'absolute',
      },
    },
    ul: {
      border: 'none',
      span: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
          textDecoration: 'underline',
        },
      },
      a: {
        color: '#fff',
        '&:hover': {
          color: '#fff',
          textDecoration: 'underline',
        },
      },
    },
    '@media (max-width: 991px)': {
      background: 'inherit',
    },
  },
}));

export const LoginButton = styled.div(({ theme, ...props }) => ({
  padding: '0.5rem 1rem',
  fontSize: 16,
  fontFamily: 'Inter',
  color: props.light ? '#fff' : '#91A1B7',
  cursor: 'pointer',
  maxWidth: 200,
  position: 'absolute',
  right: props.light ? '-40%' : 100,
  '&::before': {
    content: "''",
    backgroundImage: "url('./assets/images/icons/login.svg')",
    display: 'inline-block',
    marginRight: '5px',
    width: 12,
    height: 12,
  },
  '&:hover': {
    color: props.light ? theme.colors.attention : theme.colors.darkGrey,
  },
}));

export const UserMenuWrapper = styled.div(({ isAbsolute }) => ({
  cursor: 'pointer',
  position: isAbsolute ? 'relative' : 'absolute',
  right: isAbsolute ? '-250px' : '10%',
  ':hover': {
    ul: {
      display: 'block',
    },
  },
  '@media (max-width: 1600px)': {
    right: isAbsolute ? '-100px' : '12%',
  },
}));

export const UserMenuIconWrapper = styled.div(({ theme }) => ({
  textAlign: 'center',
}));

export const UserNameWrapper = styled.div(({ theme, light, isLoggedIn }) => ({
  textAlign: 'center',
  color: light ? theme.colors.white : theme.colors.darkGrey,
  paddingBottom: 10,
  span: {
    display: 'block',
    margin: 0,
    lineHeight: 1.24,
    color: light ? theme.colors.white : theme.colors.warmGrey,
    fontSize: 12,
    fontWeight: 400,
    '&:first-of-type': {
      color: light ? theme.colors.white : theme.colors.warmGrey,
      fontSize: isLoggedIn ? 12 : 14,
      fontWeight: isLoggedIn ? 400 : 800,
    },
  },
}));

export const MyAccountWrapper = styled.ul(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  borderLeft: `.7px solid ${theme.colors.attention}`,
  background: 'transparent linear-gradient(240deg,#44556c,#151c24) 0 0 no-repeat padding-box',
  minWidth: 250,
}));

export const MyAccountItem = styled.li(({ theme }) => ({
  whiteSpace: 'nowrap',
  '&:not(:last-of-type)': {
    borderBottom: '.7px solid #cad2dd',
  },
  '> &:nth-of-type(2)': {
    padding: 0,
  },
  a: {
    fontSize: 16,
    display: 'block',
    color: theme.colors.white,
    padding: '20px 15px',
    '&:hover': { color: `${theme.colors.attention} !important` },
  },
}));
