import React from 'react';
import styled from '@emotion/styled';

import History from '../../utils/history';

export const ButtonWrapper = styled.div(({ theme }) => ({
  fontFamily: 'Red Hat Display',
  fontSize: '16px',
  fontStretch: '100%',
  border: '2px solid #721762',
  color: '#721762',
  width: '340px',
  height: 50,
  lineHeight: '48px',
  borderRadius: 12,
  fontSize: 18,
  fontWeight: 700,
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
}));

export const ButtonWrapperSecondary = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.cubeBox,
  color: theme.colors.black,
  width: 280,
  height: 45,
  lineHeight: '45px',
  borderRadius: 25,
  fontSize: 18,
  fontWeight: 500,
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.8,
  },
  '@media (max-width: 320px)': {
    width: '215px',
    height: '35px',
    lineHeight: '35px',
  },
}));

export const Button = ({ path, text, style, className, disabled, redirectHandler, onClick, href }) => {
  const relocate = () => {
    if (disabled) return;
    onClick && onClick();
    if (!path && redirectHandler) return redirectHandler();
    if (!path && !redirectHandler && !href) return;
    if (path) {
      History.push(path);
    } else if (href) {
      window.open(href, '_self');
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <ButtonWrapper onClick={relocate} style={style} className={className}>
        {text}
      </ButtonWrapper>
    </div>
  );
};
