import React, { useContext, useRef } from 'react';

import menu from '../../data/preventionMenu.json';
import footer from '../../data/preventionFooter.json';
import trainers from '../../data/preventionVideos.json';
import modules from '../../data/prevention8modules.json';
import preventionInsurance from '../../data/preventionInsurance.json';
import preventionFAQ from '../../data/preventionFAQ.json';
import { PreventionProgramDetails } from '../../data/preventionProgramDetails';

import { LandingPageWrapper } from '../../wrappers';

import { PreventionHero, PreventionAbout, PreventionVideo, EightModules, PreventionInsurance, PreventionExpert, PreventionFAQ, PreventionPartners } from '../../screens';
import { AppContext } from '../../App';
import history from '../../utils/history';
import { Section } from '../../components';
import { MiniText, Text } from './styled-components';
import { Image } from 'react-bootstrap';
import ReactGA from 'react-ga4'

import "./styles.css";
import { PaymentPage } from '../PaymentPage';

const steps = [
  {
    text: (
      <>
        Finde heraus wie viel deine Krankenkasse übernimmt. I.d.R. sind das mind. 75% und <strong>bis zu 100% u.a. bei der HKK, BIG Direkt, Salus BKK und YUBLE (AOK PLUS).</strong>
      </>
    ),
  },
  {
    text: <>Melde dich für unseren Präventionskurs an und übernimm die Kosten zunächst selbst.</>,
  },
  {
    text: (
      <>
        Unsere Trainer begleiten dich <strong>acht Wochen auf dem Weg zum schmerzfreien, gesünderen und fitteren Körper.</strong>
      </>
    ),
  },
  {
    text: <>Zum Abschluss des Kurses erhältst du eine Teilnahmebestätigung. Reiche diese bei deiner Krankenkasse ein und du bekommst bis zu 100% der Kosten zurück.</>,
  },
];

export const Prevention = () => {
  const { dispatch } = useContext(AppContext);
  const startProgramHandler = () => {
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: PreventionProgramDetails.cubeId,
        chosenCubePrice: PreventionProgramDetails.price,
        chosenCubeName: PreventionProgramDetails.productName,
        chosenCubeNumber: PreventionProgramDetails.itemNumber,
        chosenCubePriceNumber: PreventionProgramDetails.priceNumberAmount,
        isProgram: true,
      },
    });
    ReactGA.event({
      category: "prevention registration",
      action: "CTA clicked",
    });
    history.push('/praevention-start-program');
  };
  const insuranceSection = useRef(null);
  return (
    <LandingPageWrapper login={false} absolute menu={menu.praevention} footerLinks={footer.praevention} scrollToTop>
      <Image src='assets/images/logos/praeventionscube_zertifizierung_weiss.png' className='certificate-logo' ></Image>
      <Section>
        <PreventionHero startProgramHandler={startProgramHandler} />
      </Section>
      <Section>
        <PreventionAbout startProgramHandler={startProgramHandler} scrollRef={insuranceSection} />
      </Section>
      <Section>
        <PreventionVideo videos={trainers.videos} />
      </Section>
      <Section style={{ backgroundColor: '#F7F7F8' }}>
        <EightModules modules={modules.modules} startProgramHandler={startProgramHandler} />
      </Section>
      <Section>
        <PreventionInsurance steps={steps} coating={preventionInsurance.coating} startProgramHandler={startProgramHandler} scrollRef={insuranceSection} />
      </Section>
      <Section>
        <PreventionExpert startProgramHandler={startProgramHandler} />
      </Section>
      <Section>
        <PreventionFAQ questions={preventionFAQ.FAQ} />
      </Section>
      <PreventionPartners />
      <MiniText>* Der Erstattungsbetrag kann je nach Krankenkasse variieren. Die meisten gesetzlichen Krankenkassen erstatten bis zu 100% der Teilnahmekosten.</MiniText>
    </LandingPageWrapper>
  );
};
