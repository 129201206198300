import styled from '@emotion/styled';

export const ComponentWrapper = styled.div(({ ...props }) => ({
  display: 'flex',
  flexDirection: props.reverse ? 'row-reverse' : 'row',
  '> div': {
    maxWidth: '50%',
    width: '100% !important',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
    },
  },
  '.video-react-poster': {
    backgroundSize: 'cover',
  },
  '@media (max-width: 991px)': {
    flexDirection: 'column',
  },
}));

export const Description = styled.div(({ theme, ...props }) => ({
  color: theme.colors.darkGrey,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: props.reverse ? 'flex-end' : 'flex-start',
  paddingLeft: props.reverse ? 0 : 50,
  paddingRight: props.reverse ? 50 : 0,
  textAlign: props.reverse ? 'right' : 'left',
  '@media (max-width: 991px)': {
    padding: 20,
    textAlign: 'left',
    alignItems: 'flex-start',
  },
}));

export const Header = styled.p(({ theme }) => ({
  fontSize: '18px',
  letterSpacing: '0.63px',
  fontWeight: 'bold',
  color: theme.colors.black,
  '@media (max-width: 991px)': {
    fontSize: 22,
  },
}));

export const Text = styled.p(({ theme }) => ({
  maxWidth: 700,
  fontSize: '16px',
  letterSpacing: '0.4px',
  marginTop: 15,
  color: theme.colors.black,
  '@media (max-width: 991px)': {
    marginTop: 20,
  },
}));
