import styled from '@emotion/styled';

export const MobileCubeFormWrapper = styled.section(({ theme }) => ({
  width: '100%',
  paddingRight: '1.25rem',
  paddingLeft: '1.25rem',
  marginRight: 'auto',
  marginLeft: 'auto',
}));

// export const Text = styled.p({
//   fontFamily: 'Roboto',
//   fontSize: 30,
//   paddingLeft: '5px',
//   marginBottom: 20,
//   letterSpacing: 0.54,
//   lineHeight: '28px',
//   marginBottom: '20px',
//   color: '#44556C',
// });
