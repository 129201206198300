import styled from '@emotion/styled';

export const ImageSet = styled.picture({
  img: {
    '@media (max-width: 991px)': {
      height: '100vh',
      objectFit: 'cover',
    },
  },
});

export const LegendWrapper = styled.div({
  position: 'relative',
  margin: '0 calc(50vw - 430px)',
  maxWidth: 860,
  bottom: 100,
  width: '100%',
  display: 'flex',
  justifyContent: 'center',
  '@media (max-width: 991px)': {
    flexDirection: 'column',
    overflow: 'hidden',
    margin: 0,
  },
  '@media (max-width: 991px) and (orientation: landscape)': {
    flexDirection: 'row',
  },
});

export const QuoteWrapper = styled.div(({ theme }) => ({
  animation: 'smoothOpacity 3s forwards',
  width: '50%',
  textAlign: 'left',
  marginTop: 'auto',
  '@media (max-width: 991px)': {
    width: '100%',
    margin: 'auto',
    padding: '0 20px',
  },
  '@media (max-width: 991px) and (orientation: landscape)': {
    margin: 'auto',
    width: '100%',
  },
  '@media (min-width: 1400px)': {
    bottom: 80,
  },
}));

export const QuoteIcon = styled.div({
  display: 'block',
  width: 60,
  marginBottom: 15,
  '@media (max-width: 991px)': {
    width: 50,
  },
});

export const SlideHeader = styled.h3(({ theme }) => ({
  fontSize: 100,
  lineHeight: '135px',
  color: theme.colors.attention,
  '@media (max-width: 1500px)': {
    fontSize: 70,
    lineHeight: 1.5,
  },
  '@media (max-width: 991px)': {
    fontSize: 48,
    lineHeight: '78px',
  },
}));

export const SmallerText = styled.h5(({ theme }) => ({
  fontSize: '48px',
  lineHeight: 1.5,
  fontWeight: 700,
  color: theme.colors.white,
  '@media (max-width: 1500px)': {
    fontSize: 30,
  },
  '@media (max-width: 991px)': {
    fontSize: 20,
  },
}));

export const ItalicText = styled.p({
  fontFamily: 'Fave',
  fontSize: 81,
  lineHeight: '54px',
  color: '#fff',
  '@media (max-width: 1500px)': {
    fontSize: 55,
    lineHeight: 1,
  },
  '@media (max-width: 991px)': {
    fontSize: 46,
    lineHeight: '30px',
  },
});

export const CallToActionWrapper = styled.div({
  bottom: 0,
  display: 'flex',
  alignItems: 'flex-end',
  '@media (max-width: 991px)': {
    position: 'relative',
    right: 'auto',
    justifyContent: 'center',
    marginTop: 120,
  },
});

export const ButtonWrapper = styled.div({
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  animation: 'smoothOpacity 3s forwards',
});

export const Caption = styled.div(({ theme }) => ({
  maxWidth: 235,
  width: '100%',
  padding: '20px 25px',
  color: theme.colors.black,
  fontSize: 18,
  lineHeight: '26px',
  textAlign: 'center',
  background: 'transparent linear-gradient(122deg, #FFFFFF84 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;',
  border: '1px solid #DEE4EB',
  borderRadius: '0 0 16px 16px',
  '@media (max-width: 991px)': {
    background: 'transparent',
    border: 'none',
    padding: 0,
    marginTop: 10,
    color: theme.colors.white,
  },
}));

export const MockupWrapper = styled.div({
  marginLeft: -70,
  img: {
    maxHeight: 250,
    objectPosition: '0 5px',
  },
  '@media (max-width: 991px)': {
    display: 'none',
  },
});

export const PulsatingArrows = styled.div({
  position: 'absolute',
  left: -60,
  top: -5,
  width: 54,
  height: 55,
  animation: 'pulse 2s infinite',
  '@media (max-width: 991px)': {
    top: -75,
    right: 0,
    left: 0,
    margin: '0 auto',
    img: {
      transform: 'rotate(90deg)',
    },
  },
});
