import { useState, useCallback, useEffect } from 'react';

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [ready, setReady] = useState(false);

  const login = useCallback(jwtToken => {
    setToken(jwtToken);

    localStorage.setItem('token', jwtToken);
  }, []);

  const logout = useCallback(() => {
    setToken(null);
    localStorage.removeItem('token');
  }, []);

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      login(token)
    }
    setReady(true);
  }, [login]);

  return { login, logout, token, ready };
};
