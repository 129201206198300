import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';

import { initialState, reducer } from './reducer';
import { useRoutes } from './routes';
import { useAuth } from './utils/useAuth';

import history from './utils/history';
import ReactGA from 'react-ga4';
import { measurementIdGA } from './config/environment';

export const AppContext = React.createContext();

const App = () => {
  const { token, login, logout, ready } = useAuth();
  const isAuthenticated = !!token;
  const routes = useRoutes(isAuthenticated);
  const [state, dispatch] = React.useReducer(reducer, initialState);
  ReactGA.initialize(measurementIdGA);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: window.location.pathname });
  }, [window.location.pathname])

  if (!ready) return <p>Loading...</p>;

  return (
    <AppContext.Provider
      value={{
        dispatch,
        state,
        token,
        login,
        logout,
        isAuthenticated,
      }}
    >
      <Router history={history}>{routes}</Router>
    </AppContext.Provider>
  );
};

export default App;
