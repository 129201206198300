import React from 'react';

import { Image, CallToActionPrevention, SectionTitle, SubSectionTitle } from '../../components';
import { SectionWrapper, ContentWrapper, ExpertPhoto, ExpertContent } from './styled-components';

export const PreventionExpert = ({ startProgramHandler }) => (
  <SectionWrapper>
    <SubSectionTitle align='center'>ZUM EXPERTEN</SubSectionTitle>
    <SectionTitle align='center'>Entwickelt vom Präventionsexperten</SectionTitle>
    <ContentWrapper>
      <ExpertPhoto>
        <Image {...{ path: './assets/images/FrankThoemmes.png', alt: 'Frank Thömmes' }} />
        <p>Frank Thömmes</p>
        <p>Diplom-Sportlehrer</p>
      </ExpertPhoto>
      <ExpertContent>
        <p>Diplom-Sportlehrer Frank Thömmes hat dieses hat dieses effektive Ganzkörpertraining entwickelt.</p>
        <p>
          Als 10-facher Buchautor war es seine Motivation für diesen Kurs, die 30-jährige Erfahrung als Bewegungs- und Gesundheitsexperte mit über 1000 absolvierten Präventionskursstunden in einen
          einzigen Kurs zu integrieren, um möglichst vielfältige, tiefgreifende und damit verändernde Reize zu setzen.
        </p>
        <p>
          Jede der acht Kurseinheiten ist ein abgeschlossenes Stunden-Programm mit hohem Gesundheits-Mehrwert. Alle acht Stunden sind miteinander inhaltlich intelligent verknüpft und mit viel
          Mehrwert-Informationen versehen und damit zusammen die wohl beste Chance, das Leben in Richtung Gesundheit und Fitness zu verändern. 360°. Körperlich und mental.
        </p>
      </ExpertContent>
    </ContentWrapper>
    <CallToActionPrevention dark startProgramHandler={startProgramHandler} text='Jetzt Kurs starten' />
  </SectionWrapper>
);
