import React from 'react';
import { MobileCubeFormWrapper, Text } from './styled-components';
import { Row, Col } from 'react-bootstrap';
import { Accordion, Table } from 'bootstrap';
import './styled.scss';

export const MobileCubeBitterKur = () => {
  return (
    <MobileCubeFormWrapper className='mobile-cube-form-wrapper'>
      <div className='mobile-cube-container'>
        <Row className='row'>
          <Col className='col-12 jumbotron bg-light'>
            <div className='container'>
              <div className='infotext'>
                <h5 className='product-detail-name'>Bitter-Kur</h5>
                <h2>Anregend, regulierend, ausgleichend</h2>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Was kann dieser Health Cube?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='product-detail-description-text'>
                    <h3>Dauer</h3>
                    <span>28 Tage</span>
                  </div>
                  <div className='product-detail-description-text'>
                    <h3>Produktinformationen</h3>
                    <div>
                      Über Geschmack lässt sich bekanntlich streiten – über bitter ganz besonders! Unser Geschmacksrepertoire ist längst nicht mehr auf bitteren Geschmack trainiert, unser Gaumen hat
                      sich an süß und salzig gewöhnt. Das ist schade, denn Bitterstoffe sind eine Wohltat für unsere Gesundheit! Die pflanzlichen Allroundtalente intensivieren Geschmackserlebnisse,
                      regulieren den Appetit auf natürliche Weise und sind Verdauungshelfer.
                    </div>
                    <br />
                    <div>
                      Der Bittersegen® ist eine ausgewogene Mischung aus erlesenen Kräutern und Gewürzen nach einer alten Klosterrezeptur - eine beliebte Krönung einer jeden guten Mahlzeit. Mit der
                      Einnahme des Bittersegen® Kräuterbitters im Rahmen der 4-wöchigen Bitter-Kur gewöhnst du deine Geschmacksknospen nach und nach an den bitteren Geschmack und erfährst, wie du
                      Bitteres in deine Ernährung integrierst. Komm mit auf eine Reise in die Welt der Bitterstoffe:
                    </div>
                    <div>
                      <ul>
                        <li>Wie trainiert man den Geschmackssinn?</li>
                        <li>Welche Lebensmittel enthalten besonders viele Bitterstoffe?</li>
                        <li>Wie funktioniert die Bitter-Kur?</li>
                        <li>Was macht den Bittersegen® Kräuterbitter besonders?</li>
                        <li>Welchen Einfluss haben Bitterstoffe auf Körper und Gesundheit?</li>
                      </ul>
                    </div>
                    <div>
                      Durch den Bitter-Kur Cube holst du Bitterstoffe Schritt für Schritt zurück in deinen Alltag und profitierst vielseitig von ihrer besonderen natürlichen Kraft. Ein Sprichwort
                      besagt: „Was am Anfang bitter ist, wird am Ende süß”
                    </div>
                  </div>
                  <div className='product-detail-ordernumber-container'>
                    <h3>
                      <span className='product-detail-ordernumber-label'>Artikelnummer:</span>
                    </h3>
                    <span className='product-detail-ordernumber'>30057</span>
                  </div>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Welche digitalen gratis Tools sind enthalten?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <ul>
                    <li className='bold'>
                      <span>Smart Reminder Tool</span>
                      <div className='li-text'>
                        Zusammen mit deinem Health Cube aktivierst du automatisch den Smart Reminder. Das Tool, das dich rechtzeitig an die Einnahme deiner Supplements erinnert und eine
                        Einnahmeübersicht erstellt.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Welche Supplements sind im Paket inkludiert?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <ul>
                    <li>
                      <span>Bittersegen® (50 ml) der Marke LIFE LIGHT®</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Zutaten und Nährwerte-Tabelle</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div>
                    <div className='bold'>Zutaten (Bittersegen®)</div>
                  </div>
                  <div className='margin-top'>
                    <div>
                      Alkohol 59 % vol., Wasser, pflanzliche Kräuter und Gewürzextrakte (Wermutkraut, Engelwurz, Pomeranzenschalen, Isländisch Moos, Kardamom, Mariendistel, Kümmel, Gewürznelke, Zimt,
                      Korianderfrüchte, Fenchel, Galgantwurzelstock, Enzian, Lavendel, Majoran, Melisse, Schafgarbe, Löwenzahn, Zitwerwurzel, Ingwer)
                    </div>
                  </div>
                  <div className='margin-top small-line-height'>
                    Hinweis: Nahrungsergänzungsmittel sind kein Ersatz für eine ausgewogene und abwechslungsreiche Ernährung sowie eine gesunde Lebensweise. Die empfohlene Tagesdosis nicht
                    überschreiten. Fragen Sie Ihren Arzt, Therapeuten oder Apotheker.
                  </div>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Nährstoffwissen</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='bold'>Physiologische Funktion der Nährstoffe</div>
                  <div>
                    <ul>
                      <li className='bold'>
                        <span className='li-text'>Die </span>
                        <span>ausgewogene Mischung aus erlesenen Kräutern und Gewürzen </span>
                        <span className='li-text'>machen den Bittersegen® seit über 20 Jahren zu einer beliebten Krönung jeder guten Mahlzeit.</span>
                      </li>
                      <li>
                        Bittersegen® ist eine klare bis leicht trübe Flüssigkeit. Mit der Zeit kann es zu leichten Ausflockungen kommen. Dieser Effekt hat keinen Einfluss auf die hohe Qualität des
                        Erzeugnisses.
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div className='bold'>Anwendungsbereich</div>
                    <div>
                      <ul>
                        <li>
                          <span>Ergänzung der täglichen Ernährung für jedes Alter</span>
                        </li>
                        <li>
                          <span>Vegetarische und vegane Ernährungsweise</span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div>
                    <div className='bold'>Verzehrempfehlung</div>
                    <div className='margin-top'>
                      3 x täglich 7 – 14 Tropfen mit etwas Wasser als Aperitif vor dem Essen oder ½ bis 1 Teelöffel pur als Digestif nach dem Essen einnehmen. Eine Daueranwendung ist empfehlenswert.
                    </div>
                  </div>

                  <div className='margin-top'>
                    <div className='bold'>Der Smart Reminder erinnert automatisch an die Einnahme der Supplements.</div>
                  </div>

                  <div className='margin-top'>
                    <div className='bold'>Lagerhinweis</div>
                    <div className='margin-top'>Geschlossen, kühl, trocken und lichtgeschützt außerhalb der Reichweite von kleinen Kindern lagern.</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MobileCubeFormWrapper>
  );
};
