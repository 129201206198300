import React, { useContext } from 'react';
import MediaQuery from 'react-responsive';
import { Nav } from 'react-bootstrap';
import { AppContext } from '../../App';
import { BLogo } from '../';
import { Navigation, MenuWrapper, SubmenuWrapper, MenuItemWrapper, CloseButton, NoLogo } from './styled-components';
import history from '../../utils/history';
import 'bootstrap/dist/css/bootstrap.min.css';

export const Menu = ({ menuList, light, isOpen, toggle, children, isMobile, isLoggedIn, setToken, login }) => {
  const [mobileMenu, setMobileMenu] = React.useState(isOpen);
  const { dispatch } = useContext(AppContext);
  const onLogout = () => {
    localStorage.clear();
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: null,
        isChosenCubeFree: null,
        chosenCubePrice: null,
        chosenCubePriceNumber: null,
        chosenCubeName: null,
        chosenCubeNumber: null,
      },
    });
    history.push('/');
    setToken(null);
  };

  React.useEffect(() => {
    setMobileMenu(isOpen);
  }, [isOpen]);

  return (
    <Navigation {...{ light, mobileMenu }}>
      <MediaQuery maxWidth={991}>
        <CloseButton onClick={toggle} />
        {
          //HACK When there is no login, do not show logo. For prevention workflow
        }
        {login ? <BLogo /> : <NoLogo />}
      </MediaQuery>

      <MenuWrapper>
        {menuList.map((menuItem, index) =>
          menuItem.subPages ? (
            <MenuItemWithSubmenu title={menuItem.title} subPages={menuItem.subPages} key={index} close={toggle} isMobile={isMobile} deepLink={menuItem.deepLink} />
          ) : (
            <MenuItem title={menuItem.title} path={menuItem.path} key={index} close={toggle} isMobile={isMobile} deepLink={menuItem.deepLink} />
          )
        )}
        {login &&
          isMobile &&
          (isLoggedIn ? (
            <>
              <MenuItem title={'Mein Konto'} path={'/user-profile'} key={'user-profile'} close={toggle} isMobile={isMobile} />
              <MenuItem title={'Logout'} onClick={onLogout} key={'login'} close={toggle} isMobile={isMobile} />
            </>
          ) : (
            <MenuItem title={'Login'} path={'/login'} key={'login'} close={toggle} isMobile={isMobile} />
          ))}
      </MenuWrapper>
    </Navigation>
  );
};

const MenuItem = ({ title, path, close, isMobile, deepLink, onClick }) => (
  <MenuItemWrapper>
    <Nav.Link
      {...{
        active: window.location.pathname === path ? true : false,
        href: path?.includes('#') ? path : null,
        onClick: () => {
          if (onClick) return onClick();
          if (isMobile && deepLink) {
            const a = document.createElement('a');
            a.href = deepLink;
            return a.click();
          }
          history.push(path);
          close();
        },
      }}
    >
      {title}
    </Nav.Link>
  </MenuItemWrapper>
);

const MenuItemWithSubmenu = ({ title, subPages, close, isMobile, deepLink }) => (
  <MenuItemWrapper>
    <span className='nav-link'>{title}</span>
    <SubmenuWrapper>
      {subPages.map((subItem, index) => (
        <MenuItem title={subItem.title} path={subItem.path} key={index} close={close} isMobile={isMobile} deepLink={deepLink} />
      ))}
    </SubmenuWrapper>
  </MenuItemWrapper>
);
