import React from 'react';
import MediaQuery from 'react-responsive';

import { GreenButton, Image } from '../../components';
import { Quote } from '../../components/MainSlider/Legend';

import { SectionWrapper, ContentWrapper, CTAWrapper, Text, PulsatingArrows } from './styled-components';

export const PreventionHero = ({ startProgramHandler }) => (
  <SectionWrapper>
    <ContentWrapper>
      <Quote subtitle='App basierter Onlinekurs: 360° Gesund und Fit - effektives Ganzkörperkräftigungstraining ohne Hilfsmittel' courseId='Kurs-ID: KU-BE-BHKHD6' slogan={false} />
      {/* <MediaQuery maxWidth={991}>
        <CTAWrapper>
          <PulsatingArrows>
            <Image {...{ path: './assets/images/icons/arrow-right-green.svg', alt: 'arrow right' }} />
          </PulsatingArrows>
          <Text>
            für nur <span>€ 89,99</span>
          </Text>
          <Text>Preis inkl. MwSt (19%)</Text>
          <GreenButton {...{ onClick: startProgramHandler, text: 'Jetzt starten!' }} />
        </CTAWrapper>
      </MediaQuery> */}
    </ContentWrapper>
  </SectionWrapper>
);
