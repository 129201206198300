import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  marginTop: 60,
  display: 'flex',
  width: '100%',
  paddingBottom: 145,
  '@media (max-width: 660px)': {
    flexDirection: 'column',
    marginTop: 85,
  }
});

export const ContentWrapper = styled.div({
  marginLeft: 90,
  padding: '0 12px',
  maxWidth: 714,
  width: '100%',
  '@media (max-width: 1250px)': {
    marginLeft: 0,
  }
});
