import React from 'react';
import styled from '@emotion/styled';

const ComponentWrapper = styled.div(({ theme, ...props }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: props.align,
  marginBottom: 20,
}));

const Text = styled.span(({ theme, textStyle }) => ({
  color: theme.colors.black,
  textTransform: 'uppercase',
  fontSize: '18px',
  letterSpacing: '0.9px',
  fontWeight: 500,
  '@media (max-width: 991px)': {
    fontSize: 20,
    letterSpacing: 5,
  },
  ...textStyle,
}));

const Line = styled.span(({ theme, lineStyle }) => ({
  backgroundColor: theme.colors.warmGrey,
  height: 2,
  width: 24,
  marginRight: 15,
  '@media (max-width: 991px)': {
    width: 20,
  },
  ...lineStyle,
}));

export const TitleWithLine = ({ children, textStyle, lineStyle, ...props }) => (
  <ComponentWrapper {...props}>
    <Line lineStyle={lineStyle} />
    <Text textStyle={textStyle}>{children}</Text>
  </ComponentWrapper>
);
