import React from 'react';
import MediaQuery from 'react-responsive';
import { Container, Stack, Row, Col } from 'react-bootstrap';

import { Image, CallToActionPrevention, SectionTitle, GreenButton, Section } from '../../components';
import { SectionWrapper, SubHeader, Text, CalculateCTA, CalculateCTAContent, CalculateCTAImage, TextWrapper, CalculateCTATitle, MobileBlock, UnderlinedText, Ul } from './styled-components';

export const PreventionAbout = ({ startProgramHandler, scrollRef }) => (
  <SectionWrapper>
    <Section>
      <Container className='g-4'>
        <Row>
          <Col lg={8}>
            <Stack>
              <SectionTitle>Tue etwas für deine Gesundheit und die Krankenkasse zahlt dafür!</SectionTitle>
              <SubHeader>
                Der App basierte Onlinekurs: 360° Gesund und Fit - effektives Ganzkörperkräftigungstraining ohne Hilfsmittel ist ein Präventionskurs{' '}
                <span>mit acht Praxiseinheiten zu allen wichtigen Themen</span> des Krafttrainings.
              </SubHeader>
              <UnderlinedText>Ist unser Präventionskurs der richtige für dich?</UnderlinedText>
              <Ul>
                <li>Leidest du unter Schmerzen im Alltag?</li>
                <li>Bereiten dir alltägliche Bewegungen Probleme?</li>
                <li>Hast du Schmerzen vom langen Sitzen oder Stehen?</li>
                <li>Du möchtest endlich was gegen deine Beschwerden tun?</li>
              </Ul>
              <Text>
                <span>Dann bist du bei unserem Kurs genau richtig!</span> Bewegung ist Medizin für viele Beschwerden des Alltags. Wir unterstützen dich mit Motivationstipps, Hausaufgaben und vielem
                mehr. Wir halten deine Motivation auf dem Weg zum Ziel hoch, nämlich 360° Gesund und Fit zu werden, und zu bleiben.{' '}
              </Text>
              <Text>
                <span>Kostenlos*, ohne Abo, ohne Kündigung!</span>
              </Text>
            </Stack>
          </Col>
          <Col lg={4}>
            <MediaQuery minWidth={992}>
              <CallToActionPrevention dark mockup startProgramHandler={startProgramHandler} priceText='€ 89,99' replaceText='€ 0*' text='Jetzt starten' lineThrough />
            </MediaQuery>
          </Col>
        </Row>
        <MediaQuery maxWidth={991}>
          <Row>
            <CallToActionPrevention withoutImage dark mockup startProgramHandler={startProgramHandler} priceText='€ 89,99' replaceText='€ 0*' text='Krankenkasse zahltJetzt starten' lineThrough />
          </Row>
        </MediaQuery>
      </Container>
    </Section>
    <CalculateCTA>
      <CalculateCTAContent>
        <CalculateCTATitle style={{ maxWidth: 350 }}>Du trainierst mit uns und deine Krankenkasse zahlt!</CalculateCTATitle>
        <TextWrapper>
          <Text style={{ maxWidth: 300 }}>Wir zeigen dir, wie hoch der Erstattungsbetrag ist.</Text>
          <Image {...{ path: './assets/images/icons/empty-wallet-tick.svg', alt: 'Coins' }} />
        </TextWrapper>
        <MediaQuery maxWidth={991}>
          <MobileBlock>
            <Image {...{ path: './assets/images/MoneyBack.png', alt: 'MoneyBack' }} />
            <Image {...{ path: './assets/images/icons/empty-wallet-tick.svg', alt: 'Coins' }} />
          </MobileBlock>
          <Text style={{ maxWidth: 300 }}>Wir zeigen dir, wie hoch der Erstattungsbetrag ist.</Text>
        </MediaQuery>
        <GreenButton
          style={{ margin: '20px auto 0' }}
          redirectHandler={() => {
            window.scrollTo({ top: scrollRef.current.offsetTop, behaviour: 'smooth' });
          }}
          text='Zum Krankenkassen-Rechner'
        />
      </CalculateCTAContent>
      <CalculateCTAImage>
        <Image {...{ path: './assets/images/MoneyBack.png', alt: 'MoneyBack' }} />
      </CalculateCTAImage>
    </CalculateCTA>
  </SectionWrapper>
);
