import React from 'react';
import styled from '@emotion/styled';

const ComponentWrapper = styled.div(({ theme, ...props }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: props.align,
  '@media (max-width: 991px)': {
    justifyContent: 'left',
  },
  marginBottom: 20,
}));

const Text = styled.span(({ theme, textStyle, ...props }) => ({
  color: props.light ? theme.colors.white : theme.colors.black,
  textTransform: 'uppercase',
  fontSize: '18px',
  lineHeight: '26px',
  letterSpacing: '0.9px',
  fontWeight: 500,
  ...textStyle,
}));

export const SubSectionTitle = ({ children, textStyle, ...props }) => (
  <ComponentWrapper {...props}>
    <Text textStyle={textStyle} {...props}>
      {children}
    </Text>
  </ComponentWrapper>
);
