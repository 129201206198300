import styled from '@emotion/styled';

export const NavWrapper = styled.div({
  marginLeft: 250,
  maxWidth: 262,
  width: '100%',
  fontFamily: 'Inter',
  '@media (max-width: 1250px)': {
    marginLeft: 50,
  }
});

export const Tab = styled.div(({ theme, active }) => ({
  cursor: 'pointer',
  color: active ? theme.colors.darkGrey : theme.colors.warmGrey,
  fontSize: 16,
  fontWeight: active ? 'bold' : 'normal',
  borderBottom: active ? `1px solid ${theme.colors.warmGrey}` : `1px solid ${theme.colors.white}`,
  width: 'fit-content',
  marginLeft: 25,
  marginTop: 20,
}));

export const TabsGroupHeader = styled.p(({ theme, ...props }) => ({
  fontSize: 18,
  color: theme.colors.darkGrey,
  fontWeight: 'bold',
  '&:not(:first-of-type)': {
    marginTop: 60
  }
}));
