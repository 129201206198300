import React, { useEffect } from 'react';
import MediaQuery from 'react-responsive';
import { ToastContainer } from 'react-toastify';

import { LandingPageWrapper } from '../../wrappers/LandingPageWrapper';
import { SubSectionTitle, Image, SectionTitle, Section } from '../../components';
import { Quote } from '../../components/MainSlider/Legend';
import modules from '../../data/prevention8modules.json';
import menu from '../../data/preventionMenu.json';
import footer from '../../data/preventionFooter.json';
import { AppContext } from '../../App';
import { SmallPrintText } from '../OrderConfirmation/styled-components';
import {
  SectionWrapper,
  BoldText,
  Text,
  CubePictureWrapper,
  ImageSet,
  QuoteWrapper,
  ModulesSectionWrapper,
  TextWrapper,
  ModuleText,
  ModulesWrapper,
  ModulesGrid,
  ModuleImageWrapper,
  RingIcon,
  ModuleTitle,
  LastSectionWrapper,
  LargeRing,
} from './styled-components';
import { BulletList } from '../../components';
import { EightModules } from '../../screens';
import { Col, Container, Row, Stack } from 'react-bootstrap';

const banner = {
  desktopImage: './assets/cubes/GesundUndFit-min.png',
  mobileImage: './assets/cubes/GesundUndFitMobile-min.png',
  quote: 'Onlinekurs 360° Gesund und Fit',
  price: '€ 89,99',
};

export const ProgramConfirmation = () => {
  const { state, dispatch } = React.useContext(AppContext);
  useEffect(() => {
    sessionStorage.removeItem('temporaryToken');
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: null,
        isChosenCubeFree: null,
        chosenCubePrice: null,
        chosenCubePriceNumber: null,
        chosenCubeName: null,
        chosenCubeNumber: null,
      },
    });
  }, []);

  return (
    <LandingPageWrapper login={false} menu={menu.praevention} footerLinks={footer.praevention} burgerTheme='dark' scrollToTop>
      <Section>
        <Container className='g-4'>
          <SectionWrapper>
            <SubSectionTitle>Eine gute Entscheidung</SubSectionTitle>
            <SectionTitle>Herzlichen Glückwunsch!</SectionTitle>
            <Text>
              {`Gratuliere${
                state.user?.userDetail?.firstName ? ' ' + state.user.userDetail.firstName : ''
              }, mit dem App basierten Onlinekurs: 360° Gesund und Fit - effektives Ganzkörperkräftigungstraining ohne Hilfsmittel hast du definitiv eine `}
              <span>{`gute Wahl getroffen`}</span>
              {`.`}
            </Text>
          </SectionWrapper>
        </Container>
        <CubePictureWrapper>
          <ImageSet>
            <source media='(max-width: 980px) and (orientation: portrait)' srcSet={banner.mobileImage} />
            <source media='(max-width: 980px) and (orientation: landscape)' srcSet={banner.desktopImage} />
            <Image {...{ path: banner.desktopImage, alt: 'Cube picture' }} />
          </ImageSet>
          <QuoteWrapper>
            <Quote
              subtitle='Herzlich Willkommen!'
              slogan={false}
              quoteWrapperStyles={{
                width: '100%',
                margin: 'auto',
              }}
            />
          </QuoteWrapper>
        </CubePictureWrapper>
      </Section>

      <Section style={{ backgroundColor: '#F7F7F8' }}>
        <EightModules modules={modules.modules} isProgramConfirmation />
      </Section>
      <Container className='g-4'>
        <TextWrapper>
          <SectionTitle>Die wichtigsten Informationen im Überblick:</SectionTitle>
          <Row className='align-items-center'>
            <Col md={9}>
              <BulletList>
                <li>Deine Welcome E-Mail mit allen wichtigen Informationen zum Login findest du in Kürze in deinem E-Mail Postfach.</li>
                <li>Jede Woche werden neue Kursinhalte freigeschaltet.</li>
                <li>Deine Teilnehmerbestätigung erhältst du nach erfolgreicher Teilnahme nach Ablauf der 8 Wochen.</li>
                <li>Nach 8 Wochen kannst du die Teilnahmebestätigung bei deiner Krankenkasse einreichen und somit eine Kostenübernahme beantragen.</li>
                <li>
                  Solltest du nicht für den Kurs bezahlt haben, so hat deine Krankenkasse oder dein Arbeitgeber die Kosten bereits im Voraus übernommen und du brauchst nichts weiter zu unternehmen.
                </li>
              </BulletList>
              <Text style={{ marginTop: '10%' }}>Viel Erfolg wünschen dir deine Fitness-Coaches Detlef Soost & Pamela Dutkiewicz-Emmerich und dein Experte Frank Thömmes!</Text>
            </Col>
            <Col md={3} className='mt-4'>
              <div style={{ position: 'relative', width: '200px', height: '275px', margin: 'auto' }}>
                <Image style={{ width: '200px', height: '200px' }} {...{ path: './assets/images/mocks/MockUps_Trainer@2.png', alt: 'iPhone Mockup' }} />
                <LargeRing>
                  <span>
                    Jetzt
                    <br />
                    Starten
                  </span>
                </LargeRing>
              </div>
            </Col>
          </Row>
          <SmallPrintText>Dies ist eine unverbindliche Bestellbestätigung. Nach erfolgreicher Zahlung der Bestellung wird eine Bestellbestätigung per Mail versendet.</SmallPrintText>
        </TextWrapper>
      </Container>
      <ToastContainer />
    </LandingPageWrapper>
  );
};
