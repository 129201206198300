import React from 'react';

import { Image } from '../../components';
import { SectionWrapper } from './styled-components';
import { Col, Row } from 'react-bootstrap';

export const PreventionPartners = () => (
  <SectionWrapper>
    <Row style={{ alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
      <Col>
        <Image {...{ path: './assets/images/partners/AOK.png', alt: 'AOK' }} />
      </Col>
      <Col>
        <Image {...{ path: './assets/images/partners/bkk.png', alt: 'bkk' }} />
      </Col>
      <Col>
        <Image {...{ path: './assets/images/partners/HKK (1).png', alt: 'hkk' }} />
      </Col>
      <Col>
        <Image {...{ path: './assets/images/partners/yuble (1).png', alt: 'Yuble' }} />
      </Col>
    </Row>
  </SectionWrapper>
);
