import styled from '@emotion/styled';

export const CubeHeaderWrapper = styled.section({
  marginTop: 80,
  marginBottom: 45,
  textAlign: 'center',
  color: '#44556c',
  '@media (max-width: 991px)': {
    order: 2,
    borderBottom: '0.5px solid #cbd0d6',
    margin: '40px 20px 60px',
  },
});

export const SectionTitle = styled.h3({
  font: '70px/55px "Fave"',
  letterSpacing: '2.1px',
  marginBottom: 35,
  '@media (max-width: 991px)': {
    fontSize: 32,
    lineHeight: 1.24,
    marginBottom: 15,
  },
});

export const SectionSubtitle = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '32px',
  letterSpacing: '0.24px',
  marginBottom: 15,
  '@media (max-width: 991px)': {
    fontSize: 20,
    lineHeight: '26px',
  },
});

export const LinkWrapper = styled.div({
  fontSize: 16,
  lineHeight: '32px',
  textDecoration: 'underline',
  img: {
    width: 15,
    marginRight: 15,
  },
  a: {
    color: '#91A1B7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      color: '#44556c',
    },
    '@media (max-width: 991px)': {
      alignItems: 'flex-start',
    },
  },
  '@media (max-width: 991px)': {
    lineHeight: '24px',
    paddingBottom: 40,
  },
});
