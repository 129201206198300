import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  paddingTop: '1%',
  paddingBottom: '1%',
});

export const VideosWrapper = styled.div({
  marginTop: '1%',
  marginBottom: '1%',
});
