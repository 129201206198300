import React from 'react';
import MediaQuery from 'react-responsive';
import Slider from 'react-slick';

import { SectionTitle, SubSectionTitle, Image, Teaser } from '../../components';
import {
  SectionWrapper,
  TextWrapper,
  SubHeader,
  Text,
  ModulesWrapper,
  ModulesGrid,
  ModuleImageWrapper,
  RingIcon,
  ModuleTitle,
  ModuleDescription,
  Arrow,
  ArrowWrapper,
  LargeRing,
} from './styled-components';

const ArrowNext = (props) => (
  <ArrowWrapper onClick={props.onClick} className={props.className} next>
    <Arrow />
  </ArrowWrapper>
);

const ArrowPrev = (props) => (
  <ArrowWrapper onClick={props.onClick} className={props.className}>
    <Arrow left />
  </ArrowWrapper>
);

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  // adaptiveHeight: true,
  arrows: true,
  nextArrow: <ArrowNext />,
  prevArrow: <ArrowPrev />,
};

export const EightModules = ({ modules = [], startProgramHandler = () => {}, isProgramConfirmation = false }) => {
  const [activeModule, setActiveModule] = React.useState({});

  React.useEffect(() => {
    if (modules.length) setActiveModule(modules[0]);
  }, [modules]);

  const handleSelectModule = (module) => {
    setActiveModule(module);
    const moduleDescriptionElement = document.getElementById('moduleDescription');
    if (moduleDescriptionElement) {
      moduleDescriptionElement.scrollIntoView({ block: 'nearest', behaviour: 'smooth' });
    }
  };

  return (
    <SectionWrapper id='eightModules'>
      <TextWrapper>
        {!isProgramConfirmation && <SubSectionTitle>Zum Programm</SubSectionTitle>}
        <SectionTitle>8 Module - 8 Wochen Gesundheits-Coaching</SectionTitle>
        {!isProgramConfirmation && <Teaser style={{ textAlign: 'left', marginBottom: '2%' }}>Das erwartet dich in unserem Kurs</Teaser>}
        <Text>
          {isProgramConfirmation ? (
            <>
              In den nächsten 8 Wochen erhältst du Woche für Woche neue Kursmodule, mit denen du lernst, ein Leben lang fit und gesund zu bleiben. Jedes Modul beinhaltet eine strukturierte Lern- und
              Übungseinheit bestehend aus einem Intro-Video, einem Hauptübungsteil und einem Outro-Video. In Videos und Textanleitungen werden Inhalte vermittelt, wie man ein Leben den
              Bewegungsapparat mobilisiert und kräftigt.
            </>
          ) : (
            <>
              Acht perfekt aufeinander abgestimmte Kurseinheiten, die dir helfen, <span>Schmerzen zu lindern und Verspannungen zu lösen</span>. Begleitend zu jeder Stunde findest du ein Video zu jedem
              Thema und “Hausaufgaben”, um den Beschwerden vorzubeugen und langfristig fit und gesund zu bleiben.
            </>
          )}
        </Text>
      </TextWrapper>

      <ModulesWrapper>
        {!isProgramConfirmation && (
          <div style={{ height: '100px' }}>
            <LargeRing onClick={startProgramHandler}>
              <span style={{ maxWidth: '70px', textAlign: 'center', textTransform: 'uppercase' }}>Jetzt Kurs sichern</span>
            </LargeRing>
          </div>
        )}
        <MediaQuery minWidth={992}>
          <ModulesGrid>
            {modules.map((module, index) => (
              <ModuleImageWrapper key={module.title} onClick={() => handleSelectModule(module)} active={activeModule === module ? true : false}>
                <RingIcon>
                  <span>WOCHE</span>
                  <span>{index + 1}</span>
                </RingIcon>
                <ModuleTitle>{module.title}</ModuleTitle>
                <Image {...{ path: module.image, alt: module.title }} />
              </ModuleImageWrapper>
            ))}
          </ModulesGrid>
          <ModuleDescription id='moduleDescription'>
            <span>{activeModule.title}</span>
            <p>{activeModule.description}</p>
          </ModuleDescription>
        </MediaQuery>

        <MediaQuery maxWidth={991}>
          <Slider {...sliderSettings}>
            {modules.map((module, index) => (
              <React.Fragment key={module.title}>
                <ModuleImageWrapper onClick={() => setActiveModule(module)} active={activeModule === module ? true : false}>
                  <RingIcon>
                    <span>WOCHE</span>
                    <span>{index + 1}</span>
                  </RingIcon>
                  <Image {...{ path: module.image, alt: module.title }} />
                </ModuleImageWrapper>
                <ModuleDescription>
                  <span>{module.title}</span>
                  <p>{module.description}</p>
                </ModuleDescription>
              </React.Fragment>
            ))}
          </Slider>
        </MediaQuery>
      </ModulesWrapper>
    </SectionWrapper>
  );
};
