import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  padding: '90px 0 130px',
  maxWidth: 700,
  margin: '0 auto',
  '@media (max-width: 991px)': {
    padding: '55px 20px 75px',
    maxWidth: '100%',
  },
});

export const ItalicText = styled.h3({
  fontFamily: 'Fave',
  fontSize: 70,
  lineHeight: '55px',
  color: '#44556c',
  letterSpacing: 2.1,
  marginBottom: 35,
  '@media (max-width: 991px)': {
    fontSize: 32,
    lineHeight: 1.24,
    marginBottom: 15,
  },
});

export const Subheader = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '35px',
  marginBottom: 65,
  color: '#44556c',
  '@media (max-width: 991px)': {
    fontSize: 20,
    lineHeight: 1.5,
    marginBottom: 10,
  },
});

export const ContentWrapper = styled.div({
  display: 'flex',
  '@media (max-width: 991px)': {
    flexDirection: 'column',
    fontSize: 18,
  },
});

export const TextBlock = styled.div({
  maxWidth: 460,
  p: {
    '&:first-of-type': {
      marginBottom: 20,
    },
    '&.strong': {
      marginTop: 30,
    },
  },
});

export const CallToActionBlock = styled.div({
  marginLeft: 80,
  position: 'relative',
  img: {
    maxHeight: 272,
    width: 'auto',
  },
  div: {
    position: 'absolute',
    top: '45%',
    left: '70%',
  },
  '@media (max-width: 991px)': {
    margin: '30px auto 0',
    img: {
      display: 'none',
    },
    div: {
      position: 'relative',
      left: 0,
      top: 'auto',
    },
  },
});
