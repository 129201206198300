import React from 'react';
import styled from '@emotion/styled';

import { BackToTopButton, Image, GreenButton } from '..';

const CallToActionWrapper = styled.div({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  paddingBottom: 110,
  maxWidth: 1920,
  margin: '0 auto',
  '@media (max-width: 991px)': {
    marginTop: 60,
  },
  'div:last-child': {
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
});

const MainBlock = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  img: {
    width: 45,
    height: 48,
    marginBottom: 12,
    animation: 'pulse 2s infinite',
  },
  p: {
    marginTop: 20,
    fontSize: 14,
    fontWeight: 500,
    textAlign: 'center',
  },
});

const MockupWrapper = styled.div({
  img: {
    maxWidth: 138,
  },
  '@media (max-width: 991px)': {
    display: 'none',
  },
});

export const CallToActionRegister = (props) => (
  <CallToActionWrapper {...props}>
    <MainBlock>
      <Image {...{ path: './assets/images/icons/arrow-down-green.svg', alt: 'Green Arrow Down' }} />
      <GreenButton {...{ path: '/sign-up', text: 'Jetzt registrieren' }} />
      <p>
        Melde dich HIER an und
        <br />
        hol dir deinen gratis CUBE.
      </p>
    </MainBlock>

    <MockupWrapper>
      <Image {...{ path: './assets/images/mocks/Register_male@2.png', alt: 'CTA' }} />
    </MockupWrapper>

    <BackToTopButton />
  </CallToActionWrapper>
);
