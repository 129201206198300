import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  padding: '100px 0 0',
  backgroundImage: "url('./assets/backgrounds/8EHealth-Concept-BG.png')",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '> div:last-of-type': {
    marginTop: -100,
    '@media (max-width: 991px)': {
      marginTop: 0,
    },
  },
  h3: {
    '@media (max-width: 991px)': {
      textAlign: 'left',
    },
  },
  '@media (max-width: 991px)': {
    padding: '70px 20px 0',
  },
});

export const Subheader = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '35px',
  marginTop: 15,
  marginBottom: 70,
  textAlign: 'center',
  '@media (max-width: 991px)': {
    fontSize: 22,
    lineHeight: '30px',
    textAlign: 'left',
    marginBottom: 15,
  },
});

export const ContentWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  margin: '0 auto',
  div: {
    '&:first-of-type': {
      marginRight: 0,
      marginLeft: 'auto',
      maxWidth: 460,
      '@media (max-width: 991px)': {
        order: 1,
      },
      '@media (max-width: 991px) and (orientation: landscape)': {
        marginLeft: 0,
        marginRight: 'auto',
      },
    },
    '&:nth-of-type(2)': {
      '@media (max-width: 991px)': {
        order: 3,
      },
    },
    '&:nth-of-type(3)': {
      textAlign: 'right',
      '@media (max-width: 991px)': {
        order: 2,
        textAlign: 'center',
      },
      '@media (max-width: 991px) and (orientation: landscape)': {
        display: 'none',
      },
    },
    '&:nth-of-type(4)': {
      '@media (max-width: 991px)': {
        order: 4,
      },
    },
  },
  '@media (max-width: 991px) and (orientation: portrait)': {
    flexDirection: 'column',
    marginTop: 45,
  },
});

export const ContentBlockWrapper = styled.div({
  maxWidth: '50%',
  width: '100%',
  paddingRight: 20,
  p: {
    maxWidth: 460,
    marginBottom: 65,
    '&:first-of-type': {
      marginBottom: 30,
      fontWeight: 'bold',
      '@media (max-width: 991px)': {
        marginBottom: 15,
      },
    },
  },
  img: {
    maxWidth: 470,
    '@media (max-width: 991px) and (orientation: portrait)': {
      maxWidth: '100%',
    },
    '@media (max-width: 991px) and (orientation: landscape)': {
      maxWidth: 370,
    },
  },
  '@media (max-width: 991px)': {
    maxWidth: '100%',
    fontSize: 18,
    paddingRight: 0,
  },
});
