export const SUCCESS_PAYMENT_STATUS_AUTH_FINISHED = 'AuthenticationFinished';
export const SUCCESS_PAYMENT_STATUS_AUTH_NOT_REQUIRED = 'AuthenticationNotRequired';
export const SUCCESS_PAYMENT_STATUS_RECEIVED = 'Received';
export const SUCCESS_PAYMENT_STATUS_AUTHORIZED = 'Authorised';
export const SUCCESS_PAYMENT_STATUSES = [
  SUCCESS_PAYMENT_STATUS_AUTH_FINISHED,
  SUCCESS_PAYMENT_STATUS_AUTH_NOT_REQUIRED,
  SUCCESS_PAYMENT_STATUS_RECEIVED,
  SUCCESS_PAYMENT_STATUS_AUTHORIZED,
];
