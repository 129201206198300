import React from 'react';
import { MobileCubeFormWrapper, Text } from './styled-components';
import { Row, Col } from 'react-bootstrap';
import { Accordion, Table } from 'bootstrap';
import './styled.scss';

export const MobileCubeAlpenkraeuterRegeneration = () => {
  return (
    <MobileCubeFormWrapper className='mobile-cube-form-wrapper'>
      <div className='mobile-cube-container'>
        <Row className='row'>
          <Col className='col-12 jumbotron bg-light'>
            <div className='container'>
              <div className='infotext'>
                <h5 className='product-detail-name'>Alpenkräuter - Regeneration</h5>
                <h2>Zellregeneration mit der Heilkraft der Natur</h2>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Was kann dieser Health Cube?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='product-detail-description-text'>
                    <h3>Dauer</h3>
                    <span>30 Tage</span>
                  </div>
                  <div className='product-detail-description-text'>
                    <h3>Produktinformationen</h3>
                    <div>
                      Nutze die Kraft der Alpen für die Regeneration deiner Zellen! Auf deiner 30-Tage-Erholungsreise wirst du ein Bewusstsein für die Heilkraft der Natur rund um die Alpen entwickeln
                      und deinen Horizont für heimische Produkte erweitern. Du erfährst, wie die Regeneration deiner Zellen mit der Qualität deines Schlafs zusammenhängt, erhältst wertvolle Tipps, wie
                      du dein Immunsystem natürlich unterstützen und deine Resilienz nachhaltig stärken kannst. Es erwarten dich außerdem Atem- und Bewegungsübungen, Rezepte und viele Tipps und
                      Informationen rund um einen gesunden Lebensstil, der im Einklang mit der Natur unserer Heimat verwurzelt ist.
                    </div>
                    <br />
                    <div className='bold'>Wer / was steht hinter dem Alpenkräuter-Regenerations-Cube?</div>
                    <div className='margin-top'>
                      Das Forum Via Sanitas, eine Gesundheitsplattform in Österreich, hat in Zusammenarbeit mit zahlreichen Experten das 8 Elements Health Concept entwickelt. Dieses Konzept dient auch
                      als Basis für das Wissen im Alpenkräuter-Regenerations-Cube. Hier kommt insbesondere die jahrelange Erfahrung des Forum Via Sanitas im Umgang mit heimischen und pflanzlichen
                      Nährstoffen zum Tragen. Lass dich inspirieren! Wir entführen dich in eine Welt voller natürlicher und gesundheitsfördernder Möglichkeiten, die dich auf dem Weg zur Erholung und
                      Regeneration bestmöglich unterstützen.
                    </div>
                  </div>
                  <div className='product-detail-ordernumber-container'>
                    <h3>
                      <span className='product-detail-ordernumber-label'>Artikelnummer:</span>
                    </h3>
                    <span className='product-detail-ordernumber'>30059</span>
                  </div>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Welche digitalen gratis Tools sind enthalten?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <ul>
                    <li className='bold'>
                      <span>Smart Reminder Tool</span>
                      <div className='li-text'>
                        Zusammen mit deinem Health Cube aktivierst du automatisch den Smart Reminder. Das Tool, das dich rechtzeitig an die Einnahme deiner Supplements erinnert und eine
                        Einnahmeübersicht erstellt.
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Welche Supplements sind im Paket inkludiert?</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <ul>
                    <li className='bold'>
                      <span>OPC Plus </span>
                      <span className='li-text'>mit Heidelbeere (60 Kapseln) der Marke alps pure</span>
                    </li>
                    <li className='bold'>
                      <span>Melatonin Plus </span>
                      <span className='li-text'>mit Baldrianwurzel-Extrakt (60 Kapseln) der Marke alps pure</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Zutaten und Nährwerte-Tabelle</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div>
                    <div className='bold'>Zutaten (OPC Plus)</div>
                  </div>
                  <div className='margin-top'>
                    <div>Natriumhydrogencarbonat (Säureregulator), Zitronensäure (Säuerungsmittel), Magnesiumcitrat, Kaliumcitrat, Di-Natriumhydrogenphosphar (Säureregulator), Zinkcitrat</div>
                  </div>
                  <br />
                  <div>
                    <div className='bold'>Zutaten (Melatonin Plus)</div>
                  </div>
                  <div className='margin-top'>
                    <div>
                      Calciumcarbonat, Überzugsmittel: Hydroxypropylmethylcellulose (pflanzliche Kapselhülle mit Geliermittel Gellan), Baldrianwurzel-Extrakt 9,3%, Pyridoxinhydrochlorid, Melatonin
                      0,1%, Schwarzer Pfefferfrucht-Extrakt (95% Piperin)
                    </div>
                  </div>
                  <div className='margin-top small-line-height'>
                    Hinweis: Nahrungsergänzungsmittel sind kein Ersatz für eine ausgewogene und abwechslungsreiche Ernährung sowie eine gesunde Lebensweise. Die empfohlene Tagesdosis nicht
                    überschreiten. Fragen Sie Ihren Arzt, Therapeuten oder Apotheker.
                  </div>
                </div>
              </div>
              <div className='description-item'>
                <div className='description-header'>
                  <h4>
                    <span className='description-header-text'>Nährstoffwissen</span>
                  </h4>
                </div>
                <div className='description-body'>
                  <div className='bold'>Physiologische Funktion der Nährstoffe (OPC Plus)</div>
                  <div>
                    <ul>
                      <li className='bold'>
                        <span>Vitamin C </span>
                        <span className='li-text'>trägt dazu bei, die Zellen vor oxidativen Stress zu schützen.</span>
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div className='bold'>Physiologische Funktion der Nährstoffe (Melatonin Plus)</div>
                  <div>
                    <ul>
                      <li className='bold'>
                        <span>Melatonin </span>
                        <span className='li-text'>trägt dazu bei, die Einschlafzeit zu verkürzen.</span>
                      </li>
                      <li className='bold'>
                        <span>Calcium </span>
                        <span className='li-text'>trägt zur normalen Signalübertragung zwischen den Nervenzellen bei.</span>
                      </li>
                      <li className='bold'>
                        <span>Vitamin B6 </span>
                        <span className='li-text'>trägt zur normalen Funktion des Nervensystems und der Psyche bei.</span>
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div>
                    <div className='bold'>Verzehrempfehlung (OPC Plus)</div>
                    <div className='margin-top'>1x täglich 1 Kapsel mit reichlich Flüssigkeit verzehren.</div>
                  </div>
                  <br />
                  <div>
                    <div className='bold'>Verzehrempfehlung (Melatonin Plus)</div>
                    <div className='margin-top'>1 Kapsel vor dem Schlafengehen mit reichlich Flüssigkeit verzehren.</div>
                  </div>
                  <div className='margin-top'>
                    <div className='bold'>Der Smart Reminder erinnert automatisch an die Einnahme der Supplements.</div>
                  </div>

                  <div className='margin-top'>
                    <div className='bold'>Lagerhinweis</div>
                    <div className='margin-top'>Geschlossen, kühl, trocken, lichtgeschützt und außerhalb der Reichweite von kleinen Kindern lagern.</div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </MobileCubeFormWrapper>
  );
};
