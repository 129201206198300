import styled from '@emotion/styled';

const HamburgerWrapper = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 45,
  left: 20,
  zIndex: 50,
  display: 'inline-block',
  div: {
    width: 25,
    height: 2,
    margin: '5px 0',
    backgroundColor: theme === 'dark' ? '#000' : '#fff'
  }
}));

export const Hamburger = props => (
  <HamburgerWrapper {...props}>
    <div />
    <div />
    <div />
  </HamburgerWrapper>
);
