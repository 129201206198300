import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import menu from '../../data/mainMenu.json';
import footer from '../../data/mainFooter.json';
import { LandingPageWrapper } from '../../wrappers';
import { TitleWithLine, UserDataFormCubes } from '../../components';
import { AppContext } from '../../App';
import history from '../../utils/history';

const SectionWrapper = styled.section({
  maxWidth: 1170,
  width: '65%',
  margin: '0 auto',
  paddingTop: 70,
  '> div:first-of-type': {
    '@media (max-width: 991px)': {
      position: 'relative',
      left: 0,
    },
  },
  '@media (max-width: 991px)': {
    padding: '62px 20px 20px',
    marginLeft: 0,
  },
});

const FormWrapper = styled.section({
  width: '80%',
  margin: '0 auto',
  paddingTop: 70,
  maxWidth: '1170px',
  '@media (max-width: 991px)': {
    width: '95%',
    paddingTop: 30,
  },
});

const Text = styled.p(({ theme }) => ({
  fontSize: 18,
  lineHeight: '28px',
  span: {
    backgroundColor: theme.colors.attention,
  },
}));

const Ul = styled.ul(({ theme }) => ({
  fontSize: 18,
  lineHeight: '28px',
  listStyleType: '"- "',
  span: {
    backgroundColor: theme.colors.attention,
  },
}));

export const UserDataSection = () => {
  const { state } = useContext(AppContext);
  const { userDetail, address } = state.user || {};
  if (!state.chosenCubeId) history.push('/');
  useEffect(() => sessionStorage.removeItem('isPreventionProgram'), []);
  return (
    <LandingPageWrapper headerLogo menu={menu.mainMenu} footerLinks={footer.mainFooter}>
      <SectionWrapper>
        <TitleWithLine
          textStyle={{
            '@media (max-width: 991px)': {
              fontSize: 15,
            },
          }}
          lineStyle={{ height: 1 }}
        >
          gleich geschafft
        </TitleWithLine>
        <Ul>
          <li>Sichere Zahlung mit SSL-Verschlüsselung</li>
          <li>
            <span>Sofortiger Zugang nach Zahlung</span>
          </li>
        </Ul>
      </SectionWrapper>
      <FormWrapper>
        <UserDataFormCubes pathTo='/payment' userDetail={userDetail} address={address} />
      </FormWrapper>
    </LandingPageWrapper>
  );
};
