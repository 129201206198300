import React from 'react';

import { BackToTopButton } from '../../components/Buttons/BackToTop';
import { ScrollToTopWrapper } from './styled-components';

export const PreventionScrollToTop = () => (
  <ScrollToTopWrapper>
    <BackToTopButton />
  </ScrollToTopWrapper>
);
