export const PreventionProgramDetails = {
  productName: 'App basierter Onlinekurs: 360° Gesund und Fit - effektives Ganzkörperkräftigungstraining ohne Hilfsmittel',
  itemNumber: 30056,
  price: 8999,
  priceNumberAmount: 89.99,
  cubeId: process.env.REACT_APP_IS_PROD ? '61c09ae37c2e5411400c5875' : '61c3137884ff702825a0a437',
  content: {
    image: './assets/images/cards/Cube350Gesund@2.png',
  },
};
