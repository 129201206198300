import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';

import menu from '../data/mainMenu.json';
import footer from '../data/mainFooter.json';

import History from '../utils/history';

import { PageHeader as Header, PageFooter as Footer } from '../components';
import { PreventionScrollToTop } from '../screens';

const PageWrapper = styled.div({
  height: 'inherit',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  // color: theme.colors.black
});

const PageMain = styled.main({
  display: 'flex',
  flexDirection: 'column',
  flex: '1 0 auto',
  overflow: 'hidden',
});

export const LandingPageWrapper = ({ children, burgerTheme, scrollToTop, ...props }) => {
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setScrollToTopVisible(true);
    } else {
      setScrollToTopVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const pathName = History.location.pathname.substring(1);

  const burgerThemeDefault =
    pathName === 'impressum' || pathName === 'agbs' || pathName === 'nutzungsbedingungen' || pathName === 'datenschutzerklarung' || pathName === 'widerrufsbelehrung' ? 'dark' : 'light';

  return (
    <PageWrapper>
      <Header logo={props.headerLogo} login={props.login} menu={props.menu || menu.mainMenu} absolute={props.absolute} burgerTheme={burgerTheme || burgerThemeDefault} />

      <PageMain>{children}</PageMain>

      <Footer links={props.footerLinks || footer.mainFooter} />
      {scrollToTop && scrollToTopVisible && <PreventionScrollToTop />}
    </PageWrapper>
  );
};
