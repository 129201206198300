import React, { useState, useContext, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
// import { Modal, Box, Typography } from '@material-ui/core';
import styled from '@emotion/styled';
import SwitchSelector from 'react-switch-selector';
import { Form, Row, Col, Button } from 'react-bootstrap';
import cn from 'classnames';
import { AppContext } from '../../App';
import { theme } from '../../styles/theme';
import validateEmail from '../../utils/validateEmail';
import validatePassword from '../../utils/validatePassword';
import { GreenButton } from '..';
import { ReactComponent as EditIcon } from '../../assets/icons/edit-pen.svg';
import history from '../../utils/history';
import api from '../../services/endpoints';
import { toast, ToastContainer } from 'react-toastify';
import './styles.css';
import ReactGA from 'react-ga4'

const toastSettings = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
};

const FormWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.background,
  width: '100%',
  borderRadius: 25,
  padding: '35px 50px',
  fontSize: 18,
  fontWeight: 500,
  '@media (max-width: 991px)': {
    padding: '10px 15px',
  },
}));

const FieldWrapper = styled.div(() => ({
  width: '50%',
  '@media (max-width: 991px)': {
    width: '100%',
  },
}));

const Text = styled.p({
  fontSize: '24px',
  fontWeight: 'bold',
  textAlign: 'left',
  paddingBottom: 30,
  alignItems: 'center',
  display: 'flex',
});

const SmallText = styled.p({
  fontSize: 14,
  textAlign: 'center',
});

const ModalText = styled.p({
  textAlign: 'left',
  marginBottom: '30px',
});

const BottomText = styled.p({
  textAlign: 'center',
  marginBottom: '6%',
  '@media (max-width: 991px)': {
    fontSize: 12,
  },
});

const options = [
  {
    label: 'Frau',
    value: 'female',
  },
  {
    label: 'Herr',
    value: 'male',
  },
  {
    label: 'Keine Angaben',
    value: 'non-binary',
  },
];

export const UserDataFormProgram = ({ pathTo }) => {
  const { dispatch } = useContext(AppContext);
  const [chosenGender, setChosenGender] = useState('female');
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isAgreeWithTerms, setIsAgreeWithTerms] = useState(false);
  const [isConfirmRequirements, setIsConfirmRequirements] = useState(false);
  const [form, setForm] = useState({ email: null, password: null, firstName: null, lastName: null });
  const [errors, setErrors] = useState({});
  // TODO check if isEditMode is not required
  const [isEditMode, setIsEditMode] = useState(true);

  useEffect(() => window.scrollTo(0, 0), []);

  const checkFormValidation = () => {
    for (let key in form) {
      if (!form[key]) return false;
    }
    for (let key in errors) {
      if (errors[key]) return false;
    }
    return isAgreeWithTerms && isConfirmRequirements && true;
  };

  const findAndSetErrors = (formName) => {
    const newErrors = findFormErrors(formName);
    if (Object.keys(newErrors).length === 0) return;
    setErrors(newErrors);
  };

  const setField = (field, value) => {
    clearErrors(field);
    setForm({
      ...form,
      [field]: value,
    });
  };

  const clearErrors = (fieldName) => {
    if (!errors[fieldName]) return;
    setErrors({ ...errors, [fieldName]: null });
  };

  const findFormErrors = (formName) => {
    const { email, password, firstName, passwordConfirm, lastName } = form;
    const newErrors = { ...errors };
    switch (formName) {
      case 'email': {
        if (!email) {
          newErrors.email = 'Bitte gib eine E-Mail-Adresse an!';
        } else if (!validateEmail(email)) {
          newErrors.email = 'Die angegebene E-Mail-Adresse ist nicht korrekt!';
        }
        return newErrors;
      }
      case 'password': {
        if (!password) {
          newErrors.password = 'Bitte gib dein Wunsch-Passwort an!';
        } else if (!validatePassword(password)) {
          newErrors.password = 'Mind. 8 Zeichen inkl. Groß- und Kleinbuchstaben und 1 Ziffer.';
        }
        if (!passwordConfirm) {
          newErrors.passwordConfirm = 'Bitte bestätige dein Wunsch-Passwort!';
        } else if (passwordConfirm !== password) {
          newErrors.passwordConfirm = 'Die beiden Passwörter stimmen nicht überein!';
        }
        return newErrors;
      }
      case 'firstName': {
        if (!firstName) {
          newErrors.firstName = 'Bitte gib deinen Vornamen an!';
        }
        return newErrors;
      }
      case 'lastName': {
        if (!lastName) {
          newErrors.lastName = 'Bitte gib deinen Nachnamen an!';
        }
        return newErrors;
      }
      case 'passwordConfirm': {
        if (!passwordConfirm) {
          newErrors.passwordConfirm = 'Bitte bestätige dein Wunsch-Passwort!';
        } else if (passwordConfirm !== password) {
          newErrors.passwordConfirm = 'Die beiden Passwörter stimmen nicht überein!';
        }
        return newErrors;
      }
      default:
        return newErrors;
    }
  };

  const submitHandler = () => {
    ReactGA.event({
      category: "prevention registration",
      action: "registration form submitted",
    });
    const { password, firstName, lastName, email } = form;
    const payload = {
      password,
      firstName,
      lastName,
      email,
      tenant: 'bchange',
    };

    const formSubmitPromise = api.user
      .registration(payload)
      .then(async ({ data }) => {
        dispatch({
          type: 'UPDATE',
          payload: {
            user: data,
          },
        });
        await api.user.addUserTag([{ name: "PREV_WAITING_FOR_PAYMENT", source: "prevention landing page registration" }], data.keycloakUuid);
        return api.user.login(email, password);
      })
      .then(({ access_token }) => {
        ReactGA.event({
          category: "prevention registration",
          action: "new user created",
        });
        toast.dismiss();
        sessionStorage.setItem('temporaryToken', access_token);
        history.push(pathTo);
      })
      .catch((err) => {
        ReactGA.event({
          category: "prevention registration",
          action: "error creating new user",
        });
        if (err?.response?.status === 409) {
          toast.dismiss();
          return setIsOpenModal(true);
        }
      });
    toast.promise(formSubmitPromise, {
      pending: {
        render() {
          return 'Anfrage wird gesendet...';
        },
        ...toastSettings,
      },
      error: {
        render() {
          return 'Etwas ist schief gelaufen. Bitte versuche es später noch einmal.';
        },
        ...toastSettings,
      },
    });
  };

  const { colors } = theme;
  const { firstName, lastName, email, password, passwordConfirm } = form;
  const initialSelectedIndex = options.findIndex(({ value }) => value === chosenGender);
  return (
    <FormWrapper>
      <div>
        <Text>Deine Daten {!isEditMode && <EditIcon stroke={colors.darkGrey} className='edit-icon' onClick={() => setIsEditMode(true)} />}</Text>
      </div>
      {isEditMode && (
        <p className='switch-wrapper'>
          <SwitchSelector
            initialSelectedIndex={initialSelectedIndex}
            options={options}
            onChange={setChosenGender}
            backgroundColor={colors.white}
            fontColor={colors.darkGrey}
            selectedBackgroundColor={'#F0F3F5'}
            selectedFontColor={colors.darkGrey}
            border={`1px solid ${colors.lightGrey}`}
            selectionIndicatorMargin={1}
          />
        </p>
      )}
      <div>
        <Form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {!isEditMode ? (
            <div style={{ marginBottom: '50px' }}>
              <p>{`${firstName} ${lastName}`}</p>
              <p>{email}</p>
            </div>
          ) : (
            <>
              <div className='d-flex flex-wrap p-2'>
                <FieldWrapper>
                  <Form.Group className='p-2' controlId='eMail'>
                    <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.email })}>E-Mail*</Form.Label>
                    <Form.Control
                      className={cn(!!errors.email ? 'invalid-form' : 'valid-form')}
                      onChange={(e) => setField('email', e.target.value)}
                      value={email}
                      onBlur={() => findAndSetErrors('email')}
                      isInvalid={!!errors.email}
                      type='email'
                      aria-pressed='false'
                      tabIndex='1'
                    />
                    <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </FieldWrapper>
                <FieldWrapper></FieldWrapper>
                <FieldWrapper>
                  <Form.Group className='p-2' controlId='password'>
                    <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.password })}>Passwort festlegen*</Form.Label>
                    <div style={{ position: 'relative' }}>
                      <Form.Control
                        className={cn(!!errors.password ? 'invalid-form-password' : 'valid-form')}
                        type={isVisiblePassword ? 'text' : 'password'}
                        onBlur={() => {
                          findAndSetErrors('password');
                        }}
                        value={password}
                        autoComplete='new-password'
                        onChange={(e) => setField('password', e.target.value)}
                        isInvalid={!!errors.password}
                        tabIndex='2'
                      />
                      <div
                        className={cn('eye-wrapper', {
                          'crossed-out-eye-icon': isVisiblePassword,
                          'eye-icon': !isVisiblePassword,
                        })}
                        onClick={() => setIsVisiblePassword(!isVisiblePassword)}
                      ></div>
                      {!!errors.password ? (
                        <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                          {errors.password}
                        </Form.Control.Feedback>
                      ) : (
                        <Form.Label className='under-input-text padding-left-10' style={{ color: colors.warmGrey }}>
                          Mind. 8 Zeichen inkl. Groß- und Kleinbuchstaben und 1 Ziffer.
                        </Form.Label>
                      )}
                    </div>
                  </Form.Group>
                </FieldWrapper>
                <FieldWrapper>
                  <Form.Group className='p-2' controlId='passwordConfirm'>
                    <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.passwordConfirm })}>Passwort wiederholen*</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.passwordConfirm}
                      value={passwordConfirm}
                      className={cn(!!errors.passwordConfirm ? 'invalid-form' : 'valid-form')}
                      onChange={(e) => setField('passwordConfirm', e.target.value)}
                      onBlur={() => findAndSetErrors('passwordConfirm')}
                      type={isVisiblePassword ? 'text' : 'password'}
                      tabIndex='3'
                    />
                    {!!errors.passwordConfirm ? (
                      <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                        {errors.passwordConfirm}
                      </Form.Control.Feedback>
                    ) : (
                      <Form.Label className='under-input-text' style={{ color: colors.warmGrey }}></Form.Label>
                    )}
                  </Form.Group>
                </FieldWrapper>
                <FieldWrapper>
                  <Form.Group className='p-2' controlId='firstName'>
                    <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.firstName })}>Vorname*</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.firstName}
                      value={firstName}
                      className={cn(!!errors.firstName ? 'invalid-form' : 'valid-form')}
                      onChange={(e) => setField('firstName', e.target.value)}
                      onBlur={() => findAndSetErrors('firstName')}
                      type='text'
                      tabIndex='4'
                    />
                    <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </FieldWrapper>
                <FieldWrapper>
                  <Form.Group className='p-2' controlId='lastName'>
                    <Form.Label className={cn('padding-left-10', { 'invalid--red-color': !!errors.lastName })}>Nachname*</Form.Label>
                    <Form.Control
                      isInvalid={!!errors.lastName}
                      value={lastName}
                      className={cn(!!errors.lastName ? 'invalid-form' : 'valid-form')}
                      onChange={(e) => setField('lastName', e.target.value)}
                      onBlur={() => findAndSetErrors('lastName')}
                      type='text'
                      tabIndex='5'
                    />
                    {!!errors.lastName ? (
                      <Form.Control.Feedback className='invalid--red-color under-input-text padding-left-10' type='invalid'>
                        {errors.lastName}
                      </Form.Control.Feedback>
                    ) : (
                      <Form.Label className='under-input-text' style={{ color: colors.warmGrey }}></Form.Label>
                    )}
                  </Form.Group>
                </FieldWrapper>
              </div>
              <Row mb={3} style={{ marginBottom: 30 }}>
                <Form.Label style={{ color: colors.warmGrey, fontSize: 10 }}>*Pflichtfelder</Form.Label>
              </Row>
              <Row>
                <Form.Group className='mb-3'>
                  <Form.Check
                    label={
                      <Form.Label style={{ paddingLeft: 25 }}>
                        Ich habe die Hinweise zu{' '}
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('/praevention/agbs', '_blank')}>
                          AGBs
                        </span>
                        ,{' '}
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('/praevention/datenschutzerklarung', '_blank')}>
                          Datenschutz, Newsletter und Weitergabe der Daten zu Abrechnungszwecken
                        </span>{' '}
                        gelesen und akzeptiere sie.
                      </Form.Label>
                    }
                    onChange={(e) => setIsAgreeWithTerms(e.target.checked)}
                    checked={isAgreeWithTerms}
                    tabIndex='6'
                  />
                </Form.Group>
              </Row>
              <Row style={{ marginBottom: 40 }}>
                <Form.Group className='mb-3'>
                  <Form.Check
                    label={
                      <Form.Label style={{ paddingLeft: 25 }}>
                        Hiermit bestätige ich, dass ich die{' '}
                        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('/praevention/gesundheitliche', '_blank')}>
                          gesundheitlichen Voraussetzungen
                        </span>{' '}
                        erfülle.
                      </Form.Label>
                    }
                    onChange={(e) => setIsConfirmRequirements(e.target.checked)}
                    checked={isConfirmRequirements}
                    tabIndex='7'
                  />
                </Form.Group>
              </Row>
            </>
          )}

          <Row md='auto' className='justify-content-center' style={{ marginBottom: 34 }}>
            <GreenButton
              {...{ text: 'Weiter' }}
              style={{
                opacity: checkFormValidation() ? 1 : 0.4,
              }}
              className='green-button'
              onClick={submitHandler}
              disabled={!checkFormValidation()}
            />
          </Row>
          <Row style={{ marginBottom: '14%' }}>
            <SmallText>Du wirst nun zur Zahlungsabwicklung weitergeleitet, wo du die Zahlung abschließen kannst.</SmallText>
            <SmallText>Sichere Zahlung mit SSL-Verschlüsselung | Deine Daten sind 100 % sicher</SmallText>
          </Row>
          <Row>
            <div>
              <BottomText>
                Der App basierte Onlinekurs: 360° Gesund und Fit - effektives Ganzkörperkräftigungstraining ohne Hilfsmittel ist nach § 20 Abs. 1 SGB V{' '}
                {
                  //TODO add the link to the file after certifcation
                }
                <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open('/praevention', '_blank')}>
                  zertifiziert
                </span>
                , d.h. die gesetzlichen Krankenkassen übernehmen bis zu 100 % der Kosten. Teilnahme auf eigene Gefahr.
              </BottomText>
            </div>
          </Row>
        </Form>
      </div>
      <ToastContainer />
      <Modal scrollable show={isOpenModal} onHide={() => setIsOpenModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontWeight: 'bold' }}>
            Achtung!
            <br />
            Auf diese E-Mail Adresse ist bereits ein Kundenkonto angelegt.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Bitte wende dich an den Kundendienst. Wir unterstützen dich bei der Anmeldung.
          <br />
          <br />
          Ruf uns an unter:
          <br />
          Deutschland: +49 8651 765693156
          <br />
          Österreich: +43 6246 211 01
          <br />
          <br />
          Oder sende uns eine E-Mail an <a href='mailto:praevention@b-change.com'>praevention@b-change.com</a>
          <GreenButton
            onClick={() => setIsOpenModal(false)}
            {...{ text: 'Zurück', path: '#' }}
            style={{
              margin: '30px auto',
            }}
          />
          <ModalText>Oder schließe dieses Fenster und verwende eine neue E-Mail Adresse für die Registrierung eines neuen Kundenkontos.</ModalText>
        </Modal.Body>
      </Modal>
    </FormWrapper>
  );
};
