import React from 'react';
import styled from '@emotion/styled';
import MediaQuery from 'react-responsive';
import { toast, ToastContainer } from 'react-toastify';
import { toastSettings } from './RegistrationPageWrapper';
import { useMediaQuery } from 'react-responsive';

import { AppContext } from '../App';
import History from '../utils/history';

import { LandingPageWrapper } from '.';
import { CubeHeader, CubePicture, CubeContent } from '../screens';
import { BackToTopButton, GreenButton } from '../components';
import api from '../services/endpoints';

const CTABlock = styled.section({
  maxWidth: 1920,
  width: '100%',
  margin: '30px auto',
  textAlign: 'center',
  position: 'relative',
  paddingBottom: 200,
  color: '#1D1D1D',
  '@media (max-width: 991px)': {
    order: 4,
    paddingBottom: '50px',
  },
});

const BasePrice = styled.div(({ isMobile }) => ({
  width: 'max-content',
  position: 'relative',
  marginLeft: '3px',
  marginBottom: isMobile ? '20px' : 'unset',
  span: {
    textDecoration: 'line-through',
    textDecorationThickness: 2,
    fontSize: 40,
    lineHeight: '72px',
    ':before': {
      content: '"Grundpreis :"',
      position: 'absolute',
      fontSize: '16px',
      left: isMobile ? '18px' : '-100px',
      top: isMobile ? '-35px' : 'unset',
    },
  },
}));

const UnderPriceText = styled.span({
  display: 'block',
  fontSize: 19,
  lineHeight: '27px',
});

const PriceWrapper = styled.div(({ isMobile }) => ({
  width: 'max-content',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  alignItems: isMobile ? 'center' : 'unset',
}));

const ActualPrice = styled.div(({ withAdditionalLine, isMobile }) => ({
  width: 'max-content',
  position: 'relative',
  '> span:first-of-type': {
    fontSize: 51,
    lineHeight: '72px',
    ':before': {
      content: withAdditionalLine ? '"Ihr Kennenlernpreis:"' : null,
      position: 'absolute',
      fontSize: '16px',
      left: isMobile ? '-10px' : '-155px',
      top: isMobile ? '-35px' : 'unset',
      whiteSpace: 'nowrap',
    },
  },
}));

export const CubePageWrapper = ({ banner, content, cubeId, children, isFree, cubeName, isItemNumberIsInRightColumn }) => {
  const { state, dispatch } = React.useContext(AppContext);
  const isMobile = useMediaQuery({
    query: '(max-width: 980px)',
  });
  const redirectHandler = () => {
    dispatch({
      type: 'UPDATE',
      payload: {
        chosenCubeId: cubeId,
        isChosenCubeFree: isFree,
        chosenCubePrice: content.priceAmount,
        chosenCubePriceNumber: content.priceNumberAmount,
        chosenCubeName: cubeName,
        chosenCubeNumber: content.itemNumber,
      },
    });
    const token = localStorage.getItem('token');
    if (!token) return History.push('/login');
    if (isFree)
      return api.cube
        .cubeAssign(cubeId)
        .then(() => {
          History.push('/order-confirmation');
        })
        .catch((err) => {
          toast.error(err?.response?.status === 409 ? 'Dieser Benutzer hat bereits einen solchen CUBE' : 'Etwas ist schief gelaufen. Bitte versuche es später noch einmal.', toastSettings);
        });
    if (token && !state.user) {
      api.user
        .getProfile()
        .then(({ status, data }) => {
          if (status === 200) {
            dispatch({
              type: 'UPDATE',
              payload: {
                user: data,
              },
            });

            localStorage.setItem('userFirstName', data.userDetail?.firstName);
            localStorage.setItem('userLastName', data.userDetail?.lastName);
          }
        })
        .then(() => History.push('/user-data'));
    } else if (state.user) {
      History.push('/user-data');
    }
  };
  return (
    <LandingPageWrapper headerLogo>
      <CubeHeader />

      <CubePicture isMobile={isMobile} redirectHandler={redirectHandler} {...banner} />

      <CubeContent isItemNumberIsInRightColumn={isItemNumberIsInRightColumn} {...content}>
        {children}
      </CubeContent>

      <CTABlock>
        <PriceWrapper isMobile={isMobile}>
          {content.basePrice ? (
            <>
              <BasePrice isMobile={isMobile}>
                <span>{content.basePrice}</span>
              </BasePrice>
              <ActualPrice withAdditionalLine isMobile={isMobile}>
                <span>{content.price}</span>
              </ActualPrice>
              <UnderPriceText>Preis inkl. MwSt (19%)</UnderPriceText>
            </>
          ) : (
            <>
              <ActualPrice>
                <span>{content.price}</span>
              </ActualPrice>
              <UnderPriceText>Preis inkl. MwSt (19%)</UnderPriceText>
            </>
          )}
        </PriceWrapper>

        <GreenButton style={{ margin: '16px auto 0' }} {...{ text: banner.price === 'GRATIS' ? 'Jetzt starten' : 'Jetzt kaufen', redirectHandler }} />
        <MediaQuery minWidth={992}>
          <BackToTopButton />
        </MediaQuery>
      </CTABlock>
      <ToastContainer />
    </LandingPageWrapper>
  );
};
