import styled from '@emotion/styled';

export const ScrollToTopWrapper = styled.section({
  position: 'fixed',
  display: 'flex',
  justifyContent: 'center',
  right: '-20px',
  bottom: '47px',
  width: '42px',
});
