import React from 'react';

import { Image, GreenButton } from '..';
import { MessageWrapper, SubHeader, Text, IconWrapper } from './styled-components';

export const SuccessRegistrationMessage = ({ email }) => (
  <MessageWrapper>
    {/* <SubHeader>Vielen Dank für deine Registrierung! Prüfe jetzt dein E-Mail Postfach.</SubHeader> */}

    {/* <Text>Wir haben dir gerade eine Aktivierungs-E-Mail an folgende Adresse geschickt: {email}</Text> */}
    <Text>Deine Aktivierungs-E-Mail haben wir an folgende Adresse geschickt: {email}</Text>

    <Text style={{ marginBottom: '45px' }}>
      Aktiviere deinen Account jetzt und vergiss nicht, deinen Spam-Ordner zu überprüfen.
    </Text>

    <hr style={{ marginBottom: '25px' }} />

    <IconWrapper>
      <Image {...{ path: './assets/images/icons/MailSmiley.svg', alt: 'Mail icon' }} />
    </IconWrapper>

    <GreenButton {...{ path: '/', text: 'Zurück zur Homepage' }} />
  </MessageWrapper>
);
