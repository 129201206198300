import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  paddingTop: '4%',
  paddingBottom: '4%',
  '@media (max-width: 991px)': {
    paddingTop: '7%',
    paddingBottom: '7%',
    paddingRight: 20,
    paddingLeft: 20,
  },
});

export const ContentWrapper = styled.div({
  maxWidth: 1170,
  margin: '85px auto 80px',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 991px)': {
    margin: '60px auto 40px',
    flexDirection: 'column',
  },
});

export const ExpertPhoto = styled.div({
  marginRight: 70,
  img: {
    width: 286,
    borderRadius: '50%',
    marginBottom: 30,
    '@media (max-width: 991px)': {
      marginBottom: 0,
    },
  },
  p: {
    textAlign: 'center',
    fontSize: '18px',
    lineHeight: '26px',
    fontWeight: 'bold',
    letterSpacing: '0.63px',
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  '@media (max-width: 991px)': {
    marginRight: 0,
    marginBottom: 30,
  },
});

export const ExpertContent = styled.div({
  p: {
    '&:first-of-type': {
      fontWeight: 'bold',
      fontSize: '20px',
      lineHeight: '30px',
      letterSpacing: '0.7px',
    },
    '&:not(:last-of-type)': {
      marginBottom: 30,
    },
  },
});
