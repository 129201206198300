import React from 'react';
import { NavLink } from 'react-router-dom';

import { FooterWrapper, FooterLinksWrapper } from './styled-components';
import { Nav } from 'react-bootstrap';

export const PageFooter = ({ links, ...props }) => {
  const currentYear = new Date().getFullYear();

  return (
    <FooterWrapper {...props}>
      <FooterLinksWrapper {...props}>
        {links.map((link) => (
          <FooterLinkExternal key={link.title.toLowerCase()} {...link} />
        ))}
      </FooterLinksWrapper>
      <span style={{ marginBottom: 10 }}>Copyright © {currentYear} Social Media Interactive GmbH, all rights reserved</span>
    </FooterWrapper>
  );
};

export const FooterLink = (props) => {
  if (props.email) return <a href={props.email}>{props.title}</a>;
  if (props.document)
    return (
      <a href={`${window.location.origin}/${props.document}`} target='_blank'>
        {props.title}
      </a>
    );

  return <NavLink to={props.path}>{props.title}</NavLink>;
};

export const FooterLinkExternal = (props) => {
  return (
    <Nav.Link style={{ padding: '0px' }} href={props.path}>
      {props.title}
    </Nav.Link>
  );
};
