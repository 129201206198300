import React from 'react';
import styled from '@emotion/styled';

import { Image } from '../';

const LogoWrapper = styled.div({
  margin: '25px auto 40px',
  width: 50,
  height: 50
});

export const BLogo = props => (
  <LogoWrapper {...props}>
    <Image path="./assets/images/logos/b_white.svg" alt="B Logo" />
  </LogoWrapper>
);
