import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  padding: '85px 0 135px',
  background: 'transparent linear-gradient(213deg, #512C55 0%, #40314F 100%) 0% 0% no-repeat padding-box',
  h3: {
    '@media (max-width: 991px) and (orientation: portrait)': {
      textAlign: 'left',
    },
  },
  '@media (max-width: 991px)': {
    padding: '60px 20px',
  },
});

export const Subheader = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '36px',
  textAlign: 'center',
  color: '#fff',
  maxWidth: 825,
  margin: '25px auto',
  '@media (max-width: 991px) and (orientation: portrait)': {
    fontSize: 20,
    lineHeight: '26px',
    marginTop: 35,
    marginBottom: 30,
    textAlign: 'left',
  },
  '@media (max-width: 991px) and (orientation: landscape)': {
    maxWidth: 700,
    margin: '35px auto 30px',
  },
});

export const Text = styled.p({
  maxWidth: 700,
  margin: '0 auto 60px',
  color: '#fff',
  '@media (max-width: 991px)': {
    fontSize: 18,
    lineHeight: '25px',
  },
});

export const ContentWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
  '> div': {
    '&:last-child': {
      marginLeft: 60,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '@media (max-width: 991px)': {
        marginLeft: 0,
      },
    },
  },
  '@media (max-width: 991px)': {
    flexDirection: 'column',
  },
});

export const MocksWrapper = styled.div({
  display: 'flex',
  img: {
    maxHeight: 405,
    width: 'auto',
    '@media (max-width: 991px)': {
      maxHeight: 175,
    },
  },
  '@media (max-width: 991px)': {
    justifyContent: 'center',
    margin: '30px 0 40px',
  },
});

export const TagsWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  maxWidth: 500,
  div: {
    '&:not(:last-child)': {
      marginRight: 15,
      marginBottom: 15,
    },
  },
  '@media (max-width: 991px) and (orientation: portrait)': {
    justifyContent: 'flex-start',
    marginBottom: 50,
  },
  '@media (max-width: 991px) and (orientation: landscape)': {
    justifyContent: 'center',
    margin: '0 auto',
    marginBottom: 50,
  },
});

export const Tag = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.attention,
  color: theme.colors.black,
  height: 30,
  lineHeight: '30px',
  fontSize: 16,
  borderRadius: 30,
  textAlign: 'center',
  padding: '0 16px',
  '@media (max-width: 991px)': {
    padding: '0 12px',
    height: 25,
    lineHeight: '25px',
    fontSize: 12,
  },
}));

export const AppHeader = styled.h4({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '36px',
  color: '#fff',
  '@media (max-width: 991px)': {
    fontSize: 26,
    lineHeight: '32px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
});

export const QRCodeWrapper = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '20px 0',
  position: 'relative',
  img: {
    display: 'block',
    '&:first-of-type': {
      maxWidth: 126,
    },
    '&:nth-of-type(2)': {
      position: 'absolute',
      right: -115,
      maxWidth: 62,
      '@media (max-width: 991px)': {
        right: -85,
      },
    },
  },
});

export const AppDownloadLinks = styled.div({
  display: 'flex',
  marginBottom: 35,
  a: {
    margin: '0 10px',
  },
  img: {
    maxHeight: 53,
    width: 'auto',
    verticalAlign: 'top',
    '@media (max-width: 991px)': {
      maxHeight: 30,
    },
  },
  '@media (max-width: 991px)': {
    marginTop: 20,
    justifyContent: 'center',
    marginBottom: 0,
  },
});
