import React from 'react';

import { useToggle } from '../../utils/useToggle';

import { SectionTitle } from '../../components';
import { SectionWrapper, FAQ, QuestionWrapper, Question, PlusMinus, Answer } from './styled-components';

export const PreventionFAQ = ({ questions }) => (
  <SectionWrapper>
    <SectionTitle align='center'>Häufig gestellte Fragen</SectionTitle>
    <FAQ>
      {questions.map((question, index) => (
        <QuestionComponent key={index} {...question} />
      ))}
    </FAQ>
  </SectionWrapper>
);

const QuestionComponent = ({ question, answer }) => {
  const [expanded, toggle] = useToggle(false);

  return (
    <QuestionWrapper>
      <Question onClick={toggle}>
        <span>{question}</span> {expanded ? <PlusMinus>-</PlusMinus> : <PlusMinus>+</PlusMinus>}
      </Question>
      {expanded && <Answer>{answer}</Answer>}
    </QuestionWrapper>
  );
};
