import React from 'react';
import { Container } from 'react-bootstrap';

export const Section = (props) => {
  return (
    <Container
      fluid
      className='g-0'
      style={{
        ...props.style,
        paddingBottom: '80px',
      }}
    >
      {props.children}
    </Container>
  );
};
