import styled from '@emotion/styled';

export const CubePictureWrapper = styled.section({
  position: 'relative',
  '&::before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'transparent linear-gradient(234deg,rgba(39,51,66,0),rgba(26,34,44,.43137254901960786) 61%,#151c24) 0 0 no-repeat padding-box',
  },
});

export const ImageSet = styled.picture({
  img: {
    maxHeight: 645,
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'top',
    '@media (max-width: 991px)': {
      maxHeight: 'none',
      height: '100vh',
      width: '100%',
      objectFit: 'cover',
    },
  },
});

export const QuoteWrapper = styled.div({
  position: 'absolute',
  bottom: 100,
  left: '35%',
  transform: 'translate(-35%, 0)',
  '@media (max-width: 991px)': {
    width: '100%',
    left: '50%',
    top: '50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
  },
  '> div': {
    '@media (max-width: 991px) and (orientation: portrait)': {
      margin: '0 auto',
      padding: 0,
    },
  },
  '@media (max-width: 1250px) and (min-width: 980px)': {
    left: '25%',
    bottom: 70,
  },
});

export const ButtonWrapper = styled.div({
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'absolute',
  bottom: 50,
  right: '15%',
  '> div': {
    '&:nth-of-type(2)': {
      '@media (max-width: 991px)': {
        order: 2,
        marginTop: 25,
      },
    },
  },
  '@media (min-width: 1921px)': {
    right: '25%',
  },
  '@media (max-width: 991px)': {
    right: 'unset',
    width: '100%',
    margin: '0 auto',
  },
  '@media (max-width: 1250px) and (min-width: 980px)': {
    right: '10%',
  },
});

export const Caption = styled.div(({ theme }) => ({
  maxWidth: 235,
  width: '100%',
  padding: '20px 25px',
  color: theme.colors.black,
  fontSize: 35,
  lineHeight: '48px',
  textAlign: 'center',
  background: 'transparent linear-gradient(122deg, #FFFFFF84 0%, #FFFFFF 100%) 0% 0% no-repeat padding-box;',
  border: '1px solid #DEE4EB',
  borderRadius: '0 0 16px 16px',
  span: {
    display: 'block',
    fontSize: 12,
    lineHeight: '18px',
  },
  '@media (max-width: 991px)': {
    background: 'transparent',
    border: 'none',
    padding: 0,
    marginTop: 10,
    color: theme.colors.white,
    fontSize: 36,
    lineHeight: '51px',
    order: 1,
  },
}));

export const PulsatingArrows = styled.div({
  position: 'absolute',
  left: -60,
  top: -5,
  width: 54,
  height: 55,
  animation: 'pulse 2s infinite',
  '@media (max-width: 991px)': {
    display: 'none',
  },
});

export const MaxLayoutWrapper = styled.div({
  width: '100%',
  height: '100%',
  maxWidth: '1920px',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  margin: '0 auto',
});
