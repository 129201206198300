import styled from '@emotion/styled';

export const PaymentFormWrapper = styled.section(({ theme }) => ({
  textAlign: 'left',
  fontSize: '43px',
  width: '70%',
  maxWidth: 1270,
  margin: '40px auto 0px auto',
  padding: 30,
  backgroundColor: theme.colors.background,
  borderRadius: '25px',
  '@media (max-width: 700px)': {
    width: '95%',
    padding: 10,
  },
  '@media (max-width: 991px)': {
    width: '90%',
  },
}));

export const ConfirmEmailWrapper = styled.section({
  maxWidth: 550,
  width: '100%',
  padding: 20,
});

export const BoldText = styled.p({
  fontSize: 20,
  fontWeight: 500,
  letterSpacing: 0.9,
  lineHeight: '28px',
  marginBottom: '20px',
  textAlign: 'center',
  color: '#1D1D1D',
  '@media (max-width: 700px)': {
    fontSize: 18,
  },
});

export const Text = styled.p({
  fontSize: 30,
  marginBottom: 20,
  letterSpacing: 0.54,
  lineHeight: '28px',
  marginBottom: '45px',
  color: '#44556C',
  '@media (max-width: 700px)': {
    fontSize: 18,
  },
});

export const FailedText = styled.p({
  fontSize: 30,
  marginBottom: 20,
  letterSpacing: 0.54,
  lineHeight: '28px',
  marginBottom: '0px',
  color: '#44556C',
  '@media (max-width: 700px)': {
    fontSize: 18,
  },
});

export const HeaderText = styled.p({
  fontFamily: 'Interstate Bold',
  fontSize: 40,
  marginBottom: 20,
  letterSpacing: 0.75,
  lineHeight: '28px',
  marginBottom: '0px',
  color: '#44556C',
  '@media (max-width: 700px)': {
    fontSize: 26,
  },
});
