import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { Slide } from '../../components';
import { SectionWrapper, SliderIndicator } from './styled-components';

import slider from '../../data/mainSlider.json';

import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import './SliderCarousel.scss';

const sliderSettings = {
  autoPlay: true,
  stopOnHover: false,
  infiniteLoop: true,
  interval: 7000,
  showThumbs: false,
  showArrows: false,
  showStatus: false,
  preventMovementUntilSwipeScrollTolerance: true,
};

const sliderIndicator = (onClickHandler, isSelected, index) =>
  isSelected ? <SliderIndicator isSelected /> : <SliderIndicator key={index} role="button" onClick={onClickHandler} />;

export const SliderCarousel = () => {
  const slides = slider.slides;

  return (
    <SectionWrapper id="heroSlider">
      <Carousel {...sliderSettings} renderIndicator={sliderIndicator}>
        {slides.map((slide, index) => (
          <Slide
            {...{
              key: `slide${index + 1}`,
              mobile: slide.mobile,
              desktop: slide.desktop,
              legend: slide.legend,
            }}
          />
        ))}
      </Carousel>
    </SectionWrapper>
  );
};
