const keycloakBaseUrl = process.env.REACT_APP_KEYCLOAK_URL;
//TODO use realm from environment
const keycloakRealm = process.env.REACT_APP_REALM;
export const getToken = async (email, password) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/x-www-form-urlencoded');

  const urlencoded = new URLSearchParams();
  urlencoded.append('username', email);
  urlencoded.append('password', password);
  urlencoded.append('client_id', 'iqest-client');
  urlencoded.append('grant_type', 'password');

  const requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: urlencoded,
    redirect: 'follow',
  };

  try {
    const response = await fetch(`${keycloakBaseUrl}/realms/bchange/protocol/openid-connect/token`, requestOptions);
    const result = await response.json();
    return result;
  } catch (error) {
    return console.log('error', error);
  }
};
