import styled from '@emotion/styled';

export const Navigation = styled.nav(({ theme, ...props }) => ({
  maxWidth: 690,
  width: 'inherit',
  zIndex: 1000,
  ul: {
    li: {
      span: {
        cursor: 'pointer',
        color: props.light ? '#fff' : theme.colors.darkGrey,
        fontWeight: props.light ? 400 : 800,
        '&:hover': { color: props.light ? theme.colors.attention : theme.colors.darkGrey },
        '@media (max-width: 991px)': {
          display: 'none',
        },
      },
      a: {
        color: props.light ? '#fff' : '#91A1B7',
        textDecoration: 'none',
        '&:hover': {
          color: props.light ? theme.colors.attention : theme.colors.darkGrey,
        },
        '&.active': {
          color: theme.colors.attention,
          fontWeight: 'bold',
        },
        '@media (max-width: 991px)': {
          padding: 0,
          color: '#fff',
        },
      },
    },
  },
  '@media (max-width: 991px)': {
    overflow: 'scroll',
    maxWidth: '100%',
    display: props.mobileMenu ? 'block' : 'none',
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    background: 'transparent linear-gradient(234deg, rgba(68, 85, 108, 0.9) 0, rgba(21, 28, 36, 0.9) 100%) 0% 0% no-repeat padding-box',
  },
  '@media (max-width: 1250px)': {
    maxWidth: '550px',
  },
}));

export const MenuWrapper = styled.ul({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #CAD2DD',
  '@media (max-width: 991px)': {
    flexDirection: 'column',
    padding: '0 20px',
    borderBottom: 'none',
  },
});

export const NoLogo = styled.div({
  margin: '25px auto 40px',
  width: 50,
  height: 50,
});

export const SubmenuWrapper = styled.ul(({ theme }) => ({
  borderLeft: `.7px solid ${theme.colors.attention}`,
  display: 'none',
  position: 'absolute',
  background: 'transparent linear-gradient(240deg,#44556c,#151c24) 0 0 no-repeat padding-box',
  li: {
    whiteSpace: 'nowrap',
    '&:not(:last-child)': {
      borderBottom: '.7px solid #cad2dd',
    },
    '> &:nth-of-type(2)': {
      padding: 0,
    },
    a: {
      display: 'block',
      color: '#fff',
      padding: '25px 15px',
      '&:hover': { color: `${theme.colors.attention} !important` },
      '@media (max-width: 991px)': {
        padding: 0,
      },
    },
    '@media (max-width: 991px)': {
      borderBottom: 'none',
    },
  },
  '> li': {
    '&:not(:first-of-type)': {
      '@media (max-width: 991px)': {
        paddingLeft: 30,
      },
    },
  },
  '@media (max-width: 991px)': {
    display: 'block',
    position: 'relative',
    background: 'transparent',
    border: 'none',
  },
}));

export const MenuItemWrapper = styled.li({
  position: 'relative',
  fontSize: '16px',
  letterSpacing: '0.8px',
  lineHeight: '15px',
  fontWeight: 'bold',
  '&:hover': {
    ul: {
      display: 'block',
    },
  },
  '@media (max-width: 991px)': {
    fontSize: '18px',
    padding: '10px 0',
    lineHeight: '30px',
    borderBottom: '0.5px #CAD2DD solid',
  },
});

export const CloseButton = styled.div({
  position: 'absolute',
  top: 45,
  left: 20,
  display: 'block',
  width: 20,
  height: 20,
  lineHeight: '20px',
  textAlign: 'center',
  color: '#fff',
  '&::before, &::after': {
    content: "''",
    position: 'absolute',
    left: 15,
    height: 33,
    width: 2,
    backgroundColor: '#fff',
  },
  '&::before': {
    transform: 'rotate(45deg)',
  },
  '&::after': {
    transform: 'rotate(-45deg)',
  },
});
