export const theme = {
  colors: {
    blackberry: '#5a315e',
    plum: '#781867',
    darkPlum: '#721762',
    lightGrey: '#CAD2DD',
    warmGrey: '#91A1B7',
    darkGrey: '#44556c',
    white: '#fff',
    black: '#1d1d1d',
    red: '#f54582',
    blue: '#4090F2',
    green: '#06c6a8',
    cubeBox: '#e9edf2',
    btnShadow: '#D4FD344D',
    background: '#f7f7f8',
    attention: '#D4FD34',
    noLimit: '#eb7f00',
  },
};
