import styled from '@emotion/styled';

export const PaymentFormWrapper = styled.section(({ theme }) => ({
  width: '70%',
  maxWidth: 1270,
  margin: '40px auto 0px auto',
  padding: 30,
  backgroundColor: theme.colors.background,
  borderRadius: '25px',
  minHeight: '50%',
  '@media (max-width: 700px)': {
    width: '95%',
    padding: 10,
  },
  '@media (max-width: 991px)': {
    width: '90%',
  },
}));

export const Text = styled.p({
  fontSize: 30,
  paddingLeft: '5px',
  marginBottom: 20,
  letterSpacing: 0.54,
  lineHeight: '28px',
  color: '#44556C',
  '@media (max-width: 700px)': {
    fontSize: 18,
  },
});
