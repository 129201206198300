import styled from '@emotion/styled';

export const CubeContentWrapper = styled.section({
  paddingTop: 70,
  maxWidth: 930,
  width: '100%',
  margin: '0 auto',
  '@media (max-width: 991px)': {
    order: 3,
    paddingTop: 0,
  },
});

export const CubeContentHeader = styled.div({
  display: 'flex',
  alignItems: 'center',
  img: {
    maxWidth: 215,
    width: '100%',
    height: 'auto',
    '@media (max-width: 991px)': {
      order: 2,
    },
  },
  '@media (max-width: 991px)': {
    flexDirection: 'column',
    textAlign: 'center',
  },
});

export const CubeTitleWrapper = styled.div({
  marginLeft: 20,
  '@media (max-width: 991px)': {
    marginLeft: 0,
    marginBottom: 40,
  },
});

export const CubeSubtitle = styled.p({
  fontSize: 22,
  lineHeight: '32px',
  marginTop: 15,
  '@media (max-width: 991px)': {
    fontSize: 18,
    lineHeight: '26px',
    fontWeight: 500,
  },
});

export const CubeDetails = styled.div({
  marginTop: 50,
  display: 'flex',
  flexWrap: 'wrap',
  '> div': {
    maxWidth: '50%',
    width: '100%',
    marginBottom: 60,
    '&:nth-of-type(2n+1)': {
      paddingRight: 25,
    },
    '&:nth-of-type(1)': {
      '> div:nth-of-type(1)': {
        marginBottom: 35,
        '@media (max-width: 991px)': {
          marginBottom: 40,
          borderTop: 'none',
          paddingTop: 0,
        },
      },
    },
    '@media (max-width: 991px)': {
      maxWidth: '100%',
      padding: '0 20px',
      marginBottom: 50,
    },
  },
});

export const TextBlock = styled.div({
  fontSize: 22,
  lineHeight: '32px',
  h4: {
    fontFamily: 'Inter',
    fontWeight: 800,
    fontSize: 26,
    marginBottom: 5,
    '@media (max-width: 991px)': {
      fontSize: 22,
      lineHeight: '30px',
      marginBottom: 10,
    },
  },
  '@media (max-width: 991px)': {
    fontSize: 18,
    lineHeight: '36px',
    paddingTop: 40,
    borderTop: '.5px solid #cbd0d6',
  },
});
