import axios from 'axios';
import { getToken } from '../utils/getToken';
import checkTokenExpiration from '../utils/checkTokenExpiration';

const baseURL = process.env.REACT_APP_BASE_URL;

const userAPI = 'iqs-user-service/api';
const cubeAPI = 'iqs-cube-service/api';
const paymentAPI = 'iqs-payment-service/api';

axios.interceptors.request.use(
  async (config) => {
    const token = checkTokenExpiration();
    if (token === 'expired') {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    if (token && token !== 'expired') {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    return Promise.resolve(config);
  },
  (error) => {
    Promise.reject(error);
  }
);

const user = {
  registration: (data) => axios.post(`${baseURL}/${userAPI}/users/registration/v1.0/`, data),
  confirmEmail: (code, tenant) => axios.put(`${baseURL}/${userAPI}/users/confirm-email-address/v1.0/?confirmationCode=${code}&tenant=${tenant}`),
  resetPassword: (code, tenant, data) => axios.put(`${baseURL}/${userAPI}/users/reset-forgotten-password/v1.0/?confirmationCode=${code}&tenant=${tenant}`, data),
  getProfile: (token) =>
    axios.get(`${baseURL}/${userAPI}/my-account/v1.0/`, {
      headers: { Authorization: `Bearer ${token}` },
    }),
  login: (email, password) => getToken(email, password),
  changeName: (data) => axios.patch(`${baseURL}/${userAPI}/my-account/v1.0/`, data),
  changeEmail: (data) => axios.put(`${baseURL}/${userAPI}/my-account/change-email/v1.0/`, data),
  changePassword: (data) => axios.put(`${baseURL}/${userAPI}/my-account/update-password/v1.0/`, data),
  changeAddress: (data) => axios.put(`${baseURL}/${userAPI}/my-account/setAddress/v1.0/`, data),
  resendEmailActivationLetter: () => axios.post(`${baseURL}/${userAPI}/my-account/sendOptInMail/v1.0/`, null),
  deleteUser: () => axios.delete(`${baseURL}/${userAPI}/my-account/v1.0/`, null),
  addUserTag: (data, userUuid) => axios.put(`${baseURL}/${userAPI}/users/uuid/${userUuid}/tags/add/v1.0/`, data),
  removeUserTag: (data, userUuid) => axios.put(`${baseURL}/${userAPI}/users/uuid/${userUuid}/tags/remove/v1.0/`, data),
  checkTagAndDeleteUser: () => axios.delete(`${baseURL}/${userAPI}/users/delete/v1.0/`, null),
};

const cube = {
  cubeAssign: (cubeId, token) =>
    axios.post(
      `${baseURL}/${cubeAPI}/my-usercubes/v1.0/`,
      {
        cubeId,
        startDate: new Date(),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    ),
  getUserCubes: () => axios.get(`${baseURL}/${cubeAPI}/my-usercubes/v1.0/`),
  addPreventionCubeToUser: (data) => axios.post(`${baseURL}/${cubeAPI}/my-usercubes/v1.0/`, data),
};

const payment = {
  createPaymentSession: (data) => axios.post(`${baseURL}/${paymentAPI}/v1/payment/session`, data),
  createMobilePaymentSession: (data, token) => axios.post(`${baseURL}/${paymentAPI}/v1/payment/session/simple`, data, { headers: { Authorization: `Bearer ${token}` } }),
  sendConfirmationEmail: () => axios.post(`${baseURL}/${paymentAPI}/v1/confirmation/sendOrderConfirmationMail`, null),
  redeemCode: (code) => axios.post(`${baseURL}/${paymentAPI}/code/redeem`, null, { params: { code } })
};

const allEndpoints = {
  user,
  cube,
  payment,
};

export default allEndpoints;
