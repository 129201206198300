import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  paddingTop: '4%',
  paddingBottom: '4%',
  backgroundColor: '#F7F7F8',
  '@media (max-width: 991px)': {
    paddingTop: '7%',
    paddingBottom: '7%',
    paddingRight: 20,
    paddingLeft: 20,
  },
});

export const FAQ = styled.div({
  marginTop: 50,
  '@media (max-width: 991px)': {
    marginTop: 40,
  },
});

export const QuestionWrapper = styled.div({
  maxWidth: 1200,
  margin: '0 auto',
  padding: '50px 0',
  borderBottom: '1px solid #CAD2DD',
  '@media (max-width: 991px)': {
    padding: '40px 0',
  },
});

export const Question = styled.div({
  cursor: 'pointer',
  fontWeight: 'bold',
  fontSize: '18px',
  lineHeight: '26px',
  letterSpacing: '0.63px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: '200px',
  '@media (max-width: 991px)': {
    paddingRight: 0,
    fontSize: 22,
    lineHeight: '30px',
    letterSpacing: 1.1,
  },
});

export const PlusMinus = styled.div({
  color: '#880C6C',
  fontSize: '60px',
});

export const Answer = styled.div({
  marginTop: 25,
  fontSize: 22,
  lineHeight: '32px',
  letterSpacing: 0.33,
  '@media (max-width: 991px)': {
    marginTop: 30,
  },
});
