import React from 'react';
import styled from '@emotion/styled';

import { Image } from '..';

const LogoWrapper = styled.div(props => ({
  ...props.settings
}));

export const WhiteLogo = props => (
  <LogoWrapper {...props}>
    <Image path="./assets/images/logos/header_logo.svg" alt="White Logo" />
  </LogoWrapper>
);
