import React from 'react';
import styled from '@emotion/styled';

import { Image } from '..';

const LogoWrapper = styled.div(props => ({
  ...props.settings
}));

export const DarkLogo = props => (
  <LogoWrapper {...props}>
    <Image path={`${window.location.origin}/assets/images/logos/bchange_dark.svg`} alt="Dark Logo" />
  </LogoWrapper>
);
