import React from 'react';
import Markdown from 'react-markdown';
import MediaQuery from 'react-responsive';

import menu from '../../data/preventionMenu.json';
import footer from '../../data/preventionFooter.json';
import History from '../../utils/history';
import { LandingPageWrapper } from '../../wrappers';
import { DarkLogo, Section, SectionTitle } from '../../components';
import { SectionWrapper, PageTitle } from './styled-components';

import AGBs from './text/AGBs.md';
import Datenschutzerklärung from './text/Datenschutzerklarung.md';
import Nutzungsbedingungen from './text/Nutzungsbedingungen.md';
import Impressum from './text/Impressum.md';
import Widerrufsrecht from './text/Widerrufsrecht.md';
import PreventionImpressum from './text/PreventionImpressum.md';
import PreventionNutzungsbedingungen from './text/PreventionNutzungsbedingungen.md';
import PreventionDatenschutzerklärung from './text/PreventionDatenschutzerklarung.md';
import PreventionAGBs from './text/PreventionAGBs.md';
import Gesundheitliche from './text/Gesundheitliche.md';
import rehypeRaw from 'rehype-raw';

import './md-typography.scss';

let title;

export const LegalPage = () => {
  const pathName = History.location.pathname.substring(1);
  const [markdown, setMarkdown] = React.useState('');
  const goToHome = () => History.push('/');

  switch (pathName) {
    case 'praevention/agbs':
      title = 'ALLGEMEINE GESCHÄFTSBEDINGUNGEN MIT KUNDENINFORMATIONEN';
      fetch(PreventionAGBs)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'praevention/impressum':
      title = 'Impressum';
      fetch(PreventionImpressum)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'agbs':
      title = 'AGBs';
      fetch(AGBs)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'datenschutzerklarung':
      title = 'Datenschutzerklärung';
      fetch(Datenschutzerklärung)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'praevention/datenschutzerklarung':
      title = 'Datenschutzerklärung';
      fetch(PreventionDatenschutzerklärung)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'nutzungsbedingungen':
      title = 'Nutzungsbedingungen';
      fetch(Nutzungsbedingungen)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'praevention/nutzungsbedingungen':
      title = 'Nutzungsbedingungen';
      fetch(PreventionNutzungsbedingungen)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'praevention/gesundheitliche':
      title = 'Gesundheitliche Voraussetzungen';
      fetch(Gesundheitliche)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    case 'praevention/widerrufsrecht':
      title = 'WIDERRUFSRECHT';
      fetch(Widerrufsrecht)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
    default:
      title = 'Impressum';
      fetch(Impressum)
        .then((res) => res.text())
        .then((text) => setMarkdown(text));
      break;
  }

  return (
    <LandingPageWrapper
      headerLogo={!pathName.includes('praevention')}
      login={!pathName.includes('praevention')}
      menu={pathName.includes('praevention') ? menu.praevention : null}
      footerLinks={pathName.includes('praevention') ? footer.praevention : null}
      burgerTheme='dark'
      scrollToTop
    >
      <Section>
        <SectionWrapper>
          {!pathName.includes('praevention') && (
            <MediaQuery maxWidth={991}>
              <DarkLogo onClick={goToHome} />
            </MediaQuery>
          )}
          {/* TODO add top margin for mobile */}
          <SectionTitle>{title}</SectionTitle>

          <Markdown rehypePlugins={[rehypeRaw]} className='md-text'>
            {markdown}
          </Markdown>
        </SectionWrapper>
      </Section>
    </LandingPageWrapper>
  );
};
