import React, { useContext, useEffect } from 'react';
import styled from '@emotion/styled';
import menu from '../../data/preventionMenu.json';
import footer from '../../data/preventionFooter.json';
import { LandingPageWrapper } from '../../wrappers';
import { SubSectionTitle, UserDataFormProgram, SectionTitle, BulletList, Section } from '../../components';
import { AppContext } from '../../App';
import history from '../../utils/history';

const ContentWrapper = styled.section({
  width: '80%',
  margin: 'auto',
  paddingTop: 70,
  maxWidth: '1170px',
  '@media (max-width: 991px)': {
    paddingTop: '100px',
  },
});
const FormWrapper = styled.section({
  padding: '50px 0 50px 0',
});

export const StartProgram = () => {
  const { state } = useContext(AppContext);
  if (!state.chosenCubeId) history.push('/praevention');
  useEffect(() => sessionStorage.setItem('isPreventionProgram', true), []);
  return (
    <LandingPageWrapper login={false} menu={menu.praevention} footerLinks={footer.praevention} burgerTheme='dark' scrollToTop>
      <ContentWrapper>
        <SubSectionTitle>gleich geschafft</SubSectionTitle>
        <SectionTitle>Deine Vorteile</SectionTitle>
        <BulletList>
          <li>Nach § 20 Abs. 1 SGB V von allen gesetzlichen Krankenkassen zertifiziert</li>
          <li>
            <span>Bis zu 100 % Kostenerstattung</span> durch deine Krankenkasse
          </li>
          <li>Von Experten entwickelt</li>
          <li>
            <span>Sofortiger Zugang nach Zahlung</span>
          </li>
        </BulletList>
        <Section>
          <FormWrapper>
            <UserDataFormProgram pathTo='/payment' />
          </FormWrapper>
        </Section>
      </ContentWrapper>
    </LandingPageWrapper>
  );
};
