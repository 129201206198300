import React from 'react';
import styled from '@emotion/styled';

import { useToggle } from '../../utils/useToggle';

import { InputGroup } from '../InputGroup/InputGroup';
import { FormWrapper, SubmitWrapper, SubmitButton } from '../ResetPasswordForm/styled-components';
import { RegistrationLinkWrapper, RegistrationLinkButton } from './styled-components';

import TermsText from '../TermsText/TermsText';

const ResetPasswordLink = styled.p({
  textAlign: 'end',
  fontSize: 14,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
  },
});

const RegisterText = styled.p({
  marginBottom: '15px',
  fontSize: '23px',
  fontWeight: 600,
});

export const LoginForm = ({ handleChange, onSubmit, loginData, redirectToRegister }) => {
  const [isVisible, passwordToggle] = useToggle(false);

  return (
    <FormWrapper>
      <InputGroup
        {...{
          type: 'text',
          name: 'email',
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          label: 'Email',
          isValid: true,
        }}
      />

      <InputGroup
        {...{
          type: 'password',
          name: 'password',
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          label: 'Passwort',
          isVisible,
          passwordToggle,
          isValid: true,
        }}
      />
      <ResetPasswordLink>Passwort vergessen?</ResetPasswordLink>
      <SubmitWrapper>
        <SubmitButton
          {...{
            type: 'submit',
            onClick: onSubmit,
            disabled: loginData.email && loginData.password ? false : true,
          }}
        >
          Login
        </SubmitButton>
      </SubmitWrapper>
      <TermsText />

      <RegistrationLinkWrapper>
        <RegisterText>Du bist neu bei uns? Registriere dich jetzt!</RegisterText>
        <RegistrationLinkButton onClick={redirectToRegister}>Jetzt registrieren</RegistrationLinkButton>
      </RegistrationLinkWrapper>
    </FormWrapper>
  );
};
