import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  maxWidth: 1170,
  width: '100%',
  margin: '0 auto',
  paddingTop: 125,
  '> div:first-of-type': {
    '@media (max-width: 991px)': {
      position: 'relative',
      left: 0,
      margin: '0 auto 50px',
    },
  },
  '@media (max-width: 991px)': {
    padding: '62px 20px 50px',
  },
});

export const PageTitle = styled.h2({
  fontFamily: 'Inter',
  fontSize: 56,
  lineHeight: '65px',
  borderBottom: '0.7px solid #CAD2DD',
  paddingBottom: 30,
  marginBottom: 65,
  '@media (max-width: 991px)': {
    fontSize: 26,
    lineHeight: '34px',
    textAlign: 'center',
    fontWeight: 'bold',
  },
});
