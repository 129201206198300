import styled from '@emotion/styled';

export const ConfirmEmailWrapper = styled.section({
  fontFamily: 'Red Hat Display',
  maxWidth: 420,
  width: '100%',
  margin: '0 auto 65px',
  '> div:last-of-type': {
    // margin: '75px auto 0',
  },
});

export const SubHeader = styled.p({
  fontFamily: 'Red Hat Display',
  fontSize: '18px',
  lineHeight: '24px',
  whiteSpace: 'pre-line',
  fontWeight: '700',
  color: '#1d1d1d',
  textAlign: 'center',
  margin: '60px 0 30px ',
});

export const Text = styled.p({
  fontFamily: 'Red Hat Display',
  fontSize: '16px',
  lineHeight: '24px',
  marginBottom: '30px',
});

export const SuccessMessage = styled.div({
  maxWidth: 335,
  width: '100%',
  margin: '0 auto 65px',
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '32px',
  '@media (max-width: 991px)': {
    lineHeight: 1.24,
    marginTop: 40,
  },
});

export const AppDownloadWrapper = styled.div({
  marginTop: 30,
  a: {
    display: 'block',
    '&:first-of-type': {
      marginRight: 15,
    },
  },
  '> img': {
    display: 'block',
    maxWidth: 150,
    margin: '0 auto',
    verticalAlign: 'top',
  },
});

export const LinksWrapper = styled.div({
  marginTop: 30,
  display: 'flex',
  justifyContent: 'center',
  img: {
    height: 40,
    verticalAlign: 'top',
  },
});
