import React from 'react';

import { useToggle } from '../../utils/useToggle';

import { InputGroup } from '../InputGroup/InputGroup';
import { FormWrapper, SubHeader, SubmitWrapper, SubmitButton } from './styled-components';

export const ResetPasswordForm = ({ passwords, handleChange, onSubmit, ...props }) => {
  const [isVisible, passwordToggle] = useToggle(false);
  const { newPassword, newPasswordConfirm } = passwords;
  const { isPasswordValid, isSecondPasswordValid } = props.validation;

  return (
    <FormWrapper>
      <SubHeader>Neues Passwort erstellen</SubHeader>

      <InputGroup
        {...{
          type: 'password',
          name: 'newPassword',
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          isVisible,
          passwordToggle,
          note: 'Mind. 8 Zeichen inkl. Groß- und Kleinbuchstaben und 1 Ziffer.',
          isValid: isPasswordValid,
          disabled: props.requestData == null ? true : false,
          placeholder: 'Neues Passwort',
        }}
      />

      <InputGroup
        {...{
          type: 'password',
          name: 'newPasswordConfirm',
          onChange: React.useCallback((event) => handleChange(event), [handleChange]),
          isVisible,
          passwordToggle,
          note: 'Die Passwörter stimmen nicht überein',
          isValid: isSecondPasswordValid,
          isPasswordsMatch: isSecondPasswordValid,
          disabled: props.requestData == null ? true : false,
          placeholder: 'Neues Passwort wiederholen',
        }}
      />

      <SubmitWrapper>
        <SubmitButton
          {...{
            type: 'submit',
            onClick: onSubmit,
            disabled: newPassword && newPasswordConfirm ? false : true,
          }}
        >
          Bestätigen
        </SubmitButton>
      </SubmitWrapper>
    </FormWrapper>
  );
};
