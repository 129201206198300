import styled from '@emotion/styled';

export const MessageWrapper = styled.div({
  maxWidth: 550,
  width: '100%',
  margin: '0 auto 65px',
  hr: {
    backgroundColor: '#44556C',
    width: 90,
    height: 3,
    margin: '0 auto',
    opacity: 1,
  },
  '> div:last-of-type': {
    margin: '0 auto',
  },
});

export const SubHeader = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '32px',
  marginBottom: 45,
  '@media (max-width: 991px)': {
    lineHeight: 1.24,
    marginTop: 40,
  },
});

export const Text = styled.p({
  fontSize: 20,
  lineHeight: '28px',
  marginBottom: 20,
});

export const IconWrapper = styled.div({
  marginTop: 15,
  marginBottom: 50,
  height: 30,
  textAlign: 'center',
  img: {
    maxHeight: '100%',
    width: 'auto',
  },
});
