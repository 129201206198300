import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  padding: '105px 370px',
  backgroundImage: "url('./assets/backgrounds/HealthCubes.png')",
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  '@media (max-width: 1500px)': {
    padding: '100px 120px',
  },
  '@media (max-width: 991px)': {
    padding: '70px 20px',
  },
});

export const Subheader = styled.p({
  fontFamily: 'Inter',
  fontSize: 24,
  lineHeight: '35px',
  marginTop: 20,
  marginBottom: 65,
  color: '#44556c',
  '@media (max-width: 991px)': {
    fontSize: 20,
    lineHeight: 1.24,
    marginTop: 10,
    marginBottom: 10,
  },
});

export const ContentWrapper = styled.div({
  display: 'flex',
  marginBottom: 110,
  p: {
    maxWidth: 575,
    '&:first-of-type': {
      marginRight: 20,
      '@media (max-width: 991px)': {
        marginRight: 0,
        marginBottom: 50,
      },
    },
    span: {
      marginTop: 20,
    },
  },
  '@media (max-width: 991px)': {
    flexDirection: 'column',
    fontSize: 18,
    marginTop: 50,
  },
});

export const OrderWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 991px)': {
    flexDirection: 'column-reverse',
    marginTop: 30,
  },
});

export const ComingSoonLabel = styled.div(({ available }) => ({
  backgroundColor: '#8698B0',
  color: '#fff',
  padding: '0 14px',
  height: 20,
  lineHeight: '20px',
  borderRadius: 4,
  textTransform: 'uppercase',
  textAlign: 'center',
  fontSize: 12,
  maxWidth: 130,
  float: 'right',
  opacity: !available ? 1 : 0,
  '@media (max-width: 991px)': {
    marginLeft: 60,
  },
}));

export const CubeCardWrapper = styled.div({
  padding: '0 15px',
  '@media (max-width: 991px)': {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'center',
    img: {
      maxHeight: 300,
      width: 'auto',
    },
    a: {
      margin: '0 auto',
    },
  },
});

export const MaxLayoutWrapper = styled.div({
  maxWidth: '1920px',
  margin: '0 auto',
});
