import React from 'react';
import moment from 'moment';
import Axios from 'axios';

import gesundUndFit from '../../../data/cubes/360GesundUndFit';
import basenTraining from '../../../data/cubes/BasenTraining';
import bitterPower from '../../../data/cubes/BitterPower';
import fitImmun from '../../../data/cubes/Fit&Immun';
import intermittentFasting from '../../../data/cubes/IntermittentFasting';
import { PreventionProgramDetails } from '../../../data/preventionProgramDetails';

import { AppContext } from '../../../App';

import { Image } from '../../../components';
import { GreenButton } from '../../../pages/LoginPage/styled-components';

import History from '../../../utils/history';

import {
  ComponentWrapper,
  ComponentTitle as Title,
  ComponentSubtitle as Subtitle,
  CubeCardWrapper,
  CubeCardContent,
} from './styled-components';
import generateFileURL from '../../../utils/generateFileURL';

export const ActiveCubes = () => {
  const { state } = React.useContext(AppContext);
  const cubesList = [
    gesundUndFit(process.env.REACT_APP_IS_PROD),
    basenTraining(process.env.REACT_APP_IS_PROD),
    bitterPower(process.env.REACT_APP_IS_PROD),
    fitImmun(process.env.REACT_APP_IS_PROD),
    intermittentFasting(process.env.REACT_APP_IS_PROD),
    PreventionProgramDetails,
  ];
  const [cubes, setCubes] = React.useState([]);
  const [imageLinks, setImageLinks] = React.useState({});

  React.useEffect(() => {
    const activeCubes = state.cubes?.filter((cube) => cube.status === 'ACTIVE');
    setCubes(activeCubes);
  }, [state.cubes]);

  const getImageLinkViaToken = (imageLink, id) => {
    Axios.get(imageLink, { responseType: 'arraybuffer' }).then((response) => {
      const generatedUrl = generateFileURL(response);
      setImageLinks({ ...imageLinks, [`cubeImageLink-${id}`]: generatedUrl || 'http://placehold.jp/210x290.png' });
    });
  };

  const getImg = (id) => {
    const cube = cubesList.find((cube) => cube.cubeId === id);
    if (!cube?.content?.image && !imageLinks[`cubeImageLink-${id}`]) {
      getImageLinkViaToken(cubes.find(({ cubeInfo }) => cubeInfo.id === id).cubeInfo.image.url, id);
    }
    return cube?.content?.image || imageLinks[`cubeImageLink-${id}`];
  };

  const getLink = (id) => {
    const cube = cubesList.find((cube) => cube.cubeId === id);
    if (!cube) return '#';
    return cube.link;
  };

  return (
    <ComponentWrapper>
      <Title>Aktive Health Cubes</Title>
      <Subtitle>Hier siehst du deine persönlichen aktiven Health Cubes.</Subtitle>
      {cubes?.map((cube) => (
        <CubeCard
          {...{ img: getImg(cube.cubeInfo.id), cube, link: getLink(cube.cubeInfo.id), key: cube.cubeInfo.id }}
        />
      ))}
    </ComponentWrapper>
  );
};

export const CubeCard = ({ img, cube, link }) => {
  const startDate = moment(cube.startDate * 1000).format('DD.MM.YYYY');
  const endDate = moment(cube.endDate * 1000).format('DD.MM.YYYY');

  return (
    <CubeCardWrapper>
      <div className="image-wrapper" style={{ minHeight: 290 }}>
        <Image path={img} alt={cube.name} />
      </div>
      <CubeCardContent>
        <span>Name: {cube.cubeInfo.name}</span>
        <span>Dauer: {cube.cubeInfo.duration}</span>
        <span>Aktiviert am: {startDate}</span>
        <span>Läuft bis: {endDate}</span>
        {link !== '#' && (
          <GreenButton onClick={() => History.push(link)}>
            Nochmals kaufen
          </GreenButton>
        )}
      </CubeCardContent>
    </CubeCardWrapper>
  );
};
