import styled from '@emotion/styled';

export const FormWrapper = styled.section({
  maxWidth: 550,
  width: '100%',
  margin: '0 auto 65px',
  lineHeight: '16px',
  '@media (max-width: 620px)': {
    width: '90%',
  },
});

export const SubHeader = styled.p({
  fontFamily: 'Red Hat Display',
  fontSize: '18px',
  lineHeight: '24px',
  whiteSpace: 'pre-line',
  fontWeight: '700',
  color: '#1d1d1d',
  textAlign: 'center',
  margin: '60px 0 20px ',
});

export const SubmitWrapper = styled.div({
  textAlign: 'center',
  marginTop: 45,
});

export const SubmitButton = styled.button(({ theme }) => ({
  fontFamily: 'Red Hat Display',
  fontSize: '16px',
  fontStretch: '100%',
  border: 'none',
  color: '#fff',
  backgroundColor: '#721762',
  boxShadow: 'inset 0 0px 0 rgb(255 255 255 / 15%), 0 1px 1px rgb(0 0 0 / 8%), 0 0 0.5rem 0.05rem rgb(114 23 98 / 50%);',
  width: '100%',
  height: 50,
  lineHeight: '48px',
  borderRadius: 12,
  fontSize: 18,
  fontWeight: 700,
  textAlign: 'center',
  opacity: 1,
  transition: 'opacity .3s',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#521147',
  },
  '&[disabled]': {
    cursor: 'not-allowed',
  },
  '@media (max-width: 991px)': {
    maxWidth: '100%',
  },
  // border: 'none',
  // borderRadius: 23,
  // width: 280,
  // height: 45,
  // lineHeight: '45px',
  // textAlign: 'center',
  // fontSize: 18,
  // fontWeight: 500,
  // backgroundColor: theme.colors.attention,
  // color: theme.colors.black,
  // transition: 'all 0.3s ease',
  // '&:hover': {
  //   opacity: 0.8,
  // },
  // '&[disabled]': {
  //   cursor: 'not-allowed',
  //   opacity: 0.8,
  // },
}));
