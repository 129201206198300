import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  maxWidth: 900,
  margin: 'auto',
  marginBottom: '50px',
  paddingTop: 70,
  '@media (max-width: 991px)': {
    padding: '100px 0px 0px 0px',
  },
});

export const SectionTitle = styled.h3(({ theme, ...props }) => ({
  color: props.light ? theme.colors.white : theme.colors.black,
  fontSize: '48px',
  fontWeight: 'bold',
  marginBottom: '30px',
  lineHeight: '58px',
  letterSpacing: '1.2px',
  textAlign: props.align,
  '@media (max-width: 991px)': {
    fontSize: 28,
    lineHeight: '34px',
  },
  ...props.overrides,
}));

export const Text = styled.p(({ theme }) => ({
  marginTop: '2%',
  span: {
    backgroundColor: theme.colors.attention,
  },
}));

export const BoldText = styled.h3({
  fontSize: 30,
  marginBottom: 20,
  lineHeight: '28px',
  fontWeight: 600,
});

export const CubePictureWrapper = styled.section({
  position: 'relative',
  '&::before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'transparent linear-gradient(234deg,rgba(39,51,66,0),rgba(26,34,44,.43137254901960786) 61%,#151c24) 0 0 no-repeat padding-box',
  },
});

export const ImageSet = styled.picture({
  img: {
    maxHeight: 850,
    height: '100%',
    objectFit: 'cover',
    verticalAlign: 'top',
    '@media (max-width: 991px) and (orientation: portrait)': {
      height: '100vh',
    },
    '@media (max-width: 991px) and (orientation: landscape)': {
      height: '100vh',
      width: '100%',
    },
  },
});

export const QuoteWrapper = styled.div({
  position: 'absolute',
  bottom: 10,
  left: '45%',
  transform: 'translate(-35%, 0)',
  '@media (max-width: 991px)': {
    width: '80%',
    margin: 'auto',
  },
  '> div': {
    '@media (max-width: 991px) and (orientation: portrait)': {
      margin: '0 auto',
      padding: 0,
    },
  },
});

export const ModulesSectionWrapper = styled.section({
  paddingTop: 130,
  paddingBottom: 50,
  '@media (max-width: 991px)': {
    paddingTop: 95,
    paddingBottom: 95,
  },
});

export const TextWrapper = styled.div({
  maxWidth: 1240,
  margin: '0 auto',
  '@media (max-width: 991px)': {
    maxWidth: 'none',
  },
});

export const ModuleText = styled.p(({ theme }) => ({
  maxWidth: 930,
  marginBottom: 25,
  '@media (max-width: 991px)': {
    marginBottom: 120,
  },
}));

export const ModulesWrapper = styled.div({
  position: 'relative',
  maxWidth: '1240px',
  display: 'flex',
  margin: '0 auto',
  backgroundColor: '#fff',
  padding: '40px 20px',
  borderRadius: 8,
  '@media (max-width: 991px)': {
    padding: '90px 0',
    margin: '0 20px',
  },
});

export const ModulesGrid = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
});

export const ModuleImageWrapper = styled.div(({ active }) => ({
  position: 'relative',
  cursor: 'pointer',
  maxWidth: '25%',
  textAlign: 'center',
  margin: '0 26px 65px',
  overflow: 'hidden',
  borderRadius: 8,
  '&::before': {
    content: "''",
    position: 'absolute',
    top: active ? 0 : '-100%',
    background: 'transparent linear-gradient(209deg, #44556C67 0%, #151C24 100%) 0% 0% no-repeat padding-box',
    height: '100%',
    width: '100%',
    transition: 'all 0.3s ease',
    '@media (max-width: 991px)': {
      display: 'none',
    },
  },
  img: {
    maxWidth: 215,
    verticalAlign: 'top',
    '@media (max-width: 991px)': {
      maxWidth: '100%',
    },
  },
  '@media (max-width: 1500px)': {
    maxWidth: 250,
    margin: '0 26px 35px',
  },
  '@media (max-width: 991px)': {
    maxWidth: 250,
    margin: '0 auto 30px',
  },
  '@media (max-width: 768px)': {
    maxWidth: '100%',
    margin: '0 20px 30px',
  },
}));

export const RingIcon = styled.div(({ theme }) => ({
  position: 'absolute',
  top: 15,
  left: 15,
  backgroundImage: "url('./assets/backgrounds/ring_small.svg')",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  width: 50,
  height: 50,
  color: theme.colors.darkGrey,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  span: {
    fontSize: 20,
    marginTop: -2,
    '&:first-of-type': {
      marginTop: 8,
      fontSize: 8,
      lineHeight: '10px',
      fontWeight: 500,
      position: 'relative',
      '&::before': {
        content: "''",
        position: 'absolute',
        bottom: -1,
        left: 0,
        right: 0,
        margin: '0 auto',
        width: 25,
        height: 1,
        backgroundColor: theme.colors.warmGrey,
      },
    },
  },
}));

export const ModuleTitle = styled.p({
  position: 'absolute',
  left: 0,
  right: 0,
  margin: '0 auto',
  bottom: 60,
  color: '#fff',
  fontFamily: 'Inter',
  fontSize: 20,
  lineHeight: '24px',
});

export const LargeRing = styled.div({
  backgroundImage: "url('./assets/backgrounds/ring_large.svg')",
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  position: 'absolute',
  top: '64%',
  left: '50%',
  transform: 'translate(-50%, 0)',
  width: 100,
  height: 100,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
});

export const MiniText = styled.p(({ theme }) => ({
  fontSize: '10px',
  textAlign: 'center',
}));
