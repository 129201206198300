import React from 'react';

import Select from 'react-select';

import { useToggle } from '../../utils/useToggle';

import { SectionTitle, SubSectionTitle, CallToActionPrevention } from '../../components';
import { SectionWrapper, SubHeader, StepperWrapper, StepWrapper, SloganText, InsuranceCalculateWrapper, SelectWrapper, SelectButton, Note, CoatingWrapper } from './styled-components';
import styled from '@emotion/styled';

const selectCustomStyles = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'rgba(255, 255, 255, 1)',
    border: '1px solid #721762',
    height: '56px',
    borderRadius: '12px',
    fontSize: '16px',
    fontWeight: 'bold',
    letterSpacing: '0px',
    lineHeight: '20px',
    color: '#721762',
    backgroundImage: "url('./assets/images/icons/arrow-down-green-select.svg')",
    backgroundSize: 40,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'right 15px top 0px',
    outline: 'none',
    ':hover': {
      border: '1px solid #D4FD34',
    },
    '@media (max-width: 991px)': {
      fontSize: 14,
      backgroundSize: 30,
      backgroundPosition: 'right 10px top 5px',
    },
  }),
  placeholder: () => ({
    gridArea: '1/1/2/3',
    marginLeft: 2,
    marginRight: 2,
    boxSizing: 'border-box',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: () => ({
    display: 'none',
  }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    cursor: 'default',
    backgroundColor: isFocused ? '#D4FD34' : '#fff',
    color: '#000',
    ':hover': {
      backgroundColor: '#D4FD34',
    },
  }),
  singleValue: () => ({
    gridArea: '1/1/2/3',
    marginLeft: 2,
    marginRight: 2,
    maxWidth: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
  }),
};

export const PreventionInsurance = ({ steps, coating, startProgramHandler, scrollRef }) => {
  const [coatingValue, setCoating] = React.useState();
  const [count, setCount] = React.useState(0);
  const [isShowed, toggle] = useToggle(false);

  const handleChange = (option) => setCoating(option.value);

  const showResult = () => {
    toggle();
    setCount(count + 1);
    if (count >= 1) {
      setCoating(null);
      setCount(0);
    }
  };

  return (
    <SectionWrapper id='insurance' ref={scrollRef}>
      <SubSectionTitle align='center' light>
        Zu Den Kosten
      </SubSectionTitle>
      <SectionTitle align='center' light>
        So funktioniert die Kostenerstattung
      </SectionTitle>
      <SubHeader>Finde deine Krankenkasse und wir zeigen dir, wie hoch der Erstattungsbetrag ist</SubHeader>
      <StepperWrapper>
        {steps.map((step, index) => (
          <StepWrapper key={index}>
            <span>{index + 1}</span>
            <p>{step.text}</p>
          </StepWrapper>
        ))}
      </StepperWrapper>
      <InsuranceCalculateWrapper>
        <SelectWrapper>
          <label htmlFor='select'>Finde deine Krankenkasse und wir zeigen dir, wie hoch der Erstattungsbetrag ist.</label>
          {isShowed ? (
            <CoatingWrapper>{coatingValue}</CoatingWrapper>
          ) : (
            <Select name='select' isSearchable={true} blurInputOnSelect={false} styles={selectCustomStyles} options={coating} placeholder='Krankenkasse auswählen' onChange={handleChange} />
          )}
        </SelectWrapper>
        <SelectButton disabled={coatingValue ? false : true} onClick={showResult}>
          {count < 1 ? 'Erstattung berechnen' : 'Neue Berechnung'}
        </SelectButton>
        <Note>
          * Der Rückerstattungsbetrag ist unter Vorbehalt, da dieser abhängig von Leistungen ist, welche ggf. schon in der Vergangenheit beansprucht wurden. Zudem kann der Betrag von Krankenkassen
          individuell verändert werden.
        </Note>
      </InsuranceCalculateWrapper>
      <CallToActionPrevention startProgramHandler={startProgramHandler} priceText='€ 0*' text='Jetzt starten' />
      <SloganText>Die wohl beste Chance dein Leben in Richtung Gesundheit und Fitness zu verändern - 360° Körper und Geist</SloganText>
    </SectionWrapper>
  );
};
