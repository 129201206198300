import React from 'react';
import styled from '@emotion/styled';
import MediaQuery from 'react-responsive';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useToggle } from '../utils/useToggle';
import History from '../utils/history';

import footer from '../data/mainFooter.json';

import { Logo, PageFooter } from '../components';

export const toastSettings = {
  position: 'bottom-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  fontFamily: 'Red Hat Display',
  fontSize: '16px',
};

const PageWrapper = styled.div({
  height: 'inherit',
  display: 'flex',
  width: '100%',
  position: 'relative',
});

const LeftSide = styled.div({
  flexBasis: '50%',
  maxWidth: '50%',
  width: '100%',
  overflow: 'scroll',
  display: 'flex',
  flexDirection: 'column',
  '@media (max-width: 991px)': {
    maxWidth: '100%',
    height: '100%',
    flexBasis: '100%',
    margin: '0px 40px',
  },
  '@media (min-width: 980px)': {
    padding: 20,
  },
  msOverflowStyle: 'none',
  scrollbarWidth: 'none',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
});

const PageHeader = styled.header({
  margin: '40px auto 0',
  width: 550,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  '> div:nth-of-type(1)': {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
  },
  '@media (max-width: 991px)': {
    maxWidth: '100%',
    maxHeight: '5%',
    flexBasis: '100%',
  },
});

const PageMain = styled.main({
  maxWidth: 550,
  width: '100%',
  margin: '0 auto',
  flex: '1 0 auto',
});

const RightSide = styled.div({
  display: 'flex',
  flexBasis: '50%',
  alignItems: 'center',
  maxWidth: '50%',
  width: '100%',
  position: 'relative',
  borderRadius: '36px 0 0 36px',
  objectFit: 'cover',

  '&::before': {
    content: "''",
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: -1,
  },
  '@media (max-width: 991px)': {
    display: 'none',
    height: '0',
  },
});

const MockUpWrapper = styled.div({
  position: 'fixed',
  bottom: '0',
  width: '10%',
  right: '20%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '999',

  img: {
    maxWidth: 200,
  },
  p: {
    paddingBottom: 30,
  },
  '@media (max-width: 991px)': {
    display: 'none',
  },
});

const ItalicText = styled.p({
  fontFamily: 'Fave',
  fontSize: 70,
  lineHeight: '55px',
  letterSpacing: '2.1px',
});

const footerStyles = {
  color: '#1d1d1d',
  backgroundColor: 'transparent',
  maxWidth: 550,
  margin: '0 auto',
  padding: '15px 0 0px',
  lineHeight: '22px',
  alignItems: 'center',
  span: {
    marginBottom: 20,
    '@media (max-width: 991px)': {
      marginBottom: '0',
    },
  },
  a: {
    color: '#721762',
    textDecoration: 'underline',
    '&:hover': {
      color: '#521147',
    },
  },
};

export const ShopwareWrapper = ({ children, titleText, titleStyle, subtitle, hasSubtitle }) => {
  const [isToggled, toggle] = useToggle(false);
  const goToHomePage = () => History.push('/');
  const links = footer.mainFooter;

  return (
    <PageWrapper>
      <LeftSide>
        <PageHeader>
          <Logo />
        </PageHeader>

        <PageMain>{children}</PageMain>

        <MediaQuery minWidth={992}>
          <PageFooter {...{ links, footerStyles }} />
        </MediaQuery>
      </LeftSide>

      <MediaQuery minWidth={992}>
        <img style={{ position: 'fixed', textAlign: 'center', bottom: '0', width: '40%', left: '35%', zIndex: '600' }} src='./assets/images/mocks/smartphone.png' alt='welcome-smartphone-image'></img>
        <RightSide>
          <img
            style={{ width: '100%', height: '100%', zIndex: '1', opacity: '0.5', objectFit: 'cover', borderRadius: '36px 0 0 36px' }}
            src='./assets/backgrounds/stock-image.jpeg'
            alt='welcome-image'
          ></img>
          <MockUpWrapper>
            <div>
              <img src='./assets/images/mocks/qrcode.png' alt='app-store-images'></img>
              <a href='https://play.google.com/store/apps/details?id=com.iqdh.bchange&amp;hl=de' target='_blank'>
                <img src='./assets/images/logos/google-play-badge.png' alt='app-store-images'></img>
              </a>
              <a style={{ width: '100%' }} href='https://apps.apple.com/us/app/b-change/id1599469261?itsct=apps_box_link&amp;itscg=30200' target='_blank'>
                <img style={{ width: '100%' }} src='./assets/images/mocks/black-ios-badge.svg' alt='app-store-images'></img>
              </a>
              {/* <ItalicText>my health in my hands</ItalicText> */}
            </div>
          </MockUpWrapper>
        </RightSide>
      </MediaQuery>

      <ToastContainer
        style={{
          fontFamily: 'Red Hat Display',
          fontSize: '14px',
        }}
      />
    </PageWrapper>
  );
};
