import styled from '@emotion/styled';

export const SectionWrapper = styled.section({
  padding: '5% 0',
  '@media (min-width: 1150px)': {
    img: {
      maxHeight: 200,
      width: 'auto',
    },
  },
});
