import styled from '@emotion/styled';

export const FooterWrapper = styled.footer((props) => ({
  padding: props.footerStyles ? props.footerStyles.padding : '60px 0 120px',
  fontSize: 14,
  backgroundColor: props.footerStyles ? props.footerStyles.backgroundColor : '#44556c',
  color: props.footerStyles ? props.footerStyles.color : '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  '@media (max-width: 991px)': {
    backgroundColor: '#44556c',
    padding: '50px 20px',
    textAlign: 'center',
    fontSize: 11,
    lineHeight: '25px',
    color: '#fff',
    maxWidth: 'none',
  },
  '@media (max-width: 991px) and (orientation: landscape)': {
    alignItems: 'center',
  },
  ...props.footerStyles,
}));

export const FooterLinksWrapper = styled.div((props) => ({
  display: 'flex',
  flexWrap: 'nowrap',
  a: {
    color: props.footerStyles ? props.footerStyles.a.color : '#fff',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:not(:last-child):after': {
      content: "'|'",
      paddingLeft: 5,
      marginRight: 5,
    },
    '@media (max-width: 991px)': {
      color: '#fff',
    },
  },
  '@media (max-width: 991px)': {
    color: '#fff',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
}));
